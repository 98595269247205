import * as React from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Dimensions } from "react-native"
import { Feather, FontAwesome, MaterialIcons } from "@expo/vector-icons"
import { flatten } from "ramda"

import { color, spacing, typography } from "@app/theme"
import { IconButtonProps } from "./icon.props"
import { Text } from "@app/components"

const ww = Dimensions.get("window").width

const CONTAINER: ViewStyle = {
  alignItems: "center",
  justifyContent: "center"
}

const ICON_TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 8,
  marginTop: 2,
  color: color.palette.gray4
}

const ICON: TextStyle = {
  maxHeight: 100,
  ...(ww >= 368
    ? {
        fontSize: 30,
        width: 30,
        height: 30
      }
    : {
        fontSize: 20,
        width: 20,
        height: 20
      }),
  color: color.palette.gray4,
  marginBottom: 2
}
const ICON_BUTTON: ViewStyle = {
  alignItems: "center",
  padding: ww >= 368 ? spacing[2] : 2,
  justifyContent: "center",
  flexBasis: ww >= 368 ? 70 : 54,
  flexShrink: 1,
  minWidth: 50
}

const Icons = {
  MaterialIcons,
  FontAwesome,
  Feather
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const { name, text, lib, style, containerStyle, ...rest } = props
  const Icon = Icons[lib]
  return (
    <View style={flatten([CONTAINER, containerStyle])}>
      <TouchableOpacity style={flatten([ICON_BUTTON, style])} disabled={props.disabled} {...rest}>
        <Icon style={ICON} name={name} />
        {text && <Text style={ICON_TEXT}>{text}</Text>}
      </TouchableOpacity>
    </View>
  )
}
