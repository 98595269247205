import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  StyleSheet,
  FlatList,
  StatusBar,
  TouchableOpacity,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  Alert,
  Dimensions,
  GestureResponderEvent,
  ListRenderItem
} from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import { Hoverable } from "react-native-web-hover"

import { color, typography } from "@app/theme"
import { OfferCard, OfferCardProps, Text } from "@app/components"
import { useComponentSize } from "@app/utils/hooks"

const WM = 8
const CONTAINER: ViewStyle = {
  justifyContent: "center",
  margin: WM
}
const IMH = 8
const MIN_ITEM_WIDTH = Dimensions.get("window").width >= 368 ? 160 : 100
const MAX_ITEM_WIDTH = 220
const AVAILABLE_WIDTH = Math.min(Dimensions.get("window").width, 840) - 2 * WM
const calcItemWidth = () => {
  const columns = calcNumColumns()
  const reminder = AVAILABLE_WIDTH % (MIN_ITEM_WIDTH + 2 * IMH)
  const itemWidth = Math.min(reminder / columns + MIN_ITEM_WIDTH, MAX_ITEM_WIDTH)
  return itemWidth
}
const calcNumColumns = () => {
  const columns = Math.floor(AVAILABLE_WIDTH / (MIN_ITEM_WIDTH + 2 * IMH))
  return columns
}
const ITEM: ViewStyle = {
  marginHorizontal: IMH,
  marginVertical: 6,
  width: calcItemWidth()
}

const TEXT: TextStyle = {
  marginLeft: 8
}

export interface OfferGroupProps {
  style?: StyleProp<ViewStyle>
  title?: string | React.ReactNode
  horizontal?: boolean
  offers: OfferCardProps[]
}

const renderItem: ListRenderItem<OfferCardProps> = ({ item }) => {
  return (
    <Hoverable>
      {({ hovered }) => (
        <OfferCard
          onOfferPress={item.onOfferPress}
          onStorePress={item.onStorePress}
          style={ITEM}
          catalog={item.catalog}
          title={item.title}
          subTitle={item.subTitle}
          isLoading={item.isLoading}
          showWarning={item.showWarning}
          hovered={hovered}
        />
      )}
    </Hoverable>
  )
}
export const OfferGroup = observer(function OfferGroup(props: OfferGroupProps) {
  const [selectedId, setSelectedId] = useState(null)
  const numColumns = useMemo(() => (props.horizontal ? 1 : calcNumColumns()), [])

  return (
    <View style={flatten([CONTAINER, props.style])}>
      <Text preset="header" style={TEXT}>
        {props.title}
      </Text>
      <FlatList
        data={props.offers}
        // key={`${numColumns} ${size?.width || -1}`}
        // contentContainerStyle={FLATLIST}
        // columnWrapperStyle={FLATLIST_COLUMN}
        // ListHeaderComponent={}
        renderItem={renderItem}
        keyExtractor={(item, idx) => item.catalog?.id || String(idx)}
        extraData={selectedId}
        horizontal={props.horizontal}
        numColumns={numColumns}
        showsHorizontalScrollIndicator={false}
        pagingEnabled={true}
      />
    </View>
  )
})
