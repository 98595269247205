import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import {
  View,
  ViewStyle,
  TextStyle,
  Dimensions,
  Linking,
  ListRenderItem,
  TouchableOpacity,
  FlatList
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
// import { useNavigation } from "@react-navigation/native"

// import { useStores } from "@app/models"
import { TabsScreenProps } from "@app/navigators"
import { Button, Screen, Text, Svg } from "@app/components"
import { TextProps } from "@app/components/text/text.props"
import { color, spacing } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { useNavigation } from "@react-navigation/native"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type ListItem = {
  text: string
  preset: TextProps["preset"]
}

type PolicyScreenProps = StackScreenProps<RootNavigatorParamList, "Policy">
export const PolicyScreen = observer((props: PolicyScreenProps) => {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const goHome = () => props.navigation.navigate("Home")
  // const navigation = useNavigation()
  const goHome = () =>props.navigation.navigate("Tabs", { screen: "Home", params: { screen: 'Offers'} })
  const navigation = useNavigation<TabsScreenProps["navigation"]>()

  const renderItem: ListRenderItem<ListItem> = ({ item }) => {
    switch (item.preset) {
      case "bold":
        return (
          <Text preset={item.preset} style={LIST_ITEM_BOLD}>
            {item.text}
          </Text>
        )
      default:
        return (
          <Text preset={item.preset} style={LIST_ITEM_DEFAULT}>
            {item.text}
          </Text>
        )
    }
  }
  return (
    <Screen style={ROOT} preset="scroll">
      <View style={PAGE_WRAPPER}>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <Text style={HEADER_LARGE} preset="headerLarge" text="Sekretesspolicy" />
        <Text preset="header" text="Information om personuppgiftsbehandling och integritetsskydd" />
        <Text
          preset="default"
          text="Här kan du läsa om vår integritetsskyddspolicy. Den handlar om hur och på vilket sätt vi skyddar dina personuppgifter och din integritet. Att värna din integritet och följa gällande dataskyddslagstiftning är viktigt för oss."
        />
        <Text
          preset="default"
          text="För att kunna använda VeckansE, (www.veckansE.se), ”Tjänsten”, och dess funktioner fullt ut och för att vi ska kunna administrera ditt medlemskap krävs det att du i samband med registrering och skapande av ditt användarkonto lämnar vissa personuppgifter till oss."
        />
        <FlatList
          data={[
            { preset: "bold", text: "1. Uppgifter som vi samlar in" },
            {
              preset: "default",
              text: "När du blir medlem och börjar använda Tjänsten frågar vi dig efter e-postadress samt postnummer. Det finns ingen skyldighet eller lagstadgat krav att du måste lämna uppgifterna. Att vi samlar in och sparar uppgifterna är dock nödvändigt för att du ska kunna använda Tjänsten och för att vi ska ingå avtal om och administrera ditt medlemskap. Besök som du gör på Tjänsten innebär även att vi från din webbläsare, genom användning av cookies, tilläggsprogram (plug-in), eller andra spårningsverktyg, eller genom webbanalys- eller statistikverktyg som vi använder, mottar och i serverloggar sparar information om webbläsaren, din enhets ID-nr, i vissa fall använt IP-nummer, datum och tid för registrering, och i förekommande fall även platsinformation om var din enhet finns. Dessa uppgifter är anonymiserade och/eller lagras och används på ett anonymiserat sätt utan att kopplas direkt till dig som person. Vad för typ av cookies som vi använder, inklusive cookies från tredje parter som vi samarbetar med, samt hur du gör för att neka cookies eller radera tidigare sparade cookies, kan du läsa om längre ner under Cookies."
            },
            {
              preset: "bold",
              text: "2. Hur insamlade uppgifter används, ändamål och rättslig grund för behandlingen"
            },
            {
              preset: "default",
              text: "Uppgifterna som du själv lämnar till oss används för att registrera och administrera ditt medlemskap, såsom att kommunicera med dig och lämna information om nyheter, förändringar, uppdateringar, utvärderingar, analyser och undersökningar och annat som är relevant för och förbättrar din användning av Tjänsten som medlem. För detta ändamål är den rättsliga grunden att vi behöver utföra behandlingen för att administrera medlemskapet och fullgöra det avtal mellan oss som medlemskapet innebär."
            },
            {
              preset: "default",
              text: "Insamlade och sparade uppgifter används även för analys och statistik i syfte att utveckla, förbättra, underhålla och skydda Tjänsten och vår servermiljö samt för marknadsföringsändamål, vilket kan inbegripa att, baserat på urval och selektering, utforma, anpassa och skicka reklam, förmånserbjudanden och andra utskick som är relevanta och utformade efter mottagarens intressen, preferenser och behov (profilering). För sådana ändamål kan vi även överföra uppgifterna vidare till användning av andra partners, eller andra företag som vi samarbetar med. Den rättsliga grunden för dessa ändamål är att behandlingen är nödvändig för det berättigade intresse vi har av att kunna använda uppgifterna för marknadsföringsändamål och bl.a. höja användarupplevelsen av Tjänsten och skapa intäkter nödvändiga och en förutsättning för att kunna bedriva, utveckla och tillhandahålla Tjänsten kostnadsfritt på det sätt som vi gör, i våra användares och medlemmars intresse. Detta intresse anser vi väga tyngre än integritetsintresset, med beaktande av sättet för, omfattningen och effekterna av behandlingen, vad våra medlemmar och användare av Tjänsten rimligen har för förväntningar på hur vi kan komma att använda personuppgifterna samt den rätt som alltid finns att motsätta sig att uppgifterna används för direktmarknadsföring och/eller profilering, eller att avsluta sitt medlemskap och begära radering av sina personuppgifter."
            },
            {
              preset: "default",
              text: (
                <>
                  Genom att bli medlem och godkänna Veckans:s villkor (Cartero Digital Marketing
                  AB:s){" "}
                  <TouchableOpacity onPress={() => navigation.navigate("Terms")}>
                    <Text style={LINK} text="Användarvillkor " />
                  </TouchableOpacity>
                  och det som sägs i denna information, lämnar du ditt samtycke till att vi, eller
                  någon av våra samarbetspartners skickar marknadsföring till dig via elektronisk
                  post eller sms. Du har alltid rätt att när som helst meddela oss att du motsätter
                  dig att ta emot marknadsföring via e-post eller sms, eller att dina uppgifter
                  annars används för direktmarknadsföring, inklusive profilering som har samband med
                  sådan marknadsföring, genom att klicka UNSUBSCRIBE längst ned i våra utskick
                  alternativt skicka e-post till{" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("mailto:kundtjanst@veckanse.com")}
                  >
                    <Text style={LINK} text="kundtjanst@veckanse.com" />
                  </TouchableOpacity>
                </>
              )
            },
            { preset: "bold", text: "3. Mottagare av personuppgifterna" },
            {
              preset: "default",
              text: "Personuppgifterna används av oss för Tjänstens utveckling och bedrivande och inom vår egen organisation, men kan också, för bl.a. marknadsföringsändamål, komma att överföras till och delas med andra mottagare, våra partners, eller andra företag som vi samarbetar med, t.ex. personuppgiftsbiträden som vi anlitar för behandling av personuppgifter. Man har alltid rätt att när som helst motsätta sig att ta emot marknadsföring via e-post eller sms, eller att ens uppgifter annars används för direktmarknadsföring, inbegripet profilering, se länk i punkt 2 ovan."
            },
            {
              preset: "default",
              text: "Insamlade personuppgifter överförs inte av oss till tredje land, dvs. till något land utanför EU/EES. Överföring kan dock komma att ske om vi t.ex. har anlitat ett personuppgiftsbiträde för utförande av viss behandling. Överföring av personuppgifter till tredje land förutsätter alltid att det finns en laglig grund för sådan överföring."
            },
            { preset: "bold", text: "4. Period som uppgifterna lagras" },
            {
              preset: "default",
              text: (
                <>
                  Personuppgifter sparas tills vidare så länge man är medlem och har en
                  användarrelation till Tjänsten och oss. Uppgifterna slutar inte att sparas bara
                  för att man inte är aktiv och använder Tjänsten. Om du vill avsluta ditt
                  användarkonto kan du enkelt kontakta oss och meddela detta genom att maila:{" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("mailto:kundtjanst@veckanse.com")}
                  >
                    <Text style={LINK} text="kundtjanst@veckanse.com" />
                  </TouchableOpacity>
                </>
              )
            },
            {
              preset: "default",
              text: "När ditt medlemskap är avslutat kommer vi utan dröjsmål, och senast inom den tid som krävs enligt från tid till annan gällande dataskyddslagstiftning, eller domstols eller tillsynsmyndighets beslut, att radera dina uppgifter. Dina uppgifter kan komma att lagras längre än annars om skyldighet att fortsätta att lagra uppgifterna finns enligt gällande lagstiftning som vi omfattas av."
            },
            {
              preset: "bold",
              text: "5. Information om dina rättigheter enligt dataskyddsförordningen"
            },
            {
              preset: "default",
              text: "Enligt bestämmelserna i EU:s dataskyddsförordning (GDPR) har du rätt att kostnadsfritt på begäran få tillgång till och en kopia av de personuppgifter om dig som behandlas (artikel 15), samt även rätt att få felaktiga uppgifter rättade eller ofullständiga kompletterade (artikel 16). Du har vidare rätt att i de fall och på de förutsättningar som anges i dataskyddsförordningen begära radering av dina personuppgifter (artikel 17), begränsning av sådan behandling som rör dina personuppgifter (artikel 18) samt att göra invändningar mot den behandling som sker (artikel 21). Du har också rätt att få ut och överföra sådana personuppgifter som du har tillhandahållit till oss enligt vad som kallas rätt till dataportabilitet (artikel 20)."
            },
            { preset: "bold", text: "6. Automatiserade beslut och profilering" },
            {
              preset: "default",
              text: "Den behandling av personuppgifter som vi utför inkluderar automatiserade beslut och profilering, dvs. att vissa personliga egenskaper bedöms för analysera och förutsäga preferenser, intressen och beteenden, och i förekommande fall vistelseort, i syfte att kunna skicka anpassade marknadsföringserbjudanden och annan kommunikation efter mottagarens intressen och behov. Logiken bakom detta är att din enhets användning av Tjänsten och andra av våra tjänster gör att vi kan spåra och få ökad kännedom om dina vanor på internet och därmed också dina intressen och behov. Följden för dig som registrerad blir att du kan få fler riktade reklamerbjudanden än annars, vilket i sin tur kan medföra en ökning av dina inköp av sådana varor och tjänster som marknadsföringen avser. Du har alltid en rätt att invända mot direktmarknadsföring och profilering samt, i sådant fall, även en rätt att begära radering av dina personuppgifter."
            },
            {
              preset: "bold",
              text: "7. Rätt att motsätta sig direktmarknadsföring och profilering, återkallelse av samtycke"
            },
            {
              preset: "default",
              text: "Du har alltid rätt att motsätta dig att dina uppgifter används för direktmarknadsföring, inklusive profilering som har samband med sådan marknadsföring, se länk i punkt 2 ovan. I den utsträckning som vi behandlar personuppgifter och detta grundar sig på ditt lämnade samtycke, har du rätt att när som helst återkalla ditt samtycke."
            },
            { preset: "bold", text: "8. Personuppgiftsansvarig och kontaktuppgifter" },
            {
              preset: "default",
              text: "Det är Cartero Digital Marketing AB som driver och har ansvaret för Tjänsten och är personuppgiftsansvarig för den behandling av personuppgifter som sker. Om du har frågor eller vill ha mer information om den behandling av personuppgifter som sker är du välkommen att kontakta oss eller vårt dataskyddsombud via e-post eller telefon."
            },
            { preset: "default", text: "Cartero Digital Marketing AB, org. nr: 556857-4262" },
            {
              preset: "default",
              text: "Adress: Box 5077, 102 42 Stockholm, telefon: 070-720 06 11."
            },
            {
              preset: "default",
              text: (
                <>
                  E-post:{" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("mailto:kundtjanst@veckanse.com")}
                  >
                    <Text style={LINK} text="kundtjanst@veckanse.com" />
                  </TouchableOpacity>
                </>
              )
            },
            {
              preset: "default",
              text: (
                <>
                  Dataskyddsansvarig hos företaget:{" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("mailto:dataskyddsansvarig@veckanse.com")}
                  >
                    <Text style={LINK} text="dataskyddsansvarig@veckanse.com" />
                  </TouchableOpacity>
                </>
              )
            },
            { preset: "bold", text: "9. Dataskyddsombud" },
            {
              preset: "default",
              text: (
                <>
                  Vi har utsett ett dataskyddsombud, vars uppgift bl.a. är att övervaka
                  efterlevnaden av dataskyddslagstiftningen. Kontaktuppgifter till vårt
                  dataskyddsombud är:{" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("mailto:dataskyddsansvarig@veckanse.com")}
                  >
                    <Text style={LINK} text="dataskyddsansvarig@veckanse.com" />
                  </TouchableOpacity>
                </>
              )
            },
            { preset: "bold", text: "10. Rätt att ge in klagomål" },
            {
              preset: "default",
              text: "Du har alltid rätt att vid missnöje eller anmärkningar mot den behandling av personuppgifter som utförs, ge in klagomål till berörd tillsynsmyndighet, Datainspektionen, (med föreslaget nytt namn, Integritetsskyddsmyndigheten). Detta gör du enklast genom e-post till datainspektionen@datainspektionen.se eller via telefon 08-657 61 00."
            }
          ]}
          renderItem={renderItem}
        />
        <View></View>
        <View style={SECTION}>
          <Text preset="header" text="Cookies" />
          <Text
            preset="default"
            text="Cookies är små filer som innehåller information som en webbsajt använder för att samla in uppgifter. Den här webbsajten använder cookies för att underlätta för besökarna och/eller förbättra sajtens innehåll genom att sammanställa ackumulerad information om vilka specifika avdelningar och sidor besökarna använder. Du kan ändra din webbläsares inställningar så att den inte accepterar cookies."
          />
        </View>
      </View>
      <Footer />
    </Screen>
  )
})

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height}

const LIST_ITEM_BOLD: ViewStyle = {
  marginTop: spacing[4],
  marginBottom: spacing[2]
}
const LIST_ITEM_DEFAULT: ViewStyle = {
  marginLeft: spacing[3],
  marginBottom: spacing[2]
}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  minHeight: Dimensions.get("window").height,
  alignSelf: "center"
}

const SECTION: ViewStyle = {
  paddingTop: 40
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform:
    Dimensions.get("window").width > 768
      ? [{ scale: 2 }, { rotate: "45deg" }]
      : [{ scale: 1 }, { rotate: "45deg" }],
  zIndex: -1
}

const HEADER_LARGE: TextStyle = {
  marginTop: spacing[5],
  marginBottom: spacing[5]
}

const LINK: TextStyle = {
  color: color.palette.pink3
}
