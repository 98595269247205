import * as React from "react"
import Svg, { G, Path, Rect, Circle } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={232.997}
    height={201.203}
    {...props}
  >
    <G transform="translate(-31.584 -58.029)">
      <Path
        d="M154.284 75.933c58.927 0 102.719-9.568 102.719 49.36s-32.6 122.546-91.532 122.546c-30.23 0-40.9-25.584-60.417-45.893-18.326-19.064-73.47-36.864-73.47-65.563 0-58.927 63.772-60.45 122.7-60.45Z"
        fill="#f5ecec"
      />
      <Path
        d="M188.409 58.03h.014a120.352 120.352 0 0 1 23.324 2.128 80.619 80.619 0 0 1 18.522 5.943 57.3 57.3 0 0 1 24.164 20.633 60.6 60.6 0 0 1 7.827 18.021 76.48 76.48 0 0 1 2.32 19.4 93.071 93.071 0 0 1-7.7 36.363c-2.932 6.649-5.735 13.551-8.446 20.226-3.418 8.415-6.952 17.116-10.711 25.214a159.374 159.374 0 0 1-12.511 22.667 76.869 76.869 0 0 1-16.264 17.816 56.947 56.947 0 0 1-21.934 10.546 79.289 79.289 0 0 1-18.474 2.25 73.873 73.873 0 0 1-22.952-3.69 90.84 90.84 0 0 1-21.155-10.3c-12.53-8.138-24.946-19.837-37.958-35.767-8-9.79-16.623-18.509-24.233-26.2-9.951-10.058-18.544-18.744-24.085-27.733a40.408 40.408 0 0 1-5.662-13.895 30.876 30.876 0 0 1 .971-15.026l.152-.494c2.166-7.034 4.212-13.679 7.737-19.792 3.926-6.808 9.266-12.247 16.806-17.118 8.564-5.532 20.186-10.4 35.53-14.9 16.528-4.84 37.5-9.3 64.125-13.62a192.507 192.507 0 0 1 30.593-2.672Zm-19.869 199.2a77.312 77.312 0 0 0 18.007-2.195 54.973 54.973 0 0 0 21.174-10.181 74.915 74.915 0 0 0 15.838-17.363 157.345 157.345 0 0 0 12.35-22.382c3.739-8.054 7.264-16.732 10.673-25.125 2.717-6.688 5.526-13.6 8.469-20.28a91.028 91.028 0 0 0 7.529-35.564 74.48 74.48 0 0 0-2.257-18.887 58.592 58.592 0 0 0-7.566-17.428 55.326 55.326 0 0 0-23.338-19.915 78.613 78.613 0 0 0-18.062-5.792 118.355 118.355 0 0 0-22.935-2.09h-.014a190.512 190.512 0 0 0-30.273 2.647c-26.539 4.311-47.435 8.749-63.883 13.565-15.156 4.438-26.607 9.233-35.006 14.659-7.264 4.691-12.4 9.915-16.159 16.437-3.413 5.918-5.426 12.458-7.558 19.381l-.152.494a28.93 28.93 0 0 0-.916 14.075 38.449 38.449 0 0 0 5.4 13.208c5.421 8.8 13.94 17.406 23.8 27.376 7.642 7.724 16.3 16.479 24.361 26.343 12.879 15.767 25.145 27.332 37.5 35.355a88.846 88.846 0 0 0 20.687 10.071 71.876 71.876 0 0 0 22.331 3.593Z"
        fill="#f5ecec"
      />
      <Path
        d="M77.625 221.591a7.038 7.038 0 0 1-7.039-7.038v-80.939h156.952v80.939a7.038 7.038 0 0 1-7.039 7.038Zm-7.038-87.977 61.99-61.156 9.071-8.95a10.557 10.557 0 0 1 14.83 0l36.232 35.746 34.829 34.361Z"
        fill="#5d404b"
      />
      <Rect
        width={135.837}
        height={133.726}
        rx={3}
        transform="translate(81.144 87.98)"
        fill="#fff"
      />
      <G transform="translate(90.998 97.834)">
        <Path
          d="M3 2a1 1 0 0 0-1 1v15.115a1 1 0 0 0 1 1h15.115a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3m0-2h15.115a3 3 0 0 1 3 3v15.115a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
          fill="#f5ecec"
        />
        <Rect
          width={78.124}
          height={3.519}
          rx={1.76}
          transform="translate(26.745 .704)"
          fill="#9a4767"
        />
        <Rect
          width={78.124}
          height={3.519}
          rx={1.76}
          transform="translate(26.745 8.446)"
          fill="#f5ecec"
        />
        <Rect
          width={40.118}
          height={3.519}
          rx={1.76}
          transform="translate(26.745 16.188)"
          fill="#f5ecec"
        />
        <Path
          d="m10.299 14.5-.029.028a1.192 1.192 0 0 1-1.685 0l-3.291-3.291A1.192 1.192 0 1 1 6.98 9.55l2.391 2.392 4.909-4.91a1.232 1.232 0 0 1 1.741 1.742Z"
          fill="#c5497d"
        />
        <G transform="translate(0 28.153)">
          <Path
            d="M3 2a1 1 0 0 0-1 1v15.115a1 1 0 0 0 1 1h15.115a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3m0-2h15.115a3 3 0 0 1 3 3v15.115a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
            fill="#f5ecec"
          />
          <Rect
            width={78.124}
            height={3.519}
            rx={1.76}
            transform="translate(26.745 .704)"
            fill="#9a4767"
          />
          <Rect
            width={78.124}
            height={3.519}
            rx={1.76}
            transform="translate(26.745 8.446)"
            fill="#f5ecec"
          />
          <Rect
            width={40.118}
            height={3.519}
            rx={1.76}
            transform="translate(26.745 16.188)"
            fill="#f5ecec"
          />
          <Path
            d="m10.299 14.5-.029.028a1.192 1.192 0 0 1-1.685 0l-3.291-3.291A1.192 1.192 0 1 1 6.98 9.55l2.391 2.392 4.909-4.91a1.232 1.232 0 0 1 1.741 1.742Z"
            fill="#c5497d"
          />
        </G>
      </G>
      <G fill="#c5497d">
        <Path d="M86.422 80.941a1.5 1.5 0 0 1-1.5-1.5V66.772a1.5 1.5 0 0 1 1.5-1.5 1.5 1.5 0 0 1 1.5 1.5v12.669a1.5 1.5 0 0 1-1.5 1.5ZM58.881 94.925a1.5 1.5 0 0 1 1.5-1.5H73.05a1.5 1.5 0 0 1 1.5 1.5 1.5 1.5 0 0 1-1.5 1.5H60.381a1.5 1.5 0 0 1-1.5-1.5ZM64.937 74.401a1.5 1.5 0 0 1 2.113-.184l9.705 8.146a1.5 1.5 0 0 1 .184 2.114 1.5 1.5 0 0 1-2.113.184l-9.705-8.146a1.5 1.5 0 0 1-.184-2.114Z" />
      </G>
      <Path
        d="M227.539 133.614s-49.979 6.484-89.217 26.718-67.735 54.221-67.735 54.221a7.038 7.038 0 0 0 7.038 7.038h142.876a7.038 7.038 0 0 0 7.038-7.038Z"
        fill="#5d404b"
      />
      <Path
        d="M70.587 133.614s49.979 6.484 89.217 26.718 67.735 54.221 67.735 54.221a7.038 7.038 0 0 1-7.038 7.038H77.625a7.038 7.038 0 0 1-7.038-7.038Z"
        fill="#9a4767"
      />
      <Path
        d="m157.232 214.117-2-.05a44.736 44.736 0 0 0-.583-6.794l1.976-.292a45.593 45.593 0 0 1 .607 7.136Zm-1.965-14.093-1.944.468a91.771 91.771 0 0 0-1.882-6.66l1.903-.615a93.962 93.962 0 0 1 1.923 6.807Zm24.515-19.225a14.36 14.36 0 0 1-6.337 2.1l-.14-1.996a12.64 12.64 0 0 0 6.028-2.185l1.107 1.668q-.328.214-.658.413Zm5.86-5.597-1.66-1.118a13.942 13.942 0 0 0 2.382-6.013l1.984.243a15.942 15.942 0 0 1-2.707 6.887Zm-19.455 5.734a13.768 13.768 0 0 1-1.767-1.423 8.329 8.329 0 0 1-2.504-4.835l1.974-.32a6.373 6.373 0 0 0 1.904 3.704 11.763 11.763 0 0 0 1.51 1.215Zm-15.25 5.637-1.857.745a109.152 109.152 0 0 0-2.79-6.348l1.805-.862a114.668 114.668 0 0 1 2.842 6.465Zm34.079-24.469c-.96-1.332-2.824-2.37-5.392-3l.476-1.942c3.079.755 5.279 2.025 6.538 3.77Zm-20.252 5.9-1.875-.693a28.255 28.255 0 0 1 3.417-6.38l1.638 1.149a26.263 26.263 0 0 0-3.177 5.923Zm26.023-17.925a31.22 31.22 0 0 1-3.707.043 13.335 13.335 0 0 0-3.16.266l-.39-1.96a15.334 15.334 0 0 1 3.632-.304 29.695 29.695 0 0 0 3.462-.035Zm6.32-4.166-1.89-.66c.068-.196.135-.399.206-.613a10.732 10.732 0 0 1 3.705-5.691l1.254 1.557a8.785 8.785 0 0 0-3.06 4.755c-.07.218-.143.44-.218.653Zm-24.184 12.504a41.534 41.534 0 0 0-6.796.638l-.345-1.97a44.122 44.122 0 0 1 6.124-.646l-.895-.945a32.245 32.245 0 0 1 5.738-4.364l1.017 1.722a29.842 29.842 0 0 0-4.858 3.61Zm-26.576 15.452-2-.017a11.824 11.824 0 0 1 2.483-7.078l1.603 1.196a9.867 9.867 0 0 0-2.086 5.899Zm13.17-13.103a31.475 31.475 0 0 0-6.135 2.862l-1.05-1.702a33.47 33.47 0 0 1 6.523-3.05Z"
        fill="#c5497d"
      />
      <G transform="translate(201.314 108.023)">
        <Circle
          cx={15.765}
          cy={15.765}
          r={15.765}
          transform="translate(2.718 2.718)"
          fill="#c5497d"
        />
        <Path
          d="M18.483.5A17.983 17.983 0 0 0 5.767 31.2 17.984 17.984 0 0 0 31.2 5.767 17.865 17.865 0 0 0 18.483.5m0-2.5A20.483 20.483 0 1 1-2 18.483 20.483 20.483 0 0 1 18.483-2Z"
          fill="#c5497d"
        />
        <Path
          d="M16.53 25.766a1.884 1.884 0 0 0 2.306-1.333l-3.637-.975a1.884 1.884 0 0 0 1.33 2.308Zm7.266-2.615c-.392-.738-1.181-1.902-.401-4.81a4.649 4.649 0 0 0-2.455-5.39l.16-.592a.942.942 0 1 0-1.82-.488l-.158.592a4.649 4.649 0 0 0-4.821 3.437c-.78 2.909-2.046 3.522-2.754 3.965a.92.92 0 0 0-.41.551.943.943 0 0 0 .669 1.155l10.914 2.926a.942.942 0 0 0 1.157-.665.919.919 0 0 0-.079-.682Z"
          fill="#fff"
        />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
