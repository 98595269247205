export const palette = {
  black: "#000000",
  white: "#ffffff",
  offWhite: "#f5ecec",
  pink: "#c5497d",
  pinkDarker: "#9a4767",
  pinkBlack: "#5D404B",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#dd3333",
  deepPurple: "#5D2555",
  gray1: "#f7f5f5",
  gray2: "#dbd5d8",
  gray3: "#a8a1a4",
  gray4: "#5f595a",
  gray5: "#2E292B",
  pink1: "#F5ECEC",
  pink2: "#EAC1CC",
  pink3: "#C5497D",
  pink4: "#9A4767",
  pink5: "#5D404B",
}
