import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const OfferImagesModel = types
  .model("OfferImages")
  .props({
    id: types.identifierNumber,
    offer_id: types.integer,
    path: types.string,
    order: types.integer,
    created_at: types.Date,
    updated_at: types.maybe(types.Date),
    is_active: types.boolean,
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

type OfferImagesType = Instance<typeof OfferImagesModel>
export interface OfferImages extends OfferImagesType {}
type OfferImagesSnapshotType = SnapshotOut<typeof OfferImagesModel>
export interface OfferImagesSnapshot extends OfferImagesSnapshotType {}
export const createOfferImagesDefaultModel = () => types.optional(OfferImagesModel, {})
