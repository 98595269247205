import React, { useEffect } from "react"
import { StyleProp, TextStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import * as WebBrowser from "expo-web-browser"
import * as Google from "expo-auth-session/providers/google"
import * as AuthSession from "expo-auth-session"

import { FontAwesome } from "@expo/vector-icons"

import { color } from "@app/theme"
import { Button, Text } from "@app/components"
import { viewPresets, textPresets } from "@app/components/button/button.presets"
import { UserSnapshot, useStores } from "@app/models"

import { GOOGLE_WEB_CLIENT_ID } from "@app/config/env"
WebBrowser.maybeCompleteAuthSession()

export interface GoogleAuthButtonProps {
  onAuthenticated: (user: UserSnapshot | null) => void
  text?: string
  style?: StyleProp<TextStyle>
  disabled?: boolean
  shouldAuth?: () => boolean
}
export const GoogleAuthButton = observer((props: GoogleAuthButtonProps) => {
  const [request, response, promptAsync] = Google.useAuthRequest({
    webClientId: GOOGLE_WEB_CLIENT_ID,
    redirectUri: AuthSession.makeRedirectUri({ path: "login" }),
    scopes: ["openid", "profile", "email"] // https://developers.google.com/identity/protocols/oauth2/scopes
  })
  const { userOauthConnect } = useStores()

  useEffect(() => {
    if (response?.type === "success") {
      const { authentication } = response
      const { accessToken } = authentication
      userOauthConnect({ provider: "google", query: { accessToken } }).then(props.onAuthenticated)
    }
  }, [response])

  const onPress = () => {
    const { shouldAuth = () => true } = props
    if (!shouldAuth()) return
    promptAsync()
  }

  return (
    <TouchableOpacity>
      <Button
        style={flatten([viewPresets.wide, BUTTON, props.style]) as TextStyle}
        disabled={props.disabled || !request}
        // name="google"
        // backgroundColor=""
        onPress={onPress}
      >
        <FontAwesome style={BUTTON_ICON} name="google" />
        <Text style={flatten([textPresets.wide, TEXT])}>{props.text || "Login med Google"}</Text>
      </Button>
    </TouchableOpacity>
  )
})

const BUTTON: TextStyle = {}

const BUTTON_ICON: TextStyle = {
  color: color.textWhite,
  padding: 5,
  paddingBottom: 4,
  fontSize: 18
}

const TEXT: TextStyle = {
  fontFamily: "Poppins-Bold",
  fontSize: 16,
  fontWeight: "900",
  color: color.textWhite
}
