import React from "react"
import { StyleProp, TextStyle, View, ViewStyle, Dimensions } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import { MaterialIcons, Feather } from "@expo/vector-icons"

import { color, spacing } from "@app/theme"
import { Button, Text, useModal } from "@app/components"
import { useStores } from "@app/models"

export interface ChangeLocationBannerProps {
  style?: StyleProp<ViewStyle>
}

export const ChangeLocationBanner = observer((props: ChangeLocationBannerProps) => {
  const { openModal, setModalVariant } = useModal()
  const { setFindLocationOnMount } = useStores()
  const onPress = () => {
    setModalVariant("location")
    setFindLocationOnMount(true)
    openModal()
    setTimeout(() => {
      setFindLocationOnMount(false)
    }, 299)
  }

  return (
    <View style={flatten([CONTAINER, props.style])}>
      {/* <View style={CIRCLE}>
        <MaterialIcons style={LOCATION_ICON} name="location-on" />
      </View> */}
      <View style={FLEX}>
        <Feather style={LOCATION_ICON} name="map-pin" />
        <Text style={HEADER_TEXT} preset="header" text="Se erbjudanden nära dig!" />
      </View>
      <Button style={BUTTON} preset="wide" onPress={onPress}>
        <MaterialIcons style={BUTTON_ICON} name="my-location" />
        <Text style={BUTTON_TEXT} preset="bold" text="Välj din plats" />
      </Button>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  margin: 10,
  marginTop: 0,
  marginBottom: spacing[4],
  padding: 15,
  borderRadius: 12,
  backgroundColor: color.palette.pink1,
  width: Dimensions.get("window").width > 768 ? "97%" : "92%",
  // maxWidth: 475,
  alignSelf: "center"
}

// const CIRCLE: ViewStyle = {
//   justifyContent: "center",
//   alignItems: "center",
//   height: 60,
//   width: 60,
//   borderRadius: 30,
//   marginTop: -50,
//   backgroundColor: color.backgroundDark
// }

const FLEX: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  marginBottom: 8
}

const LOCATION_ICON: TextStyle = {
  color: color.backgroundDark,
  marginRight: 4,
  paddingTop: 4,
  paddingBottom: 5,
  fontSize: 22
}
const HEADER_TEXT: TextStyle = {
  paddingTop: 4,
  paddingBottom: 5,
  marginBottom: 0
}

const BUTTON: ViewStyle = {
  maxWidth: Dimensions.get("window").width > 768 ? 270 : "auto"
}

const BUTTON_ICON: TextStyle = {
  color: color.textWhite,
  padding: 5,
  paddingBottom: 4,
  fontSize: 14
}
const BUTTON_TEXT: TextStyle = {
  color: color.textWhite
}
