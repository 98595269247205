import React from "react"
import { StyleProp, TextInput, TextInputProps, TextStyle, View, ViewStyle } from "react-native"
import { color, spacing, typography } from "../../theme"
import { translate, TxKeyPath } from "../../i18n"
import { Text } from "../text/text"

export interface TextFieldProps extends TextInputProps {
  placeholderTx?: TxKeyPath
  placeholder?: string
  labelTx?: TxKeyPath
  label?: string
  style?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<TextStyle>
  preset?: keyof typeof PRESETS

  forwardedRef?: any
}

/**
 * A component which has a label and an input together.
 */
export function TextField(props: TextFieldProps) {
  const {
    placeholderTx,
    placeholder,
    labelTx,
    label,
    preset = "default",
    style: styleOverride,
    inputStyle: inputStyleOverride,
    forwardedRef,
    ...rest
  } = props

  const containerStyles = [CONTAINER, PRESETS[preset], styleOverride]
  const inputStyles = [INPUT, inputStyleOverride]
  const actualPlaceholder = placeholderTx ? translate(placeholderTx) : placeholder

  return (
    <View style={containerStyles}>
      {label && <Text preset="fieldLabel" tx={labelTx} text={label} />}
      <TextInput
        placeholder={actualPlaceholder}
        placeholderTextColor={color.palette.gray4}
        underlineColorAndroid={color.transparent}
        {...rest}
        style={inputStyles}
        ref={forwardedRef}
      />
    </View>
  )
}

const CONTAINER: ViewStyle = {
  marginVertical: 6
}

const INPUT: TextStyle = {
  fontFamily: typography.primary,
  color: color.text,
  borderRadius: 5,
  paddingVertical: 10,
  paddingHorizontal: 16,
  fontSize: 16,
  backgroundColor: color.palette.gray1
}

const PRESETS: { [name: string]: ViewStyle } = {
  default: {}
}
