import React from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"

import { Layout, Account } from "@app/components"
import { color } from "@app/theme"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type LoginScreenProps = StackScreenProps<RootNavigatorParamList, "Login">
export const LoginScreen = observer((props: LoginScreenProps) => {
  // const close = () => props.navigation.goBack()
  const close = () => {
    props.navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })
  }

  return (
    <Layout.ModalScreen style={CONTAINER} onClosePress={close} closeIconPreset="icon">
      <Account.Login style={ACCOUNT} />
    </Layout.ModalScreen>
  )
})

const CONTAINER: ViewStyle = {
  backgroundColor: color.background,
  padding: 24
}
const ACCOUNT: ViewStyle = {
  alignSelf: "center",
  maxWidth: 900
}
