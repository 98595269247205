import React from "react"
import { GestureResponderEvent } from "react-native"
import ReactMapViewMaps from "react-native-web-maps"
import { MapViewProps, LatLng, MapPressEvent, Camera } from "react-native-maps"
import { Circle } from "react-google-maps"
import { ExtendedMapCircleProps } from "./map-view.types"

type WebLatLng = {
  lat: () => number
  lng: () => number
}
const convertFromWebLatLng = (coordinate: WebLatLng): LatLng => ({
  latitude: coordinate.lat(),
  longitude: coordinate.lng()
})
const convertToWebLatLng = (coordinate: LatLng): Record<keyof WebLatLng, number> => ({
  lat: coordinate.latitude,
  lng: coordinate.longitude
})

interface ViewPress extends GestureResponderEvent {
  latLng: WebLatLng
  jb: {
    x: number
    y: number
  }
}

const convertMapPress = (onPress: (e: MapPressEvent) => void) => (e: ViewPress) => {
  onPress({
    ...e,
    nativeEvent: {
      coordinate: convertFromWebLatLng(e.latLng),
      ...(e.jb && { position: { x: e.jb.x, y: e.jb.y } }),
      action: "press"
    }
  })
}

ReactMapViewMaps.prototype.animateCamera = function (camera: Partial<Camera>) {
  this.setState({ zoom: camera.zoom })
  this.setState({ center: convertToWebLatLng(camera.center) })
}

export const MapView = React.forwardRef(function MapView({ onPress, ...props }: MapViewProps, ref) {
  return (
    <ReactMapViewMaps
      ref={ref}
      onPress={convertMapPress(onPress)}
      onRegionChange={props.onTouchStart}
      onRegionChangeComplete={props.onTouchEnd}
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: defaultMapOptions.styles
      }}
      defaultOptions={defaultMapOptions}
      {...props}
    />
  )
})

export const MapViewMarker = ReactMapViewMaps.Marker
export const MapViewPolyline = ReactMapViewMaps.Polyline
export const MapViewCallout = ReactMapViewMaps.Callout
export const MapViewCircle = ({ center, onPress, ...props }: ExtendedMapCircleProps) => {
  return (
    <Circle
      onClick={(e) => {
        convertMapPress(onPress)(e as unknown as ViewPress) // TODO(Zakarias): this might break but did work look at this
      }}
      options={{
        strokeColor: props.strokeColor,
        strokeWeight: props.strokeWidth,
        fillColor: props.fillColor
      }}
      center={convertToWebLatLng(center)}
      {...props}
    />
  )
}

const defaultMapOptions = {
  styles: [
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ]
}
