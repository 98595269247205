import { cast, Instance, SnapshotOut, types } from "mobx-state-tree"
import { CatalogApi } from "@app/services/api/catalog-api"
import { withEnvironment } from "@app/models/extensions/with-environment"

export const CatalogPageModel = types.model("CatalogPage", {
  thumb: types.string,
  view: types.string,
  zoom: types.string
})

export interface CatalogPage extends Instance<typeof CatalogPageModel> {}
export interface CatalogPageSnapshot extends SnapshotOut<typeof CatalogPageModel> {}

/**
 * Model description here for TypeScript hints.
 */
export const CatalogModel = types
  .model("Catalog")
  .props({
    id: types.identifier,
    runFrom: types.maybe(types.string),
    runTo: types.maybe(types.string),
    images: types.maybe(
      types.model({
        view: types.maybe(types.string),
        thumb: types.maybe(types.string)
      })
    ),
    branding: types.maybe(
      types.model({
        name: types.maybe(types.string)
      })
    ),
    _pages: types.maybe(types.array(CatalogPageModel))
  })
  .extend(withEnvironment)
  .actions((self) => ({
    savePages: (pages: CatalogPageSnapshot[]) => {
      self._pages = cast(pages)
    }
  }))
  .actions((self) => ({
    loadPages: async () => {
      const catalogApi = new CatalogApi(self.environment.api)
      const result = await catalogApi.getCatalogPages(self.id)

      if (result.kind === "ok") {
        self.savePages(result.data)
      }
    }
  }))
  .views((self) => ({
    get pages() {
      if (!self._pages) {
        self.loadPages()
      }
      return self._pages
    }
  }))

export interface Catalog extends Instance<typeof CatalogModel> {}
export interface CatalogSnapshot extends SnapshotOut<typeof CatalogModel> {}
export const createCatalogDefaultModel = () => types.optional(CatalogModel, {})
