import React, { FC, useState, useMemo, useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
  Dimensions,
  ViewStyle,
  TextStyle,
  ImageStyle,
  View,
  ScrollView,
  SectionList,
  Platform
} from "react-native"
import * as Analytics from "expo-firebase-analytics"
import { StackScreenProps } from "@react-navigation/stack"
import { getISOWeek, format } from "date-fns"
import { sv } from "date-fns/locale"
import { Badge } from "react-native-elements"

import {
  Screen,
  Text,
  Tag,
  OfferGroup,
  OfferCardProps,
  Footer,
  Svg,
  CustomBannerAd,
  ChangeLocationBigBanner,
  AutoImage,
  OFFER_CARD_SUB_TITLE,
  ChangeLocationBanner
} from "@app/components"
import { CompositeScreenProps, EventArg } from "@react-navigation/native"
import { HomeScreenProps } from "@app/navigators"
import { Catalog, INITIAL_LOCATION, INITIAL_RADIUS, useStores } from "@app/models"
import { color, spacing } from "@app/theme"
import { useGetLandingPageQuery } from "@app/services/graphql/generated"
import { HomeNavigatorParamList } from "@app/navigators/navigator-params"
import { daysSince } from "@app/utils/misc"

/* -------------------------------------- */

/* -------------------------------------- */
const ROOT: ViewStyle = {
  backgroundColor: color.palette.white
}
const CENTER: ViewStyle = {
  alignItems: "center"
}
const HEADER_CONTAINER: ViewStyle = {
  ...CENTER,
  marginTop: Dimensions.get("window").width > 768 ? 32 : 24,
  marginBottom: 24
}
const HORIZONTAL_SCROLL_VIEW_CONTAINER: ViewStyle = {
  marginVertical: spacing[4],
  flexGrow: 1,
  justifyContent: "center",
  ...(Platform.OS !== "web" && {
    marginHorizontal: spacing[4] / 2
  })
}

const HORIZONTAL_SCROLL_VIEW_MARGIN: ViewStyle = Platform.select({
  native: {},
  web: {
    marginHorizontal: spacing[4] / 4
  }
})

const LOGO: ImageStyle = {
  marginBottom: 12,
  display: Dimensions.get("window").width > 768 ? "none" : "flex"
}

const TITLE: TextStyle = {
  marginBottom: 8
}

const SUB_TITLE: TextStyle = {
  color: color.palette.gray4
}

const HeaderSection = ({ item }) => {
  return (
    <View style={HEADER_CONTAINER}>
      {/* <Logo style={LOGO} variant="primary" /> */}
      <View style={LOGO}>
        <Svg preset="VeckanseLogoPink" />
      </View>
      <Text style={TITLE} preset="headerLarge" text={item.title} />
      <Text style={SUB_TITLE} preset="default" text={item.subTitle} />
    </View>
  )
}
const TagsSection = ({ item }) => {
  return (
    <ScrollView
      contentContainerStyle={HORIZONTAL_SCROLL_VIEW_CONTAINER}
      snapToAlignment="start"
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      pagingEnabled={true}
    >
      <View style={HORIZONTAL_SCROLL_VIEW_MARGIN} />
      {item.isLoading && Array.from(Array(5)).map((_, idx) => <Tag key={idx} isLoading />)}
      {item.tags.map((tag) => (
        <Tag key={tag.text} onPress={tag.onPress} text={tag.text} selected={tag.selected} />
      ))}
      <View style={HORIZONTAL_SCROLL_VIEW_MARGIN} />
    </ScrollView>
  )
}

const LocationSection = ({ item }) => {
  const { latitude, longitude } = item.location
  const isVisible =
    INITIAL_LOCATION.latitude === latitude &&
    INITIAL_LOCATION.longitude === longitude &&
    INITIAL_RADIUS === item.radius
  return isVisible && <ChangeLocationBanner />
}

const CarouselBannerSection = ({ item }) => {
  return (
    <ScrollView
      contentContainerStyle={HORIZONTAL_SCROLL_VIEW_CONTAINER}
      horizontal={true}
      snapToAlignment="start"
      showsHorizontalScrollIndicator={false}
      pagingEnabled={true}
    >
      <View style={HORIZONTAL_SCROLL_VIEW_MARGIN} />
      {item.isLoading &&
        Array.from(Array(3)).map((_, idx) => <CustomBannerAd key={idx} isLoading />)}
      {item.banners.map((banner, idx) => (
        <CustomBannerAd
          key={idx}
          url={banner.url}
          title={banner.title}
          subtitle={banner.subtitle}
          imageUrl={banner.image.data?.attributes.url}
        />
      ))}
      <View style={HORIZONTAL_SCROLL_VIEW_MARGIN} />
    </ScrollView>
  )
}

export const BannerSection = ({ item }) => {
  const ww = Dimensions.get("window").width
  const imageStyle = !item.withMargin ? IMAGE_BANNER : [IMAGE_BANNER, IMAGE_BANNER_BOTTOM_MARGIN]
  return Platform.select({
    native: (
      <AutoImage
        maxWidth={ww <= 620 ? ww - 20 : 600}
        style={imageStyle}
        source={{
          uri: "https://veckanse-cms-stage.s3.eu-north-1.amazonaws.com/media/SMS_Bild_Large_5b84b94262.png?updated_at=2022-03-31T15:49:31.288Z"
        }}
      />
    ),
    web: (
      <View style={imageStyle}>
        <div className="adplugg-tag" {...item.attributes} />
      </View>
    )
  })
}

const OfferSection = ({ item }) => {
  const offers: OfferCardProps[] = item.isLoading
    ? Array.from(Array(12)).map(() => ({ isLoading: true }))
    : item.offers

  return offers.length > 0 ? (
    <OfferGroup
      style={OFFER_SECTION}
      title={item.title}
      offers={offers}
      horizontal={item.horizontal}
    />
  ) : (
    <ChangeLocationBigBanner />
  )
}
const OFFER_SECTION: ViewStyle = {
  minHeight: Dimensions.get("window").height,
  justifyContent: "flex-start",
  marginBottom: spacing[7]
}
const FooterSection = () => <Footer />

const today = new Date()
const HEADER_DATA = {
  title: "Veckans erbjudanden",
  subTitle: `Vecka ${getISOWeek(today)} • ${format(today, "LLLL", {
    locale: sv
  })} • ${today.getFullYear()}`
}
const section = (data, renderItem) => ({ data, renderItem })

export interface OfferScreenProps
  extends CompositeScreenProps<
    StackScreenProps<HomeNavigatorParamList, "Offers">,
    HomeScreenProps
  > {
  tabPressListener: (callback: (e: EventArg<"tabPress", true, undefined>) => void) => void
}
export const OffersScreen = observer((props: OfferScreenProps) => {
  // const nextScreen = () => navigation.navigate("Demo")
  // const { someStore, anotherStore } = useStores()
  // const navigation = useNavigation()
  const { dealerStore, location, radius } = useStores()
  // const [excludedCategories, setExcludedCategories] = useState([]) // props.route.params.category

  const scrollRef = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState(props.route.params?.category || "alla")
  const catalogsRunFrom = useMemo(
    () =>
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10)
      ).toISOString(),
    []
  )

  useEffect(() => {
    const unsubscribe = props.tabPressListener((e) => {
      scrollRef.current?.scrollToLocation({ itemIndex: 0, sectionIndex: 0 })
      e.preventDefault()
    })
    return unsubscribe
  }, [props.navigation])

  const {
    loading: isPageLoading,
    data: pageData,
    error: pageError
  } = useGetLandingPageQuery({
    variables: {
      catalogsRunFrom,
      // distanceFilter: {
      //   longitude: location.longitude,
      //   latitude: location.latitude,
      //   distance: radius
      //   // distance: null
      // },
      useNearByStores: true,
      userLongitude: location.longitude,
      userLatitude: location.latitude,
      distanceLimit: radius
      // excludedCategories: []
    }
  })

  // const offers: OfferCardProps[] = dealerStore.dealerfront.map(({ dealer, catalogs }) => ({
  //   title: catalogs[0]?.branding?.name,
  //   // subTitle: `Till ${catalogs[0]?.branding?.name} >`,
  //   subTitle: `Till annonsören >`,
  //   catalog: catalogs[0],
  //   onStorePress: (o, e) => navigation.navigate("Dealer", { dealerId: catalogs[0].dealer_id }),
  //   onOfferPress: ({ catalog }, e) => {
  //     navigation.navigate("Catalog", { catalogId: catalog.id })
  //   }
  // }))
  const tags = useMemo(() => {
    if (isPageLoading || pageError) return []
    const categories = pageData.landingPage.data?.attributes?.categories?.data || []

    // const allCategorySlugs = categories.map((c) => c.attributes.slug)
    return [{ attributes: { name: "Alla", slug: "alla" } }, ...categories].map(({ attributes }) => {
      const { name, slug } = attributes
      return {
        text: name,
        // selected: excludedCategories.length !== 0 && !excludedCategories.includes(slug),
        selected: selectedCategory === slug,
        onPress: () => {
          Analytics.logEvent("select_content", {
            content_type: "category",
            item_id: slug
          })
          props.navigation.setParams({
            category: slug
          })
          setSelectedCategory(slug)
          // setExcludedCategories(allCategorySlugs.filter((s) => s !== slug))
        }
      }
    })
  }, [selectedCategory, isPageLoading, pageError, pageData])

  const offers: OfferCardProps[] =
    isPageLoading || pageError
      ? []
      : (pageData.landingPage.data?.attributes?.offerCollection?.offerings || [])
          .filter(
            ({ organization }) =>
              selectedCategory === "alla" ||
              organization.data?.attributes.categories.data
                .map((c) => c.attributes.slug)
                .includes(selectedCategory)
          )
          .map(({ organization }, idx) => {
            const store = organization.data?.attributes.stores.data[0]
            const storesNearBy = organization.data?.attributes.nearbyStores.data
            if (store === undefined) return null
            const c = store.attributes.catalogs.data[0]
            if (c === undefined) return null

            const catalog = {
              id: c.id,
              runFrom: c.attributes.runFrom,
              images: {
                thumb: c.attributes.pages.data[0]?.attributes?.image
              }
            } as Catalog
            const subTitle = (
              <View>
                <Text style={OFFER_CARD_SUB_TITLE} text="Fler erbjudanden >" />
                {storesNearBy.length >= 2 && (
                  <Badge
                    value={storesNearBy.length}
                    badgeStyle={BADGE_STYLE}
                    containerStyle={BADGE_CONTAINER_STYLE}
                  />
                )}
              </View>
            )
            const { expectedCatalogsUpdateFrequency } = organization.data?.attributes || {}
            const showWarning =
              expectedCatalogsUpdateFrequency && catalog.runFrom
                ? daysSince(catalog.runFrom) > expectedCatalogsUpdateFrequency
                : false

            return {
              title: organization.data?.attributes.name,
              subTitle,
              showWarning: showWarning,
              catalog,
              onStorePress: (o, e) =>
                props.navigation.navigate("Dealer", {
                  dealerId: organization.data?.attributes.slug
                }),
              onOfferPress: ({ catalog }, e) => {
                props.navigation.navigate("Catalog", { catalogId: catalog.id })
              }
            } as OfferCardProps
          })
          .filter(Boolean)

  // isPageLoading || pageError
  //   ? []
  //   :
  const sections = [
    section([{ key: "section:header", ...HEADER_DATA }], HeaderSection),
    section(
      [
        {
          key: "section:banner-carousel",
          banners: pageData?.landingPage?.data?.attributes?.carouselBanner || [],
          isLoading: isPageLoading
        }
      ],
      CarouselBannerSection
    ),
    section(
      [{ key: "section:banner-bottom", attributes: { "data-adplugg-zone": "ve_top_placement" } }],
      BannerSection
    ),
    section([{ key: "section:tags", tags, isLoading: isPageLoading }], TagsSection),
    section([{ key: "section:location", location, radius }], LocationSection),
    section(
      [
        {
          key: "section:offers",
          title: "Alla erbjudanden",
          offers,
          horizontal: false,
          isLoading: isPageLoading
        }
      ],
      OfferSection
    ),
    section(
      [
        {
          key: "section:banner-top",
          attributes: { "data-adplugg-zone": "ve_bottom_600_" },
          withMargin: true
        }
      ],
      BannerSection
    )
    // section([{ key: "section:footer" }], FooterSection)
  ]

  // const [selectedTagIndex, setSelectedTagIndex] = useState<number>(0)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await dealerStore.loadDealerfront()
  //     // console.log(dealerStore.dealers.toJSON())
  //   }
  //   fetchData()
  // }, [])

  return (
    <Screen style={ROOT} preset="fixed">
      <SectionList
        ref={scrollRef}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={SECTION_LIST_CONTAINER}
        ListFooterComponent={FooterSection}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: ListFooterComponentStyle is used in source https://github.com/facebook/react-native/blob/main/Libraries/Lists/VirtualizedList.js#L899
        ListFooterComponentStyle={SECTION_LIST_FOOTER}
        sections={sections}
      />
    </Screen>
  )
})

const SECTION_LIST_CONTAINER: ViewStyle = {
  maxWidth: 840,
  width: "100%",
  alignSelf: "center",
  minHeight: Dimensions.get("window").height - 80 + 346 // minus header plus footer
}
const SECTION_LIST_FOOTER: ViewStyle = {
  ...(Dimensions.get("window").width > 840 && {
    marginHorizontal: -(Dimensions.get("window").width - 840) / 2
  }),
  marginTop: "auto"
}

const BORDER_RADIUS = 10
const IMAGE_BANNER: ImageStyle = {
  alignSelf: "center",
  marginLeft: spacing[4],
  marginRight: spacing[4],
  borderRadius: BORDER_RADIUS,
  backgroundColor: "transparent",
  shadowColor: color.palette.black,
  shadowOffset: {
    width: 0,
    height: 1
  },
  shadowOpacity: 0.16,
  shadowRadius: 16,
  overflow: "hidden",
}
const IMAGE_BANNER_BOTTOM_MARGIN: ImageStyle = {
  marginBottom: spacing[8]
}

const BADGE_STYLE: ViewStyle = {
  backgroundColor: color.primary,
  width: 20,
  height: 20,
  borderRadius: 18
}
const BADGE_CONTAINER_STYLE: ViewStyle = {
  position: "absolute",
  right: -24
}
