import { Api } from "@app/services/api"
import { GOOGLE_CLOUD_API_KEY } from "@app/config/env"
import * as Location from "expo-location"

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    if (__DEV__) {
      // dev-only services
    }
    this.api = new Api()
  }

  async setup() {
    // allow each service to setup
    await this.api.setup()
    Location.setGoogleApiKey(GOOGLE_CLOUD_API_KEY)
  }

  /**
   * Our api.
   */
  api: Api
}
