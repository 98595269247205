/**
 * A "modern" sleep statement.
 *
 * @param ms The number of milliseconds to wait.
 */
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Convert from camelCase to snake_case
 */
export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

/**
 * Convert from snake_case to camelCase
 */
export const snakeToCamelCase = (str: string) =>
  str.replace(/(_\w)/g, (match) => match[1].toUpperCase())

/**
 * Convert object keys from snake_case to camelCase
 */
export const keysToCamelCase = (o: { [key: string]: any }) =>
  Object.entries(o).reduce((acc, [key, val]) => {
    if (val && typeof val === "object" && !Array.isArray(val)) {
      val = keysToCamelCase(val)
    } else if (Array.isArray(val)) {
      val = val.map((item) => {
        if (item && typeof item === "object" && !Array.isArray(item)) {
          return keysToCamelCase(item)
        }
        return item
      })
    }
    acc[snakeToCamelCase(key)] = val
    return acc
  }, {})

/**
 * Convert object keys from camelCase to snake_case
 */
export const keysToSnakeCase = (o: { [key: string]: any }) =>
  Object.entries(o).reduce((acc, [key, val]) => {
    acc[camelToSnakeCase(key)] = val
    return acc
  }, {})

/**
 * Convert hex to alpha hex
 */
export const addAlphaToHex = (hex: string, opacity: number) => {
  const rxHex = /^#([0-9a-f]{6})$/i

  const match = rxHex.exec(hex)
  if (!match) throw new Error("invalid hex")
  if ((opacity >= 0 && opacity <= 1) !== true) throw new Error("invalid opacity")

  const alphaHex = parseInt(String(opacity * 255), 10).toString(16)
  return `#${match[1]}${alphaHex}`
}

export const throttle = (callback: (args: any) => void, interval: number) => {
  let allow = true
  let handler: ReturnType<typeof setTimeout>

  return (...args: any) => {
    if (!allow) return

    allow = false
    callback.apply(this, args)
    clearTimeout(handler)
    handler = setTimeout(() => {
      allow = true
    }, interval)
  }
}

export const debounce = (callback: (args: any) => void, interval: number) => {
  let handler: ReturnType<typeof setTimeout>

  return (...args: any) => {
    clearTimeout(handler)
    handler = setTimeout(() => {
      callback.apply(this, args)
    }, interval)
  }
}

export const daysSince = (dateTime: string) => {
  const now = new Date()
  const diff = now.getTime() - new Date(dateTime).getTime()
  return Math.floor(diff / (1000 * 60 * 60 * 24))
}
