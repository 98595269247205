import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import {
  ScrollView,
  SectionList,
  View,
  ViewStyle,
  TextStyle,
  ImageStyle,
  Dimensions
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { CompositeScreenProps } from "@react-navigation/native"

import { Catalog, useStores } from "@app/models"
import { HomeScreenProps } from "@app/navigators"
import {
  AutoImage,
  Button,
  ChangeLocationBigBanner,
  Footer,
  OfferCardProps,
  OfferGroup,
  Screen,
  Text
} from "@app/components"
import { color, spacing } from "@app/theme"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { useGetDealerPageQuery } from "@app/services/graphql/generated"
import { BannerSection } from "@app/screens/offers/offers-screen"
import { HomeNavigatorParamList } from "@app/navigators/navigator-params"
import { daysSince } from "@app/utils/misc"

export type DealerScreenProps = CompositeScreenProps<
  StackScreenProps<HomeNavigatorParamList, "Dealer">,
  HomeScreenProps
>
export const DealerScreen = observer((props: DealerScreenProps) => {
  const { radius, location } = useStores()
  const { params } = props.route
  const catalogsRunFrom = useMemo(
    () =>
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10)
      ).toISOString(),
    []
  )
  const {
    loading: isPageLoading,
    data: pageData,
    error: pageError
  } = useGetDealerPageQuery({
    variables: {
      organizationSlug: params.dealerId,
      catalogsRunFrom,
      distanceFilter: {
        longitude: location.longitude,
        latitude: location.latitude,
        distance: radius
      }
    }
  })
  const goHome = () =>
    props.navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })

  const offers: OfferCardProps[] =
    isPageLoading || pageError
      ? []
      : pageData.organizations.data[0]?.attributes.stores.data
          .map((store) => {
            const c = store.attributes.catalogs.data[0]
            if (c === undefined) return null
            const catalog = {
              id: c.id,
              runFrom: c.attributes.runFrom,
              images: {
                thumb: c.attributes.pages.data[0]?.attributes?.image
              }
            } as Catalog

            const { expectedCatalogsUpdateFrequency } =
              pageData?.organizations?.data[0]?.attributes || {}
            const showWarning =
              expectedCatalogsUpdateFrequency && catalog.runFrom
                ? daysSince(catalog.runFrom) > expectedCatalogsUpdateFrequency
                : false
            return {
              title: store.attributes.name,
              subTitle: pageData.organizations.data[0].attributes.name,
              showWarning: showWarning,
              catalog: catalog,
              onStorePress: () =>
                props.navigation.navigate("Dealer", {
                  dealerId: pageData.organizations.data[0].attributes.slug
                }),
              onOfferPress: ({ catalog }) => {
                props.navigation.navigate("Catalog", { catalogId: catalog.id })
              }
            }
          })
          .filter(Boolean) || []
  const sections = useMemo(() => {
    // if (isPageLoading || pageError) return []
    const o = pageData?.organizations?.data[0]
    // if (!o) return []
    return [
      section(
        [
          {
            key: "section:header",
            title: o?.attributes?.name,
            description: o?.attributes?.description,
            logo: o?.attributes?.logo?.data?.attributes?.url,
            goHome: goHome
          }
        ],
        HeaderSection
      ),
      section([{ key: "section:tags", tags: TAGS_DATA }], TagsSection),
      section(
        [
          {
            key: "section:offers",
            title: "Erbjudanden nära dig",
            offers,
            horizontal: false,
            isLoading: isPageLoading
          }
        ],
        OfferSection
      ),
      section([{ key: "section:banner" }], BannerSection)
      // section([{ key: "section:footer" }], FooterSection)
    ]
  }, [params.dealerId, isPageLoading])

  return (
    <Screen style={ROOT} preset="fixed">
      <SectionList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={SECTION_LIST_CONTAINER}
        ListFooterComponent={FooterSection}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: ListFooterComponentStyle is used in source https://github.com/facebook/react-native/blob/main/Libraries/Lists/VirtualizedList.js#L899
        ListFooterComponentStyle={SECTION_LIST_FOOTER}
        sections={sections}
      />
    </Screen>
  )
})

const SECTION_LIST_CONTAINER: ViewStyle = {
  maxWidth: 840,
  width: "100%",
  minHeight: Dimensions.get("window").height - 80,
  alignSelf: "center"
}
const SECTION_LIST_FOOTER: ViewStyle = {
  ...(Dimensions.get("window").width > 840 && {
    marginHorizontal: -(Dimensions.get("window").width - 840) / 2
  }),
  marginTop: "auto"
}
const ROOT: ViewStyle = {
  backgroundColor: color.palette.white
}
const HEADER_CONTAINER: ViewStyle = {
  padding: 16
}
const TAGS_CONTAINER: ViewStyle = {
  padding: 16
}
const TAGS: ViewStyle = {
  // alignSelf: Dimensions.get("window").width > 768 ? "center" : "flex-start",
  alignSelf: "center",
  flex: 1,
  marginTop: 16
}

const BACK_BUTTON: ViewStyle = {
  alignSelf: "flex-start"
}

const TITLE: TextStyle = {
  marginBottom: 8
}
const DESCRIPTION: TextStyle = {
  maxWidth: 700,
  textAlign: "center"
}
const DEALER_LOGO_HEIGHT = 68
const DEALER_LOGO: ImageStyle = {
  resizeMode: "contain",
  borderRadius: 10,
  borderWidth: 2,
  borderColor: color.palette.gray1,
  marginBottom: spacing[4]
}

const BRAND: ViewStyle = {
  alignItems: "center"
}

const HeaderSection = ({ item }) => {
  return (
    <View style={HEADER_CONTAINER}>
      <Button style={BACK_BUTTON} onPress={item.goHome} preset="back">
        <Feather style={textPresets.back} name="chevron-left" />
        <Text style={textPresets.back} text="Tillbaka" />
      </Button>
      <View style={BRAND}>
        {item.logo && (
          <AutoImage
            fadeIn
            maxHeight={DEALER_LOGO_HEIGHT}
            style={DEALER_LOGO}
            source={{ uri: item.logo }}
          />
        )}
        {item.title && <Text style={TITLE} preset="headerLarge" text={item.title} />}
        {item.description && <Text style={DESCRIPTION} preset="default" text={item.description} />}
      </View>
    </View>
  )
}
const TagsSection = () => {
  return (
    <ScrollView
      contentContainerStyle={TAGS_CONTAINER}
      style={TAGS}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      pagingEnabled={true}
    >
      {/* {item.tags.map((tag, idx) => (
        <Tag key={idx} onPress={() => undefined} text={tag.text} selected={tag.selected} />
      ))} */}
    </ScrollView>
  )
}
const OfferSection = ({ item }) => {
  const offers = item.isLoading
    ? Array.from(Array(12)).map(() => ({ isLoading: true }))
    : item.offers

  return offers.length > 0 ? (
    <OfferGroup
      style={OFFER_SECTION}
      title={item.title}
      offers={offers}
      horizontal={item.horizontal}
    />
  ) : (
    <ChangeLocationBigBanner />
  )
}
const OFFER_SECTION: ViewStyle = {
  minHeight: Dimensions.get("window").height / 2,
  justifyContent: "flex-start",
  marginBottom: spacing[7]
}

const FooterSection = () => <Footer />

const TAGS_DATA = [
  { text: "Erbjudanden", selected: true },
  { text: "Butiker", selected: false }
  // { text: "Info", selected: false }
]
const section = (data, renderItem) => ({ data, renderItem })
