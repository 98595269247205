import React from "react"
import { observer } from "mobx-react-lite"
import { SvgProps as RNSvgProps } from "react-native-svg"

import { Svgs, SvgPreset } from "./svgs"

export interface SvgProps extends RNSvgProps {
  preset: SvgPreset
}

export const Svg = observer((props: SvgProps) => {
  const { preset, ...restProps } = props

  const SvgComp = Svgs[preset]
  return <SvgComp {...restProps} />
})
