import { Instance, SnapshotOut, types, cast } from "mobx-state-tree"
import * as Analytics from "expo-firebase-analytics"

import * as storage from "@app/utils/storage"
import { CharacterStoreModel } from "@app/models/character-store/character-store"
import { DealerStoreModel } from "@app/models/dealer-store/dealer-store"
import { CatalogStoreModel } from "@app/models/catalog-store/catalog-store"
import { ButikStoreModel } from "@app/models/butik-store/butik-store"
import { ButikModel, ButikSnapshot } from "@app/models/butik/butik"
import { CatalogModel, CatalogSnapshot } from "@app/models/catalog/catalog"
import { createUserDefaultModel, UserSnapshot } from "@app/models/user/user"
import { withEnvironment } from "@app/models/extensions/with-environment"

import { UserApi, GetOauthConnect } from "@app/services/api/user-api"
import { apolloClient } from "@app/services/apollo/client"
import { UPDATE_USER_ME } from "@app/services/graphql/mutations/updateMe"

const LocationModel = types.model("Location", {
  latitude: types.maybe(types.number),
  longitude: types.maybe(types.number),
  country: types.maybe(types.string),
  district: types.maybe(types.string),
  isoCountryCode: types.maybe(types.string),
  name: types.maybe(types.string),
  postalCode: types.maybe(types.string),
  region: types.maybe(types.string),
  street: types.maybe(types.string),
  city: types.maybe(types.string)
})

export interface Location extends Instance<typeof LocationModel> {}
export interface LocationSnapshot extends SnapshotOut<typeof LocationModel> {}

export const INITIAL_LOCATION = { latitude: 59.3274, longitude: 18.0653 }
export const INITIAL_RADIUS = 20000

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model("RootStore")
  .props({
    userConsent: types.optional(types.boolean, false),
    sessionCount: types.optional(types.number, 0),
    characterStore: types.optional(CharacterStoreModel, {} as any),
    dealerStore: types.optional(DealerStoreModel, {} as any),
    catalogStore: types.optional(CatalogStoreModel, {} as any),
    butikStore: types.optional(ButikStoreModel, {} as any),

    dealerScreenFront: types.optional(
      types.array(
        types.model({
          butik: types.reference(ButikModel),
          catalog: types.maybe(types.reference(CatalogModel))
        })
      ),
      []
    ),
    dealerScreenOffset: types.optional(types.number, 0),
    findLocationOnMount: types.optional(types.boolean, false),
    location: types.optional(LocationModel, INITIAL_LOCATION),
    radius: types.optional(types.number, INITIAL_RADIUS),
    user: createUserDefaultModel()
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setSessionCount: (val: number) => {
      self.sessionCount = val
    },
    setFindLocationOnMount: (val: boolean) => {
      self.findLocationOnMount = val
    },
    setUserConsent: (val: boolean) => {
      self.userConsent = val
    },
    setDealerScreenFront: (
      arr: { butik: ButikSnapshot["id"]; catalog: CatalogSnapshot["id"] }[]
    ) => {
      self.dealerScreenFront = cast(arr)
    },
    setLocation: (location: LocationSnapshot) => {
      const parseStringElseNull = (arg: any | null) => (arg === null ? null : String(arg))
      const properties: Record<string, string> = {
        user_location_latitude: parseStringElseNull(location.latitude),
        user_location_longitude: parseStringElseNull(location.longitude),
        user_location_postal_code: parseStringElseNull(location.postalCode),
        user_location_region: parseStringElseNull(location.region),
        user_location_district: parseStringElseNull(location.district),
        user_location_country: parseStringElseNull(location.country),
        user_location_name: parseStringElseNull(location.name)
      }

      Object.keys(location).forEach((key) => {
        if (location[key] === null) location[key] = undefined
      })

      Analytics.setUserProperties(properties)
      Analytics.logEvent("set_location", properties)
      self.location = cast(location)
    },
    setRadius: (radius: number) => {
      const properties = { user_location_radius: String(radius) }
      Analytics.setUserProperties(properties)
      Analytics.logEvent("set_radius", properties)
      self.radius = radius
    },
    setUser: (user: UserSnapshot) => {
      Analytics.setUserId(user.id)
      Analytics.setUserProperties({ user_logged_in: user.id ? "true" : "false" })
      self.user = cast(user)
    },
    logout: () => {
      Analytics.logEvent("logout", { user_id: self.user.id })
      self.user = undefined
      Analytics.setUserId(null)
      Analytics.setUserProperties({ user_logged_in: "false" })
      storage.remove("token")
    },
    setJwtToken: (jwt: string) => {
      storage.save("token", jwt)
    }
  }))
  .actions((self) => ({
    login: (user: UserSnapshot, jwt: string) => {
      Analytics.logEvent("login", { user_id: user.id })
      self.setJwtToken(jwt)
      self.setUser(user)
    }
  }))
  .actions((self) => ({
    updateSelectedLocation: async (location: LocationSnapshot) => {
      if (self.user) {
        return await apolloClient.mutate({
          mutation: UPDATE_USER_ME,
          variables: {
            data: {
              selectedLocation: location
            }
          }
        })
      }
    },
    userOauthConnect: async (args: GetOauthConnect): Promise<UserSnapshot | null> => {
      const userApi = new UserApi(self.environment.api)
      const result = await userApi.getOauthConnect(args)
      if (result.kind === "ok") {
        const { jwt, user } = result.data
        user.id = String(user.id)
        if (self.user) self.logout()
        self.login(user, jwt)
        return user
      }
      return null
    },
    dealerScreenFrontLoadMore: async (dealerId: string) => {
      const butikerIds = await self.butikStore.getButiker({
        dealerIds: [dealerId].join(","),
        orderBy: "distance",
        limit: 9,
        offset: self.dealerScreenOffset,
        rLat: self.location.latitude,
        rLng: self.location.longitude
      })
      if (!butikerIds.length) return

      /* --- this should work when the danish fix api --- */
      // const catalogsIds = await self.catalogStore.getCatalogs({
      //   ids: butikerIds,
      //   byIdsType: "store_ids"
      // })
      /* --- temp fix --- */
      const catalogsIds = await Promise.all(
        butikerIds.map((butikId) =>
          self.catalogStore
            .getCatalogs({
              ids: [butikId],
              byIdsType: "store_ids"
            })
            .then((ids) => ids[0])
        )
      )
      /* --- /temp fix --- */
      if (!catalogsIds.length) return

      const front = [
        ...self.dealerScreenFront.map(({ butik, catalog }) => ({
          butik: butik.id,
          catalog: catalog.id
        })),
        ...butikerIds
          .map((butikId, idx) => ({
            butik: butikId,
            catalog: catalogsIds[idx]
          }))
          .filter(({ catalog }) => catalog !== undefined)
      ]
      self.setDealerScreenFront(front)
    }
  }))

export interface RootStore extends Instance<typeof RootStoreModel> {}
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
