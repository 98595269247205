import React from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  Dimensions,
  ImageStyle,
  Image,
  Alert,
  Platform
} from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import * as Linking from "expo-linking"
import ContentLoader, { Path } from "react-content-loader/native"

import { color, typography, spacing } from "@app/theme"
import { Text, Button } from "@app/components"
import { textPresets } from "@app/components/button/button.presets"
import FadeIn from "react-native-fade-in-image"

export interface CustomBannerAdProps {
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  url?: string
  imageUrl?: string
  isLoading?: boolean
  style?: StyleProp<ViewStyle>
}

export const CustomBannerAd = observer((props: CustomBannerAdProps) => {
  const onPress = async () => {
    const supported = await Linking.canOpenURL(props.url)
    if (supported) {
      if (Platform.OS === "web") {
        window.open(props.url, "_blank")
      } else {
        await Linking.openURL(props.url)
      }
    } else {
      Alert.alert(`Don't know how to open this URL: ${props.url}`)
    }
  }
  return props.isLoading ? (
    <CustomBannerAdLoader style={CONTAINER} />
  ) : (
    <View style={flatten([CONTAINER, props.style])}>
      {props.imageUrl && (
        <FadeIn>
          <Image style={IMAGE} source={{ uri: props.imageUrl }} resizeMode="cover" />
        </FadeIn>
      )}
      <View style={TEXTS}>
        <Text style={TITLE} numberOfLines={3}>{props.title}</Text>
        <Button onPress={onPress} preset="linkTight" style={LEFT}>
          <Text style={textPresets.back}>{props.subtitle}</Text>
        </Button>
      </View>
    </View>
  )
})

const CustomBannerAdLoader = (props) => (
  <ContentLoader
    speed={2}
    width={345}
    height={83}
    viewBox="0 0 345 83"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <Path d="M 6.594 0.42 C 3.95 1.486 1.917 3.524 0.714 6.31 C 0.072 7.796 -0.301 71.262 0.312 74.811 c 0.573 3.323 2.657 6.104 5.6 7.475 L 7.448 83 h 330.279 l 1.646 -0.755 c 1.971 -0.905 3.828 -2.709 4.87 -4.729 l 0.758 -1.471 V 7.025 l -0.759 -1.534 c -0.968 -1.957 -2.729 -3.706 -4.699 -4.667 l -1.546 -0.753 l -165.14 -0.053 C 18.132 -0.03 7.646 -0.006 6.594 0.42 z" />
  </ContentLoader>
)

const screenWidth = Dimensions.get("window").width;

const CONTAINER: ViewStyle = {
  backgroundColor: color.palette.pink1,
  borderRadius: 10,
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: 'stretch',
  alignSelf: "center",
  width: screenWidth < 600 ? screenWidth * 1 - 32 : "auto",
  maxWidth: 650,
  minWidth: 345,
  minHeight: 83,
  marginHorizontal: spacing[2]
}

const TITLE: TextStyle = {
  fontFamily: typography.primaryBold,
  fontSize: Dimensions.get("window").width > 768 ? 18 : 16,
  color: color.title,
  paddingLeft: 4,
  paddingBottom: 4,
  flexShrink: 1,
  textAlign: "left",
}

const TEXTS: ViewStyle = {
  alignItems: "flex-start",
  padding: 16,
  flex: 1
}

const LEFT: ViewStyle = {
  alignItems: "flex-start",
}

const IMAGE: ImageStyle = {
  flex: 1,
  backgroundColor: color.palette.gray2,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
  width: Dimensions.get("window").width > 768 ? 140 : 110,
  resizeMode: 'cover',
  height: '100%',
}

function useCallback(arg0: () => Promise<void>, arg1: any[]) {
  throw new Error("Function not implemented.")
}
