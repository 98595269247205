import React, { ErrorInfo } from "react"
import { TextStyle, View, ViewStyle, ScrollView, ImageStyle } from "react-native"
import { color, typography, spacing } from "@app/theme"
import { Button, Icon, Text } from "@app/components"
import { Feather } from "@expo/vector-icons"

const CONTAINER: ViewStyle = {
  alignItems: "center",
  flex: 1,
  padding: 16,
  paddingVertical: 50,
  backgroundColor: color.background
}

// const ERROR_DETAILS_CONTAINER: ViewStyle = {
//   width: "100%",
//   maxHeight: "60%",
//   backgroundColor: color.line,
//   marginVertical: 15,
//   paddingHorizontal: 10,
//   paddingBottom: 15,
//   borderRadius: 6,
// }

const BTN_RESET: ViewStyle = {
  backgroundColor: color.primary
}

const TITLE_ERROR: TextStyle = {
  fontFamily: typography.primaryBold,
  fontSize: 18,
  color: color.palette.pink5,
  paddingVertical: spacing[4]
}

const FRIENDLY_SUBTITLE: TextStyle = {
  color: color.palette.black,
  fontWeight: "normal",
  paddingBottom: spacing[6],
  textAlign: "center"
}

// const CONTENT_ERROR: TextStyle = {
//   color: color.error,
//   fontWeight: "bold",
//   paddingVertical: 15,
// }

// const CONTENT_BACKTRACE: TextStyle = {
//   color: color.dim,
// }

const ICON: ImageStyle = {
  marginTop: 30,
  width: 64,
  height: 64
}

const ERROR_ICON: TextStyle = {
  color: color.palette.pink5,
  fontSize: 60
}

export interface ErrorComponentProps {
  error: Error
  errorInfo: ErrorInfo
  onReset(): void
}

export const ErrorComponent = (props: ErrorComponentProps) => {
  return (
    <View style={CONTAINER}>
      <Feather style={ERROR_ICON} name="alert-octagon" />
      <Text style={TITLE_ERROR} tx={"errorScreen.title"} />
      <Text style={FRIENDLY_SUBTITLE} tx={"errorScreen.friendlySubtitle"} />
      {/* <View style={ERROR_DETAILS_CONTAINER}>
        <ScrollView>
          <Text selectable style={CONTENT_ERROR} text={`${props.error}`} />
          <Text selectable style={CONTENT_BACKTRACE} text={`${props.errorInfo.componentStack}`} />
        </ScrollView>
      </View> */}
      <Button style={BTN_RESET} onPress={props.onReset} tx="errorScreen.reset" />
    </View>
  )
}
