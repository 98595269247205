import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { Dimensions, Platform, ViewStyle } from "react-native"
import { useModalize } from "react-native-modalize/lib/utils/use-modalize"
import { Modalize } from "react-native-modalize"
import { flatten } from "ramda"

import { ModalContent, ModalContentVariant } from "./modal-content"
import { Button } from "@app/components"
import { transform } from "@babel/core"
// import { useDimensions } from "react-native-modalize/lib/utils/use-dimensions"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { spacing } from "@app/theme"

interface ProviderProps {
  children?: React.ReactNode
}
interface IModal {
  openState: "opening" | "open" | "closing" | "closed"
  panGestureEnabled: boolean
  variant: ModalContentVariant
  openModal: () => void
  closeModal: () => void
  toggleModal: () => void
  setModalVariant: (variant: ModalContentVariant) => void
  setPanGestureEnabled: (val: boolean) => void
}

export const Context = createContext<IModal>({} as IModal)
export const useModal = () => useContext(Context)

export const Provider: React.FC<ProviderProps> = (props) => {
  const [state, setState] = useState<Pick<IModal, "openState" | "variant" | "panGestureEnabled">>({
    openState: "closed",
    variant: "navigation",
    panGestureEnabled: true
  })
  const { ref, open, close } = useModalize()

  const toggleModal = () => {
    ;["open", "opening"].includes(state.openState) ? close() : open()
  }
  const setModalVariant = (variant: ModalContentVariant) => {
    setState((state) => ({ ...state, variant }))
  }
  const setPanGestureEnabled = (val: boolean) => {
    setState((state) => ({ ...state, panGestureEnabled: val }))
  }
  const onClose = () => {
    setState((state) => ({ ...state, openState: "closing" }))
  }
  const onClosed = () => {
    setState((state) => ({ ...state, openState: "closed" }))
  }
  const onOpen = () => {
    setState((state) => ({ ...state, openState: "opening" }))
  }
  const onOpened = () => {
    setState((state) => ({ ...state, openState: "open" }))
  }
  return (
    <Context.Provider
      value={{
        ...state,
        openModal: open,
        closeModal: close,
        toggleModal,
        setModalVariant,
        setPanGestureEnabled
      }}
    >
      {props.children}
      <Modalize
        ref={ref}
        // modalHeight={365}
        // modalHeight={465}
        withOverlay={true}
        modalStyle={flatten([
          MODAL,
          ["location", "register"].includes(state.variant) ? HOVER : [],
          ["consent", "welcome"].includes(state.variant) ? HOVER_BOTTOM : []
        ])}
        // overlayStyle={{ flex: 1, backgroundColor: "green" }}
        // rootStyle={{ justifyContent: "center", flex: 1, backgroundColor: "yellow" }}
        // childrenStyle={{flex:1, backgroundColor:'red'}}
        // handleStyle={{backgroundColor:'orange'}}
        onOpened={onOpened}
        onOpen={onOpen}
        onClosed={onClosed}
        onClose={onClose}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        adjustToContentHeight
        panGestureEnabled={["consent"].includes(state.variant) ? false : state.panGestureEnabled}
        closeOnOverlayTap={!["consent"].includes(state.variant)}
        withHandle={!["consent"].includes(state.variant)}
      >
        <ModalContent variant={state.variant} />
      </Modalize>
    </Context.Provider>
  )
}

export const Modal = {
  Context,
  Provider
}

const MODAL: ViewStyle = {
  alignSelf: "center",
  width: "100%",
  maxWidth: 410,
  minWidth: Dimensions.get("window").width > 768 ? 410 : "92%"
  // minHeight: Dimensions.get("window").width > 768 ? 512 : 320
}
const HOVER: ViewStyle = {
  marginBottom: "auto",
  borderRadius: 12,
  maxWidth: 960,
  width: "92%"
}

const HOVER_BOTTOM: ViewStyle = {
  ...HOVER,
  ...Platform.select({
    web: {
      minHeight: "auto",
      width: "auto"
    }
  }),
  marginHorizontal: spacing[3],
  marginBottom: spacing[3]
}
