import { Butik } from "@app/models"
import { Api } from "@app/services/api"
import { DEFAULT_API_CONFIG } from "@app/services/api/api-config"
import { keysToSnakeCase } from "@app/utils/misc"

export interface GetButiker {
  dealerIds: string
  limit: number
  offset?: number
  orderBy: "distance"
  rLat?: number
  rLng?: number
}

export class ButikApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getButiker(args: GetButiker) {
    return await this.api.get<Butik[]>(
      `${DEFAULT_API_CONFIG.urlExternalV2}/stores`,
      keysToSnakeCase(args)
    )
  }
}
