import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const DealerModel = types
  .model("Dealer")
  .props({
    id: types.identifier,
    name: types.maybe(types.string),
    logo: types.maybe(types.string),
    color: types.maybe(types.string),
    description: types.maybe(types.string)
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface Dealer extends Instance<typeof DealerModel> {}
export interface DealerSnapshot extends SnapshotOut<typeof DealerModel> {}
export const createDealerDefaultModel = () => types.optional(DealerModel, {})
