import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, ViewStyle, View, TouchableOpacity, ImageStyle, Dimensions } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import * as Analytics from "expo-firebase-analytics"

import { OfferViewer, Text, AutoImage, Layout } from "@app/components"
import { color, spacing } from "@app/theme"
import { useGetCatalogPageQuery } from "@app/services/graphql/generated"
import { useStores } from "@app/models"
import { addAlphaToHex, daysSince } from "@app/utils/misc"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"
import { Feather } from "@expo/vector-icons"

export type CatalogScreenProps = StackScreenProps<RootNavigatorParamList, "Catalog">
export const CatalogScreen = observer((props: CatalogScreenProps) => {
  const close = () => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack()
    } else {
      props.navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })
    }
  }
  const { location } = useStores()
  // const { catalogStore } = useStores()
  // const catalog = catalogStore.catalogs.get(props.route.params.catalogId)

  const {
    loading: isPageLoading,
    data: pageData
    // error: pageError
  } = useGetCatalogPageQuery({
    variables: {
      catalogId: props.route.params.catalogId,
      distanceFilter: {
        longitude: location.longitude,
        latitude: location.latitude,
        distance: null
      }
    }
  })

  // useEffect(() => {
  //   const fetchData = async () =>
  //     await catalogStore.getCatalogs({
  //       ids: [props.route.params.catalogId],
  //       byIdsType: "catalog_ids"
  //     })
  //   if (!catalog) {
  //     fetchData()
  //   }
  // }, [])
  useEffect(() => {
    if (pageData) {
      const store = pageData?.catalog?.data?.attributes?.stores?.data[0]
      Analytics.logEvent("catalog_view", {
        catalog_id: props.route.params.catalogId,
        catalog_checksum: pageData?.catalog?.data?.attributes?.checksum,
        store_name: store?.attributes?.name,
        organization_name: store?.attributes?.organization?.data?.attributes?.name
      })
    }
  }, [pageData])

  const store = pageData?.catalog?.data?.attributes?.stores?.data[0]
  const { organization } = store?.attributes || {}
  const navigateToDealer = () => {
    props.navigation.navigate("Tabs", {
      screen: "Home",
      params: { screen: "Dealer", params: { dealerId: organization?.data?.attributes.slug } }
    })
  }

  const reportToAnalytics = (name: string, properties: Record<string, any>) => {
    Analytics.logEvent(name, {
      catalog_id: props.route.params.catalogId,
      catalog_checksum: pageData?.catalog?.data?.attributes?.checksum,
      store_name: store?.attributes?.name,
      organization_name: store?.attributes?.organization?.data?.attributes?.name,
      ...properties
    })
  }
  const reportCatalogPageView = (idx: number) => {
    reportToAnalytics("catalog_page_view", {
      catalog_page_index: idx
    })
  }
  const onPageView = (idxs: number[]) => {
    idxs.forEach((idx) => {
      reportCatalogPageView(idx)
    })
  }
  const onZoomStart = (idxs: number[]) => {
    // not yet working
    // idxs.forEach((idx) => {
    //   __DEV__ && console.log("zoom start")
    //   throttle(() => {
    //     reportToAnalytics("catalog_page_zoom_start", {
    //       catalog_page_index: idx
    //     })
    //   }, 1000)
    // })
  }
  const onZoomReset = (idxs: number[]) => {
    // not yet working
    // idxs.forEach((idx) => {
    //   throttle(() => {
    //     __DEV__ && console.log("zoom reset")
    //     reportToAnalytics("catalog_page_zoom_reset", {
    //       catalog_page_index: idx
    //     })
    //   }, 1000)
    // })
  }

  const showMightHaveExpiredWarning: boolean = useMemo(() => {
    const { runFrom } = pageData?.catalog?.data?.attributes || {}
    const { expectedCatalogsUpdateFrequency } = organization?.data?.attributes || {}
    return expectedCatalogsUpdateFrequency && runFrom
      ? daysSince(runFrom) > expectedCatalogsUpdateFrequency
      : false
  }, [pageData])

  return (
    <Layout.ModalScreen onClosePress={close} closeIconPreset="iconBlack">
      {/* <OfferViewer images={catalog?.pages?.map((page) => ({ uri: page.zoom }))} /> */}
      {!isPageLoading && (
        <React.Fragment>
          {organization !== null && (
            <View style={HEADER_CONTAINER}>
              <View style={HEADER}>
                <TouchableOpacity onPress={navigateToDealer}>
                  <AutoImage
                    maxHeight={DEALER_LOGO_HEIGHT}
                    style={DEALER_LOGO}
                    source={{ uri: organization?.data?.attributes.logo.data?.attributes.url }}
                  />
                </TouchableOpacity>
                <View style={HEADER_TEXT}>
                  <View style={HEADER_TITLE_CONTAINER}>
                    <TouchableOpacity onPress={navigateToDealer}>
                      <Text
                        style={HEADER_TITLE}
                        text={organization?.data?.attributes.name}
                        preset="bold"
                      />
                    </TouchableOpacity>
                    <Text
                      style={HEADER_AFTERTITLE}
                      text={store?.attributes.name.replace(
                        `${organization?.data?.attributes.name}`,
                        " "
                      )}
                      numberOfLines={1}
                    />
                  </View>
                  <TouchableOpacity onPress={navigateToDealer}>
                    <Text style={HEADER_SUBTITLE} text="Fler erbjudanden >" />
                  </TouchableOpacity>
                </View>
              </View>
              {showMightHaveExpiredWarning && (
                <View style={EXPIRED_WARNING}>
                  <Feather style={WARNING_ICON} name="alert-triangle" />
                  <Text style={EXPIRED_LABEL} text="Erbjudandet kan ha utgått" />
                </View>
              )}
            </View>
          )}
          <OfferViewer
            onPageView={onPageView}
            onZoomStart={onZoomStart}
            onZoomReset={onZoomReset}
            images={pageData?.catalog?.data?.attributes?.pages?.data?.map((page) => ({
              uri: page.attributes.image
            }))}
          />
        </React.Fragment>
      )}
    </Layout.ModalScreen>
  )
})
const HEADER_CONTAINER: ViewStyle = {
  zIndex: 1,
  left: 12,
  marginTop: Dimensions.get("window").width > 768 ? -52 : -57,
  flexDirection: "row",
  ...(Dimensions.get("window").width <= 512 && {
    flexDirection: "column"
  }),
  ...(Dimensions.get("window").width <= 768 && {
    left: 7
  }),
  ...(Dimensions.get("window").width <= 368 && {
    marginTop: -46,
    marginRight: 12,
    marginBottom: -5,
    padding: 0,
    transform: [{ scale: 0.7 }]
  })
}
const HEADER: ViewStyle = {
  maxWidth: Dimensions.get("window").width < 1550 ? 210 : 202,
  backgroundColor: addAlphaToHex(color.palette.black, 0.5),
  borderRadius: 15,
  marginVertical: spacing[2],
  flexDirection: "row",
  alignSelf: Dimensions.get("window").width > 768 ? "flex-start" : "flex-start",
  alignItems: "center",
  padding: 7,
  ...(Dimensions.get("window").width <= 768 && {
    maxWidth: null
  }),
  ...(Dimensions.get("window").width <= 368 && {
    height: 48,
    borderRadius: 12
  })
}
const EXPIRED_WARNING: ViewStyle = {
  paddingHorizontal: 10,
  paddingVertical: 6,
  alignSelf: "flex-start",
  borderRadius: 15,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 80,
  backgroundColor: addAlphaToHex(color.palette.black, 0.5)
}

const WARNING_ICON: TextStyle = {
  color: color.textWhite,
  fontSize: 16,
  marginRight: 8
}

const EXPIRED_LABEL: TextStyle = {
  color: color.palette.white,
  fontSize: 14
}

// const HEADER: ViewStyle = {
//   backgroundColor: addAlphaToHex(color.palette.black, 0.5),
//   borderRadius: 15,
//   zIndex: 1,
//   marginVertical: spacing[2],
//   marginTop: Dimensions.get("window").width > 768 ? -58 : -60,
//   flexDirection: "row",
//   alignSelf: Dimensions.get("window").width > 768 ? "center" : "flex-start",
//   alignItems: "center",
//   paddingVertical: 5,
//   paddingHorizontal: 7,
//   ...(Dimensions.get("window").width <= 1400 && {
//     marginTop: Dimensions.get("window").width > 768 ? -64 : -57,
//     marginVertical: spacing[0],
//     transform: [{ scale: 0.85 }],
//   }),
//   ...(Dimensions.get("window").width <= 768 && {
//     margin: spacing[2],
//     marginVertical: spacing[2],
//     transform: [{ scale: 1 }],
//   }),
//   ...(Dimensions.get("window").width <= 368 && {
//     height: 48,
//     borderRadius: 12,
//     marginTop: -46,
//     marginRight: 12,
//     marginBottom: -5,
//     padding: 0,
//     transform: [{ scale: 0.7 }]
//   })
// }

const HEADER_TEXT: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  display: Dimensions.get("window").width < 1630 ? "none" : "flex",
  paddingLeft: spacing[2],
  paddingRight: spacing[1],
  ...(Dimensions.get("window").width <= 768 && {
    display: "flex"
  })
}
const HEADER_TITLE_CONTAINER: ViewStyle = {
  alignItems: "baseline",
  flexDirection: "row"
}
const HEADER_TITLE: TextStyle = {
  color: color.palette.white
}
const HEADER_AFTERTITLE: TextStyle = {
  paddingLeft: 4,
  color: color.palette.white,
  fontSize: 14
}
const HEADER_SUBTITLE: TextStyle = {
  color: color.palette.white,
  fontSize: 14,
  marginTop: -3
}
const DEALER_LOGO_HEIGHT = 40
const DEALER_LOGO: ImageStyle = {
  resizeMode: "contain",
  borderRadius: 10
}
