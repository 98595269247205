import * as React from "react"
import Svg, { G, Path, Rect, Circle } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={203.202}
    height={167.773}
    {...props}
  >
    <G fill="#f5ecec">
      <Path d="M131.186 9.448c32.414-1.522 47.4 17.623 67.7 67.782 10.191 25.18-10.068 35.051-28.429 51.634-18.031 16.284-33.992 39.383-67.031 38.9-66.344-.964-41.645-19.46-60.331-38.9C25.554 110.611-11.38 106.826 6.92 47.709S98.772 10.97 131.186 9.448Z" />
      <Path d="M133.62 2.001c-6.76 0-13.94.433-21.542.892-8.576.518-17.445 1.053-26.861 1.053-1.5 0-2.99-.014-4.436-.042a241.873 241.873 0 0 0-4.68-.046c-12.46 0-22.478 1.038-30.628 3.174s-14.7 5.446-20 10.109c-5.15 4.536-9.329 10.551-12.776 18.386-3.234 7.351-5.912 16.394-8.428 28.459A111.435 111.435 0 0 0 2.284 77.05a67.75 67.75 0 0 0-.132 10.61 37.96 37.96 0 0 0 4.1 15.323c4.352 8.214 11.567 13.094 19.206 18.26 3.177 2.149 6.463 4.371 9.584 6.876a222.567 222.567 0 0 1 17.482 15.808c3.5 3.43 6.524 6.392 9.741 8.994a47.851 47.851 0 0 0 10.606 6.614 32.819 32.819 0 0 0 13.48 2.464c7.157 0 15.822-1.483 25.756-4.408a224.055 224.055 0 0 0 27.918-10.538c17.224-7.717 33.548-17.07 41.427-22.494.815-.561 1.626-1.111 2.41-1.642 7.867-5.331 14.082-9.541 16.389-19.473 1.254-5.4 1.281-12.186.083-20.752-1.326-9.484-4.138-21.094-8.6-35.494a115.98 115.98 0 0 0-4.911-13.177 75.146 75.146 0 0 0-5.605-10.355 46.3 46.3 0 0 0-13.137-13.59 44.984 44.984 0 0 0-15.791-6.424 88.7 88.7 0 0 0-18.665-1.654m0-2c27.1 0 47.724 6.892 60.018 46.608 19.363 62.55 6.054 67.815-11.063 79.6S99.896 173.96 72.038 161.36c-14.179-6.417-19.727-16.805-38.256-31.679-17.92-14.385-41.258-19.15-31.469-66.1 9.5-45.54 24.149-61.72 73.787-61.72 1.537 0 3.114.016 4.719.046q2.216.042 4.4.041c17.775-.001 34.004-1.947 48.403-1.947Z" />
    </G>
    <Path
      d="M88.345 46.964a4.051 4.051 0 0 1 4.974 2.843l12.005 44.801a4.051 4.051 0 0 1-2.887 4.95l-79.793 21.38a4.051 4.051 0 0 1-4.975-2.842L5.665 73.294a4.051 4.051 0 0 1 2.887-4.949Z"
      fill="#c5497d"
    />
    <Path
      d="M5.613 73.04s28.874-3.87 53.8 1.517 45.906 20.032 45.906 20.032a4.061 4.061 0 0 1-2.881 4.97l-79.78 21.376a4.061 4.061 0 0 1-4.98-2.863Z"
      fill="#5d404b"
    />
    <Path
      d="M93.252 49.557s-26.84 11.058-45.654 28.166-29.599 40.263-29.599 40.263a4.054 4.054 0 0 0 4.961 2.868l79.492-21.3a4.054 4.054 0 0 0 2.867-4.965Z"
      fill="#9a4767"
    />
    <Path
      d="M62.451 94.617 83.994 63.1l.03-.043 9.227-13.5-87.706 23.5 14.733 7.074.057.028 34.41 16.523a6.128 6.128 0 0 0 7.705-2.065Z"
      fill="#c5497d"
    />
    <G transform="translate(76.528 19.4)">
      <Rect width={72.973} height={71.839} rx={3} fill="#fff" />
      <G transform="translate(5.293 5.293)">
        <Path
          d="M3 1.5A1.5 1.5 0 0 0 1.5 3v5.343a1.5 1.5 0 0 0 1.5 1.5h5.343a1.5 1.5 0 0 0 1.5-1.5V3a1.5 1.5 0 0 0-1.5-1.5H3M3 0h5.343a3 3 0 0 1 3 3v5.343a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
          fill="#f5ecec"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 .378)"
          fill="#9a4767"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 4.537)"
          fill="#f5ecec"
        />
        <Rect
          width={21.552}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 8.696)"
          fill="#f5ecec"
        />
        <Path
          d="m5.533 7.79-.016.015a.64.64 0 0 1-.905 0L2.844 6.037a.64.64 0 0 1 .905-.906l1.286 1.285L7.67 3.78a.662.662 0 1 1 .935.936Z"
          fill="#c5497d"
        />
      </G>
      <G transform="translate(5.293 20.417)">
        <Path
          d="M3 1.5A1.5 1.5 0 0 0 1.5 3v5.343a1.5 1.5 0 0 0 1.5 1.5h5.343a1.5 1.5 0 0 0 1.5-1.5V3a1.5 1.5 0 0 0-1.5-1.5H3M3 0h5.343a3 3 0 0 1 3 3v5.343a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
          fill="#f5ecec"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 .378)"
          fill="#9a4767"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 4.537)"
          fill="#f5ecec"
        />
        <Rect
          width={21.552}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 8.696)"
          fill="#f5ecec"
        />
        <Path
          d="m5.533 7.79-.016.015a.64.64 0 0 1-.905 0L2.844 6.037a.64.64 0 0 1 .905-.906l1.286 1.285L7.67 3.78a.662.662 0 1 1 .935.936Z"
          fill="#c5497d"
        />
      </G>
      <G transform="translate(5.293 35.541)">
        <Path
          d="M3 1.5A1.5 1.5 0 0 0 1.5 3v5.343a1.5 1.5 0 0 0 1.5 1.5h5.343a1.5 1.5 0 0 0 1.5-1.5V3a1.5 1.5 0 0 0-1.5-1.5H3M3 0h5.343a3 3 0 0 1 3 3v5.343a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
          fill="#f5ecec"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 .378)"
          fill="#9a4767"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 4.537)"
          fill="#f5ecec"
        />
        <Rect
          width={21.552}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 8.696)"
          fill="#f5ecec"
        />
        <Path
          d="m5.533 7.79-.016.015a.64.64 0 0 1-.905 0L2.844 6.037a.64.64 0 0 1 .905-.906l1.286 1.285L7.67 3.78a.662.662 0 1 1 .935.936Z"
          fill="#c5497d"
        />
      </G>
      <G transform="translate(5.293 50.665)">
        <Path
          d="M3 1.5A1.5 1.5 0 0 0 1.5 3v5.343a1.5 1.5 0 0 0 1.5 1.5h5.343a1.5 1.5 0 0 0 1.5-1.5V3a1.5 1.5 0 0 0-1.5-1.5H3M3 0h5.343a3 3 0 0 1 3 3v5.343a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3Z"
          fill="#f5ecec"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 .378)"
          fill="#9a4767"
        />
        <Rect
          width={41.969}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 4.537)"
          fill="#f5ecec"
        />
        <Rect
          width={21.552}
          height={1.89}
          rx={0.945}
          transform="translate(14.368 8.696)"
          fill="#f5ecec"
        />
        <Path
          d="m5.533 7.79-.016.015a.64.64 0 0 1-.905 0L2.844 6.037a.64.64 0 0 1 .905-.906l1.286 1.285L7.67 3.78a.662.662 0 1 1 .935.936Z"
          fill="#c5497d"
        />
      </G>
    </G>
    <Path
      d="m143.8 95.62-9.105 20.833 8.785-2.469 3.725 7.216 10.164-21.172ZM179.974 96.251l8.373 21.139-8.694-2.774-3.975 7.081-9.417-21.514Z"
      fill="#5d404b"
    />
    <Path
      d="M135.308 61.627c1.652-2.927 8.55-2.743 8.55-2.743s.046-6.992 3.11-8.605 9.138 2.152 9.138 2.152 3.33-6.03 6.53-5.974 6.248 6.197 6.248 6.197 5.989-3.555 8.957-1.836 2.919 8.71 2.919 8.71 6.879.057 8.483 3.04-2.067 8.895-2.067 8.895 5.916 3.49 5.857 6.838-6.091 6.551-6.091 6.551 3.471 5.574 1.765 8.407-8.587 2.928-8.587 2.928-.198 7.198-3.226 8.928-8.89-2.01-8.89-2.01-3.26 5.645-6.454 5.589-6.324-5.811-6.324-5.811-6.206 3.527-9.207 1.693-2.804-9.033-2.804-9.033-6.896-.335-8.445-3.225 2.246-8.337 2.246-8.337-5.88-3.413-5.821-6.76 6.053-6.63 6.053-6.63-3.591-6.037-1.94-8.964Z"
      fill="#9a4767"
    />
    <Path
      d="M156.603 89.053a1.034 1.034 0 0 1-1.113-1.555l11.32-18.117a1.034 1.034 0 0 1 1.755 1.096l-11.316 18.11a1.031 1.031 0 0 1-.646.466ZM155.766 70.827a2.685 2.685 0 1 0 2.638 2.732 2.688 2.688 0 0 0-2.638-2.732m.036-2.068a4.753 4.753 0 1 1-4.835 4.67 4.753 4.753 0 0 1 4.835-4.67ZM168.017 82.278a2.685 2.685 0 1 0 2.638 2.731 2.688 2.688 0 0 0-2.638-2.731m.036-2.068a4.753 4.753 0 1 1-4.835 4.67 4.753 4.753 0 0 1 4.835-4.67Z"
      fill="#fff"
    />
    <G transform="translate(66.962 68.254)">
      <Circle
        cx={33.611}
        cy={33.611}
        transform="translate(5.795 5.795)"
        fill="#c5497d"
        r={33.611}
      />
      <Path
        d="M39.406 2.758a36.659 36.659 0 0 0-14.264 70.417A36.658 36.658 0 0 0 53.67 5.637a36.416 36.416 0 0 0-14.264-2.879m0-2.758A39.406 39.406 0 1 1 0 39.406 39.406 39.406 0 0 1 39.406 0Z"
        fill="#c5497d"
      />
      <G fill="#fff">
        <Path d="M53.514 52.635v-3.527a7.054 7.054 0 0 0-7.054-7.054H32.351a7.054 7.054 0 0 0-7.054 7.054v3.527Z" />
        <Path d="M53.515 53.325H25.297a.689.689 0 0 1-.689-.689v-3.527a7.744 7.744 0 0 1 7.744-7.744H46.46a7.744 7.744 0 0 1 7.748 7.744v3.527a.689.689 0 0 1-.693.689Zm-27.528-1.379h26.838v-2.837a6.372 6.372 0 0 0-6.365-6.365H32.352a6.365 6.365 0 0 0-6.365 6.365ZM46.461 27.945a7.054 7.054 0 1 1-7.055-7.054 7.054 7.054 0 0 1 7.054 7.054Z" />
        <Path d="M39.406 35.69a7.744 7.744 0 1 1 5.476-2.268 7.693 7.693 0 0 1-5.476 2.268Zm0-14.109a6.365 6.365 0 1 0 6.365 6.365 6.372 6.372 0 0 0-6.365-6.365Z" />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
