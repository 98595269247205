import React from "react"
import { observer } from "mobx-react-lite"
import {
  View,
  ViewStyle,
  TextStyle,
  Dimensions,
  Linking,
  ListRenderItem,
  TouchableOpacity,
  FlatList
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { TabsScreenProps } from "@app/navigators"
import { Button, Screen, Text, Svg } from "@app/components"
import { TextProps } from "@app/components/text/text.props"
import { color, spacing } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { useNavigation } from "@react-navigation/native"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type ListItem = {
  text: string
  preset: TextProps["preset"]
}

type TermsScreenProps = StackScreenProps<RootNavigatorParamList, "Terms">
export const TermsScreen = observer((props: TermsScreenProps) => {
  const goHome = () =>props.navigation.navigate("Tabs", { screen: "Home", params: { screen: 'Offers'} })
  const navigation = useNavigation<TabsScreenProps["navigation"]>()

  const renderItem: ListRenderItem<ListItem> = ({ item }) => {
    switch (item.preset) {
      case "bold":
        return (
          <Text preset={item.preset} style={LIST_ITEM_BOLD}>
            {item.text}
          </Text>
        )
      default:
        return (
          <Text preset={item.preset} style={LIST_ITEM_DEFAULT}>
            {item.text}
          </Text>
        )
    }
  }
  return (
    <Screen style={ROOT} preset="scroll">
      <View style={PAGE_WRAPPER}>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Text style={HEADER_LARGE} preset="headerLarge" text="Användarvillkor" />

        <FlatList
          data={[
            {
              preset: "bold",
              text: "1. Terms of use for Cartero Digital Marketing AB:s service VeckansE (below called veckansE)"
            },
            {
              preset: "default",
              text: "1. Your use of the services of veckansE is governed by these terms of use. By services we refer to all websites, applications and other products of veckansE."
            },
            {
              preset: "default",
              text: "2. These terms of use form a legally binding relationship between you and veckansE. We behold the right to modificate these terms from time to time and recommend that you check them regularly for updates."
            },
            {
              preset: "default",
              text: "3. In addition to these terms, your agreement with veckansE will include the terms of any legal notices we post on veckansE.se or any other of it’s services. These additional terms are also legally binding on you and veckansE. If there is any conflict between the additional terms and the terms of use, the additional terms will supersede and be applicable."
            },
            {
              preset: "bold",
              text: "2. Your acceptance of these terms"
            },
            {
              preset: "default",
              text: "1. You must accept these terms of use before you can use the services of veckansE. You can accept them by clicking to agree when veckansE gives you the option or when you actually use the services. By using the services veckansE will interpret your use as a acceptance of these terms."
            },
            {
              preset: "default",
              text: "2. You may not use the services and may not accept the terms of use if you are not of legal age to form a binding agreement with veckansE."
            },
            {
              preset: "default",
              text: "3. We recommend you to print or save a legal copy of these terms of use for your records, preferably before you continue reading."
            },
            {
              preset: "default",
              text: "4. As an existing or past member or user of VeckansE, you consent that we may automatically enrol you in any new, as well as existing, verticals, channels, businesses, or loyalty programmes forming part of our services and for which you qualify, including VeckansR and Sverige Sparar, joint ventures between Cartero Digital Marketing AB and Addreax Group Limited and Haartland, and that we may transfer your personal data to Addreax Group Limited and Haaartland for this purpose. As part of such automatic enrolment, we or Addreax Group Limited and Haaartland may provide you with newsletters, SMS, push notifications and other relevant mailings via one or more media."
            },
            {
              preset: "bold",
              text: "3. Language of these terms"
            },
            {
              preset: "default",
              text: "1. VeckansE may provide you with a translation of the English version of the terms of use. The English version is the one legally binding and the translation is only a service made for your convenience."
            },
            {
              preset: "bold",
              text: "4. Our provision of VeckansE:s services"
            },
            {
              preset: "default",
              text: "1. VeckansE is a newly established company and is expected to grow and develop. We may change and further develop the services in different ways to respond to the growing extent of the brand. We may not always alert you regarding these changes."
            },
            {
              preset: "default",
              text: "2. VeckansE may stop, suspend or replace the services (or certain parts of the services) provided to you at our sole discretion and without any prior notice to you. If you decide to stop using the services of veckansE, you may do so at any time, unless you expressly agree otherwise in connection with a separate agreement between you and veckansE or through additional terms."
            },
            {
              preset: "default",
              text: "3. We reserve the rights to set limits of the services at any time, such as search limits and storage limits."
            },
            {
              preset: "default",
              text: "4. VeckansE reserves the right to pre-screen, review, flag, filter, modify, reject or remove any content from the services."
            },
            {
              preset: "default",
              text: "5. VeckansE uses hyperlinks to third party websites as part of our provision of the services. You agree that veckansE is not responsible for any content provided on third party websites. We are also not responsible for any loss or damage that a third website may cause."
            },
            {
              preset: "bold",
              text: "5. Your use of VeckansE:s services"
            },
            {
              preset: "default",
              text: "1. In order to register an account on veckansE:s services you may have to provide us with information about yourself, such as e-mail and zip code. By using the services you agree to keep this information updated and correct at all times."
            },
            {
              preset: "default",
              text: "2. Please observe that you must have reached an age of 13 years to be allowed to use our services. If you learn that your minor child has provided us with personal data without your consent, please contact us. We will never knowingly collect any personal data from children."
            },
            {
              preset: "default",
              text: "3. You agree to use veckansE’s services only for the purposes permitted by these terms of use and applicable laws in each relevant jurisdiction."
            },
            {
              preset: "default",
              text: "4. You agree that you will not do anything, directly or through a third party, to interfere with or disrupt the services, including the servers and networks connected to them."
            },
            {
              preset: "default",
              text: "5. You agree that you will not reproduce, duplicate, copy, sell, trade or resell the services for any reason, unless veckansE specifically authorizes you to."
            },
            {
              preset: "default",
              text: "6. VeckansE does not take responsibility for damage or loss arising from violation of these terms of use."
            },
            {
              preset: "default",
              text: "7. You agree not to use any veckansE trade name, trademark, service mark, logo, domain name or brand features without our express prior written consent. You further agree not to remove, obscure or alter any proprietary rights notices (including copyright and trademark notices) contained in the services."
            },
            {
              preset: "bold",
              text: "6. Password and account security"
            },
            {
              preset: "default",
              text: "1. You agree to maintain the confidentiality of usernames and passwords associated with any veckansE account you use to access veckansE’s services."
            },
            {
              preset: "default",
              text: "2. You agree that you will be solely responsible for all activities that occur under your veckansE account."
            },
            {
              preset: "bold",
              text: "7. Advertising material, Mmail notifications, and newsletters"
            },
            {
              preset: "default",
              text: "1. We may send mails with updates relating to our services and based on the content in your profile. We may also, based on the content in your profile, send you advertising material with the purpose of marketing services and/or products of the veckansE, or from our partners. Such marketing activities will be sent out by using electronic mailing (e-mail) or text messages (sms). Our intention is that these notifications communications should be relevant for users of veckansE. As a user, you can opt out of these mailings free of charge."
            },
            {
              preset: "default",
              text: "2. We or our partners may also send newsletters with information about our services and advertising which may contain information about i.e. gambling or alcohol."
            },
            {
              preset: "default",
              text: (
                <>
                  3. As a user, you give your consent to receiving notifications, marketing material
                  and newletters, as set out above in this section 7, by your acceptance of these
                  Terms of use. However, you can always, at any time, opt out of these mailings and
                  advertising free of charge, by klicking UNSUBSCRIBE in the e-mails or send a
                  e-mail to{" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("mailto:kundtjanst@veckanse.com")}
                  >
                    <Text style={LINK} text="kundtjanst@veckanse.com" />
                  </TouchableOpacity>
                </>
              )
            },
            {
              preset: "bold",
              text: "8. Privacy Policy and Information"
            },
            {
              preset: "default",
              text: (
                <>
                  1. Please read{" "}
                  <TouchableOpacity onPress={() => navigation.navigate("Policy")}>
                    <Text style={LINK} text="VeckansE privacy policy " />
                  </TouchableOpacity>
                  and information. The content of the privacy policy and information explains how we
                  treat your personal information, and protect your privacy when you use our
                  services.
                </>
              )
            },
            {
              preset: "bold",
              text: "9. Closing your account"
            },
            {
              preset: "default",
              text: "1. Please read VeckansE privacy policy and information by clicking here. The content of the privacy policy and information explains how we treat your personal information, and protect your privacy when you use our services."
            },
            {
              preset: "default",
              text: "2. VeckansE may at any time terminate your use of the services if you act against these terms of use."
            },
            {
              preset: "default",
              text: "3. Modification of these terms and services"
            },
            {
              preset: "default",
              text: "4. We reserve the right to modify these terms of use from time to time and make those modifications publicly available on our services."
            },
            {
              preset: "default",
              text: "5. Your use of the services after a modification constitutes your agreement on the modified terms of use."
            }
          ]}
          renderItem={renderItem}
        />
      </View>
      <Footer />
    </Screen>
  )
})

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height}

const LIST_ITEM_BOLD: ViewStyle = {
  marginTop: spacing[4],
  marginBottom: spacing[2]
}
const LIST_ITEM_DEFAULT: ViewStyle = {
  marginLeft: spacing[3],
  marginBottom: spacing[2]
}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  minHeight: Dimensions.get("window").height,
  alignSelf: "center"
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform:
    Dimensions.get("window").width > 768
      ? [{ scale: 2 }, { rotate: "-245deg" }]
      : [{ scale: 1 }, { rotate: "-245deg" }],
  zIndex: -1
}

const HEADER_LARGE: TextStyle = {
  marginTop: spacing[5],
  marginBottom: spacing[5]
}

const LINK: TextStyle = {
  color: color.palette.pink3
}
