import React, { CSSProperties } from "react"
import { SliderProps } from "@react-native-community/slider"
import MuiSlider from "@mui/material/Slider"
import { color, spacing } from "@app/theme"

import { styled } from "@mui/material/styles"

export const Slider = (props: SliderProps) => {
  const onChange: (event: Event, value: number | number[], activeThumb: number) => void = (
    _,
    value
  ) => {
    props.onValueChange(Array.isArray(value) ? value[0] : value)
  }
  const onChangeCommitted: (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => void = (_, value) => {
    props.onSlidingComplete(Array.isArray(value) ? value[0] : value)
  }
  return (
    <PrettoSlider
      min={props.minimumValue}
      max={props.maximumValue}
      step={0.01}
      value={props.value}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
    />
  )
}
export default Slider

const PrettoSlider = styled(MuiSlider)({
  flexGrow: 0,
  padding: `${spacing[3]}px ${0}`,
  margin: `${0} ${spacing[5]}px`,
  width: "auto",
  color: color.primary,
  height: 5,
  "& .MuiSlider-track": {
    border: "none"
  },
  "& .MuiSlider-rail": {
    color: color.palette.gray3,
  },
  "& .MuiSlider-thumb": {
    height: 22,
    width: 22,
    backgroundColor: color.primary,
    boxShadow: "0 2px 1px rgba(0,0,0,0.1),0 2px 12px rgba(0,0,0,0.18),0 0 0 1px rgba(0,0,0,0.02)",
    //     border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "0 2px 1px rgba(0,0,0,0.1),0 2px 12px rgba(0,0,0,0.18),0 0 0 1px rgba(0,0,0,0.02)"
    },
    "&:before": {
      display: "none"
    }
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
    },
    "& > *": {
      transform: "rotate(45deg)"
    }
  }
})
