import { ApolloClient, NormalizedCacheObject, createHttpLink, ApolloLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"

import { API_BASE_URL, REPLACE_S3_URL_FROM, REPLACE_S3_URL_TO } from "@app/config/env"
import * as storage from "@app/utils/storage"
import { captureGraphqlErrors } from "@app/services/sentry/sentry.common"
import { cache } from "./cache"

const httpLink = createHttpLink({ uri: `${API_BASE_URL}/graphql` })

const authLink = setContext(async (_, { headers }) => {
  const token = await storage.load("token")

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    __DEV__ && console.log({ graphQLErrors, operation })
    captureGraphqlErrors(graphQLErrors, operation)
  }
  if (networkError) __DEV__ && console.log({ networkError })
})

const replaceS3UrlsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const replaceUrls = (obj: any) => {
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          replaceUrls(obj[key])
        } else if (typeof obj[key] === "string") {
          obj[key] = obj[key].replace(REPLACE_S3_URL_FROM, REPLACE_S3_URL_TO)
        }
      }
    }

    replaceUrls(response.data)
    return response
  })
})

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, errorLink, replaceS3UrlsLink, httpLink])
})
