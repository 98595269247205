import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, Dimensions } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"

import { Button, Screen, Text, Account } from "@app/components"
import { color } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type RegisterNewsletterScreenProps = StackScreenProps<RootNavigatorParamList, "RegisterNewsletter">
export const RegisterNewsletterScreen = observer((props: RegisterNewsletterScreenProps) => {
  const goHome = () =>
    props.navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })
  return (
    <Screen style={ROOT} preset="scroll">
      <View style={PAGE_WRAPPER}>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <Account.Register />
      </View>
      <Footer />
    </Screen>
  )
})

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  flex: 1,
  height: "100%"
}

const PAGE_WRAPPER: ViewStyle = {
  maxWidth: 840,
  minHeight: Dimensions.get("window").height,
  width: "100%",
  alignSelf: "center",
  paddingBottom: Dimensions.get("window").width > 768 ? 0 : 60,
  paddingTop: 24,
  paddingHorizontal: 24
}
