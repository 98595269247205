import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, Dimensions, TextStyle } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"

import { Button, Screen, Text, Svg } from "@app/components"
import { color, spacing } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"
// import { useGetFaqPageQuery } from "@app/services/graphql/generated"

type PromotionScreenProps = StackScreenProps<RootNavigatorParamList, "Promotion">
export const PromotionScreen = observer((props: PromotionScreenProps) => {
  const goHome = () =>props.navigation.navigate("Tabs", { screen: "Home", params: { screen: 'Offers'} })

  // const { loading, data } = useGetFaqPageQuery()

  return (
    <Screen style={ROOT} preset="scroll">
      {/* <View style={PAGE_WRAPPER}> */}
      {/* <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View> */}
      {/* <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button> */}
      {/* {!loading && <Markdown>{data.faqPage.data?.attributes?.markdown}</Markdown>} */}
      {/* </View> */}
      <View style={PAGE_WRAPPER}>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <Text style={HEADER_LARGE} preset="headerLarge" text="Kampanjer just nu" />
        <Text
          preset="default"
          text="Välkommen till billigastelavtal.com – en jämförelsesida för att hitta den bästa och billigaste elavtalet på den svenska marknaden. Vi kommer att uppdatera denna sida dagligen så hugg in och börja jämföra i vår topplista nedan. Vägen till ditt billigaste elavtal börjar här och nu!"
        />
        <View style={SECTION}>
          <View style={PROMOTION_CARD}>
            <View style={PROMOTION_CARD_IMAGE}></View>
            <View style={PROMOTION_CARD_CONTENT}>
              <Text style={HEADER_LARGE} preset="header" text="Namn på kampanj" />
              <Text
                preset="default"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec consequat ex. Nullam sed sem non elit auctor facilisis non vitae justo."
              />
            </View>
            <View style={PROMOTION_CARD_CTA}>
              <Text preset="default" text="Du sparar:" />
              <Text style={HEADER_LARGE} preset="headerLarge" text="1 200 kr" />
              <Button preset="wide" text="Teckna direkt" />
            </View>
          </View>
        </View>
      </View>
      <Footer />
    </Screen>
  )
})

const ww = Dimensions.get("window").width
const wh = Dimensions.get("window").height

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height}

const LIST_ITEM_BOLD: ViewStyle = {
  marginTop: spacing[4],
  marginBottom: spacing[2]
}
const LIST_ITEM_DEFAULT: ViewStyle = {
  marginLeft: spacing[3],
  marginBottom: spacing[2]
}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  minHeight: Dimensions.get("window").height,
  alignSelf: "center"
}

const PROMOTION_CARD: ViewStyle = {
  backgroundColor: color.palette.gray1,
  borderRadius: 10,
  // flexDirection: "row",
  flexDirection: Dimensions.get("window").width > 768 ? "row" : "column",
  alignItems: "center",
  marginBottom: 32
}

const PROMOTION_CARD_IMAGE: ViewStyle = {
  width: Dimensions.get("window").width > 768 ? 220 : "100%",
  height: Dimensions.get("window").width > 768 ? "100%" : 220,
  backgroundColor: color.palette.gray2,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: Dimensions.get("window").width > 768 ? 10 : 0,
  borderTopRightRadius: Dimensions.get("window").width > 768 ? 0 : 10,
  flex: Dimensions.get("window").width > 768 ? 1.3 : null
}

const PROMOTION_CARD_CONTENT: ViewStyle = {
  padding: 24,
  flexDirection: "column",
  flexWrap: "wrap",
  flexShrink: 1,
  alignItems: "flex-start",
  alignSelf: "flex-start",
  flex: Dimensions.get("window").width > 768 ? 2 : null
}

const PROMOTION_CARD_CTA: ViewStyle = {
  padding: 24,
  flexDirection: "column",
  flexWrap: "wrap",
  alignItems: "flex-start",
  alignSelf: "stretch",
  justifyContent: "center",
  flex: Dimensions.get("window").width > 768 ? 1 : null
}

const SECTION: ViewStyle = {
  paddingTop: 40
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform:
    Dimensions.get("window").width > 768
      ? [{ scale: 2 }, { rotate: "45deg" }]
      : [{ scale: 1 }, { rotate: "45deg" }],
  zIndex: -1
}

const HEADER_LARGE: TextStyle = {
  marginTop: spacing[2],
  marginBottom: spacing[2]
}

const LINK: TextStyle = {
  color: color.palette.pink3
}
