import { ViewStyle, TextStyle, Dimensions } from "react-native"
import { color, spacing, typography } from "../../theme"

const CONTAINER_VIEW_BASE: ViewStyle = {
  alignItems: "center"
}
const ww = Dimensions.get("window").width
const BASE_VIEW: ViewStyle = {
  paddingVertical: ww >= 368 ? spacing[3] : spacing[2],
  paddingHorizontal: ww >= 368 ? spacing[5] : spacing[4],
  borderRadius: 4
  // justifyContent: "center",
  // alignItems: "center",
}

const BASE_TEXT: TextStyle = {
  // paddingHorizontal: spacing[3],
  fontSize: ww >= 368 ? 16 : 12,
  fontWeight: "900",
  fontFamily: typography.primaryBold,
  textAlign: "left",
  color: color.textWhite
}

export const containerPresets: Record<string, ViewStyle> = {
  primary: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  secondary: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  tertiary: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  wide: { ...CONTAINER_VIEW_BASE, width: "100%" } as ViewStyle,
  link: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  linkTight: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  menu: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  icon: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  iconWhite: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  iconBlack: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  iconPink: { ...CONTAINER_VIEW_BASE } as ViewStyle,
  back: { ...CONTAINER_VIEW_BASE } as ViewStyle
}
export const viewPresets: Record<string, ViewStyle> = {
  primary: { ...BASE_VIEW, backgroundColor: color.primary } as ViewStyle,
  secondary: {
    ...BASE_VIEW,
    backgroundColor: color.palette.white,
    borderColor: color.palette.gray2,
    borderWidth: 2
  } as ViewStyle,
  tertiary: {
    ...BASE_VIEW,
    backgroundColor: color.palette.white,
    borderColor: color.palette.white
  } as ViewStyle,
  wide: {
    ...BASE_VIEW,
    backgroundColor: color.primary,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 500
  } as ViewStyle,
  link: {
    ...BASE_VIEW,
    paddingVertical: spacing[1],
    paddingHorizontal: spacing[4],
    marginRight: 2,
    marginLeft: 2,
    alignItems: "flex-start"
  } as ViewStyle,
  linkTight: {
    ...BASE_VIEW,
    paddingVertical: spacing[0],
    paddingHorizontal: spacing[0],
    paddingLeft: 4,
    alignItems: "flex-start",
  } as ViewStyle,
  menu: {
    ...BASE_VIEW,
    paddingVertical: spacing[3],
    paddingHorizontal: spacing[1],
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
    width: "100%"
  } as ViewStyle,
  icon: {
    ...BASE_VIEW,
    backgroundColor: color.palette.gray1,
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center"
  } as ViewStyle,
  iconWhite: {
    ...BASE_VIEW,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center"
  } as ViewStyle,
  iconBlack: {
    ...BASE_VIEW,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center"
  } as ViewStyle,
  iconPink: {
    ...BASE_VIEW,
    backgroundColor: color.palette.pink1,
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center"
  } as ViewStyle,
  back: {
    ...BASE_VIEW,
    backgroundColor: "rgba(255, 255, 255, 0)",
    borderRadius: 100,
    paddingVertical: 6,
    paddingHorizontal: 0,
    paddingBottom: 16,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start"
  } as ViewStyle
}

export const textPresets: Record<ButtonPresetNames, TextStyle> = {
  primary: { ...BASE_TEXT, color: color.textWhite } as TextStyle,
  secondary: { ...BASE_TEXT, color: color.palette.pink3 } as TextStyle,
  tertiary: { ...BASE_TEXT, color: color.palette.pink3 } as TextStyle,
  link: {
    ...BASE_TEXT,
    color: color.palette.white,
    paddingHorizontal: spacing[1],
    paddingVertical: spacing[2],
    fontFamily: typography.primary
  } as TextStyle,
  linkTight: {
    ...BASE_TEXT,
    color: color.palette.white,
    paddingHorizontal: spacing[0],
    paddingVertical: spacing[0],
    fontFamily: typography.primary,
  } as TextStyle,
  menu: {
    ...BASE_TEXT,
    color: color.palette.gray4,
    paddingRight: spacing[2],
    paddingVertical: 0,
    fontFamily: typography.primary
  } as TextStyle,
  menuIcon: {
    ...BASE_TEXT,
    color: color.palette.gray4,
    paddingRight: spacing[2],
    paddingVertical: 0,
    fontFamily: typography.primary,
    fontSize: 18
  } as TextStyle,
  icon: {
    ...BASE_TEXT,
    color: color.palette.gray4,
    paddingHorizontal: 0,
    paddingVertical: 0,
    fontSize: 26
  } as TextStyle,
  iconWhite: {
    ...BASE_TEXT,
    color: color.textWhite,
    paddingHorizontal: 0,
    paddingVertical: 0,
    fontSize: 26
  } as TextStyle,
  iconBlack: {
    ...BASE_TEXT,
    color: color.textWhite,
    paddingHorizontal: 0,
    paddingVertical: 0,
    fontSize: 26
  } as TextStyle,
  iconPink: {
    ...BASE_TEXT,
    color: color.palette.pink3,
    paddingHorizontal: 0,
    paddingVertical: 0,
    fontSize: 26
  } as TextStyle,
  back: {
    ...BASE_TEXT,
    color: color.palette.pink3,
    paddingHorizontal: 0,
    paddingVertical: 0,
    fontFamily: typography.primary
  } as TextStyle
}

export type ButtonPresetNames = keyof typeof viewPresets
