import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const ButikModel = types
  .model("Butik")
  .props({
    id: types.identifier,
    dealer_id: types.string,
    latitude: types.maybe(types.number),
    longitude: types.maybe(types.number),
    city: types.maybe(types.string),
    street: types.maybe(types.string),
    zip_code: types.maybe(types.string),
    branding: types.maybe(
      types.model({
        name: types.maybe(types.string),
        logo: types.maybe(types.string),
        color: types.maybe(types.string),
        description: types.maybe(types.string)
      })
    )
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface Butik extends Instance<typeof ButikModel> {}
export interface ButikSnapshot extends SnapshotOut<typeof ButikModel> {}
export const createButikDefaultModel = () => types.optional(ButikModel, {})
