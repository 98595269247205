import { LatLng } from "react-native-maps"
import { Geocode, LocationService, LocationServiceBase } from "./location.base"
import { LocationGeocodedAddress } from "expo-location"

// import googleSerivcesJson from "../../google-services.json"

// const GOOGLE_API_URL = "https://maps.googleapis.com/maps/api/geocode/json"
// const GOOGLE_API_KEY = googleSerivcesJson.client[0].api_key[0].current_key

class LocationServiceImpl extends LocationServiceBase implements LocationService {
  async reverseGeocode(coordinate: LatLng): Promise<LocationGeocodedAddress[]> {
    // const res = await fetch(
    //   `${GOOGLE_API_URL}?latlng=${coordinate.latitude},${coordinate.longitude}&key=${GOOGLE_API_KEY}`
    // )
    const res = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate.latitude}&lon=${coordinate.longitude}`
    )

    const geocode = (await res.json()) as Geocode

    return [geocode].map(this.convertGeocodeToLocationGeocodedAddress)
  }
}

export { LocationServiceImpl as LocationService }
