import { LocationGeocodedAddress } from "expo-location"
import { LatLng } from "react-native-maps"

export interface LocationService {
  reverseGeocode(coordinate: LatLng): Promise<Geocode[]>
  convertGeocodeToLocationGeocodedAddress: (geocode: Geocode) => LocationGeocodedAddress
}

export abstract class LocationServiceBase {
  convertGeocodeToLocationGeocodedAddress(geocode: Geocode): LocationGeocodedAddress {
    const address = geocode.address || {}
    return {
      city: address.city || address.town || address.municipality || null,
      district: address.municipality || address.neighbourhood || null,
      streetNumber: null, // Geocode does not have a specific field for street number
      street: address.road || null,
      region: address.county || null,
      subregion: address.iso31662Lvl4 || null,
      country: address.country || null,
      postalCode: address.postcode || null,
      name: geocode.name || null,
      isoCountryCode: address.countryCode || null,
      timezone: null // Geocode does not have a timezone field
    }
  }
}

export interface Geocode {
  placeID?: number
  licence?: string
  osmType?: string
  osmID?: number
  lat?: string
  lon?: string
  class?: string
  type?: string
  placeRank?: number
  importance?: number
  addresstype?: string
  name?: string
  displayName?: string
  address?: Address
  boundingbox?: string[]
}

export interface Address {
  road?: string
  neighbourhood?: string
  isolatedDwelling?: string
  municipality?: string
  county?: string
  iso31662Lvl4?: string
  postcode?: string
  country?: string
  countryCode?: string
  town?: string
  city?: string
  residential?: string
}
