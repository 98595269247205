import Filter from "./filter"
import IllustrationSignup from "./illlustration-signup"
import IllustrationMail from "./illustration-mail"
import PinkBlob from "./pink-blob"
import VeckanseLogoPink from "./veckanse-logo-pink"
import VeckanseLogoWhite from "./veckanse-logo-white"
import VeckanseLogo from "./veckanse-logo"

export const Svgs = {
  Filter,
  IllustrationMail,
  IllustrationSignup,
  PinkBlob,
  VeckanseLogoPink,
  VeckanseLogoWhite,
  VeckanseLogo
}

export type SvgPreset = keyof typeof Svgs
