import React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { spacing } from "@app/theme"
import { Button, useModal, Account } from "@app/components"
import { textPresets } from "@app/components/button/button.presets"
import { Feather } from "@expo/vector-icons"

export interface ModalContentRegisterProps {
  style?: StyleProp<ViewStyle>
}

export const ModalContentRegister = observer((props: ModalContentRegisterProps) => {
  const { closeModal } = useModal()

  return (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={BUTTON_CONTAINER}>
        <Button preset="icon" onPress={closeModal} style={CLOSE_BUTTON}>
          <Feather style={textPresets.icon} name="x" />
        </Button>
      </View>
      <Account.Register />
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  padding: spacing[5],
  flex: 1
}

const BUTTON_CONTAINER: ViewStyle = {
  width: "100%",
  zIndex: 1
}
const CLOSE_BUTTON: ViewStyle = {
  alignSelf: "flex-end"
}
