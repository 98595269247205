import React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import { Feather } from "@expo/vector-icons"

import { color, typography } from "@app/theme"
import { Button, Text, useModal } from "@app/components"
import { INITIAL_LOCATION, INITIAL_RADIUS, useStores } from "@app/models"

export interface LocationButtonProps {
  style?: StyleProp<ViewStyle>
}

export const LocationButton = observer((props: LocationButtonProps) => {
  const { closeModal, openModal, setModalVariant, openState } = useModal()
  const { location, radius } = useStores()

  const openLocation = () => {
    const delay = ["open", "opening"].includes(openState) ? 300 : null
    if (delay) {
      closeModal()
      setTimeout(() => {
        setModalVariant("location")
        openModal()
      }, delay)
    } else {
      setModalVariant("location")
      openModal()
    }
  }
  return (
    <View style={flatten([CONTAINER, props.style])}>
      <Button onPress={openLocation} preset="linkTight" style={LOCATION}>
        <Feather style={LOCATION_PIN} name="map-pin" />
        <View style={LOCATION_TEXT}>
          <Text
            style={LOCATION_AREA}
            text={
              location.city ||
              location.district ||
              location.region?.replace("County", "Län") ||
              "Stockholm"
            }
          />
          <Text style={LOCATION_SUBTITLE}>
            {INITIAL_LOCATION.latitude === location.latitude &&
            INITIAL_LOCATION.longitude === location.longitude &&
            INITIAL_RADIUS === radius
              ? "Ändra plats"
              : `${Math.round(radius / 100) / 10} km`}
            <Feather style={LOCATION_SUBTITLE} name="chevron-down" />
          </Text>
        </View>
      </Button>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center"
}

const LOCATION: ViewStyle = {
  flexDirection: "row",
  alignItems: "center"
}

const LOCATION_TEXT: ViewStyle = {
  flexDirection: "column"
}

const LOCATION_PIN: TextStyle = {
  color: color.palette.pink3,
  fontSize: 32,
  marginRight: 4
}

const LOCATION_AREA: TextStyle = {
  fontFamily: typography.primaryBold,
  flexDirection: "row",
  color: color.palette.pink3,
  marginBottom: -4
}

const LOCATION_SUBTITLE: TextStyle = {
  flexDirection: "row",
  color: color.palette.gray4,
  fontSize: 14,
  marginBottom: 0
}
