import React from "react"
import {
  StyleProp,
  TextStyle,
  ImageStyle,
  View,
  ViewStyle,
  Platform,
  Dimensions,
  Pressable,
  Linking
} from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { color, typography } from "@app/theme"
import { Button, Text, Svg } from "@app/components"
import { useNavigation } from "@react-navigation/native"
import { TabsScreenProps } from "@app/navigators"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  backgroundColor: color.palette.gray5,
  paddingTop: 64,
  paddingLeft: 10,
  paddingRight: 10,
  ...(Platform.OS === "web"
    ? {
        paddingBottom: 56
      }
    : {
        marginBottom: -500, // used to prevent white background showing on scroll bounce
        paddingBottom: 600
      })
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 16,
  textAlign: "center",
  color: color.palette.gray3
}

const TEXT_BOTTOM: TextStyle = {
  paddingTop: 16,
  marginBottom: 20
}

const LOGO: ImageStyle = {
  alignSelf: "center",
  marginTop: 40,
  marginBottom: 32
}

const MAIN_LINKS: ImageStyle = {
  flexDirection: Dimensions.get("window").width > 768 ? "row" : "column",
  alignSelf: "center"
}

export interface FooterProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const Footer = observer((props: FooterProps) => {
  const { style } = props
  const styles = flatten([CONTAINER, style])
  const navigation = useNavigation<TabsScreenProps["navigation"]>()

  return (
    <View style={styles}>
      <View style={MAIN_LINKS}>
        <Button preset="link" onPress={() => navigation.navigate("Faq")} text={"Frågor & svar"} />
        <Button preset="link" onPress={() => navigation.navigate("About")} text={"Om oss"} />
        <Button preset="link" onPress={() => navigation.navigate("Contact")} text={"Kontakt"} />
        <Button
          preset="link"
          onPress={() => Linking.openURL("https://annonsera.veckanse.se/")}
          text={"Annonsera"}
        />
        <Button preset="link" onPress={() => navigation.navigate("Sitemap")} text={"Sitemap"} />
      </View>
      <Pressable style={LOGO} onPress={() => navigation.navigate("Tabs")}>
        <Svg preset="VeckanseLogoWhite" />
      </Pressable>
      <View style={TEXT_BOTTOM}>
        <Text style={TEXT}>
          © 2024 Cartero Digital Marketing AB -
          <Button
            preset="linkTight"
            onPress={() => navigation.navigate("Policy")}
            text={"Sekretesspolicy"}
          />
          ,
          <Button
            preset="linkTight"
            onPress={() => navigation.navigate("Terms")}
            text={"Användarvillkor"}
          />{" "}
          och
          <Button
            preset="linkTight"
            onPress={() => navigation.navigate("Policy")}
            text={"Cookieinställningar"}
          />
          .
        </Text>
      </View>
    </View>
  )
})
