import React, { useEffect, useState, useMemo } from "react"

import { Text, OfferGroup, ChangeLocationBigBanner } from "@app/components"
import { YoastHead } from "@app/components/third-party/newcustomer/yoast-head"
import { styles } from "@app/components/third-party/newcustomer/styles"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "@app/models"
import { useGetDealerPageQuery } from "@app/services/graphql/generated"

const Page = ({ dealerId, slug }) => {
  const [page, setPage] = useState(null)

  const replaceDomain = (url) => {
    return url.replace("https://veckanse.velumi.site", `${location.origin}/sida`)
  }
  const fetchPage = () => {
    const urlSlug = slug ?? dealerId

    fetch("https://veckanse.velumi.site/wp-json/wp/v2/pages?slug=" + urlSlug)
      .then((response) => response.json())
      .then((json) => {
        if (!json[0]) return
        if (json[0].yoast_head_json) {
          json[0].yoast_head_json.canonical = replaceDomain(json[0].yoast_head_json.canonical)
        }
        setPage(json[0])
      })
  }
  useEffect(() => {
    if (dealerId) fetchPage()
  }, [dealerId, slug])

  return (
    page && (
      <>
        <link
          rel="stylesheet"
          href="https://veckanse.velumi.site/wp-includes/css/dist/block-library/style.min.css?ver=6.3"
        />
        {/* <link // Dont load global styles will break our styles
          rel="stylesheet"
          href="https://veckanse.velumi.site/wp-content/themes/tailpress/css/app.css?ver=3.2.0"
        /> */}

        {styles}
        <div className="entry-content" style={FONT_STYLE}>
          <YoastHead yoastHeadJson={page.yoast_head_json} />
          <Text preset="headerLarge" text={page?.title?.rendered} />

          <div
            dangerouslySetInnerHTML={{ __html: page?.content?.rendered.split("{{catalog}}")[0] }}
          />
          <PageCatalogs organizationSlug={dealerId} />
          <div
            dangerouslySetInnerHTML={{ __html: page?.content?.rendered.split("{{catalog}}")[1] }}
          />
        </div>
      </>
    )
  )
}

const PageCatalogs = ({ organizationSlug }) => {
  const { radius, location } = useStores()
  const navigation = useNavigation()
  const catalogsRunFrom = useMemo(
    () =>
      new Date(
        new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10)
      ).toISOString(),
    []
  )
  const {
    loading: isPageLoading,
    data: pageData,
    error: pageError
  } = useGetDealerPageQuery({
    variables: {
      organizationSlug: organizationSlug,
      catalogsRunFrom,
      distanceFilter: {
        longitude: location.longitude,
        latitude: location.latitude,
        distance: radius
      }
    }
  })
  const offersData =
    isPageLoading || pageError
      ? []
      : pageData.organizations.data[0]?.attributes.stores.data
          .map((store) => {
            const c = store.attributes.catalogs.data[0]
            if (c === undefined) return null
            const catalog = {
              id: c.id,
              images: {
                thumb: c.attributes.pages.data[0]?.attributes?.image
              }
            }
            return {
              title: store.attributes.name,
              subTitle: pageData.organizations.data[0].attributes.name,
              // subTitle: ``,
              catalog: catalog,
              onStorePress: () =>
                navigation.navigate("Dealer", {
                  dealerId: pageData.organizations.data[0].attributes.slug
                }),
              onOfferPress: ({ catalog }) => {
                navigation.navigate("Catalog", { catalogId: catalog.id })
              }
            }
          })
          .filter(Boolean) || []
  const offers = isPageLoading ? Array.from(Array(4)).map(() => ({ isLoading: true })) : offersData

  return offers.length > 0 ? (
    <OfferGroup
      title={"Erbjudanden nära dig"}
      offers={offers.filter((_, i) => i < 4)}
      horizontal={false}
    />
  ) : (
    <ChangeLocationBigBanner />
  )
}

export default Page

const FONT_STYLE = {
  fontFamily: "Poppins-Regular",
  margin: 8
}
