import React, { useEffect } from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import * as Analytics from "expo-firebase-analytics"

import { ModalContentNavigation } from "./content/modal-content-navigation"
import { ModalContentLocation } from "./content/modal-content-location"
import { ModalContentRegister } from "./content/modal-content-register"
import { ModalContentConsent } from "./content/modal-content-consent"
import { ModalContentWelcome } from "./content/modal-content-welcome"

const CONTAINER: ViewStyle = {
  flex: 1
}

export type ModalContentVariant = "location" | "navigation" | "register" | "consent" | "welcome"

interface ModalContentProps {
  style?: StyleProp<ViewStyle>
  variant: ModalContentVariant
}

export const ModalContent = observer((props: ModalContentProps) => {
  useEffect(() => {
    Analytics.logEvent(`modal_${props.variant}_opened`)
    return () => {
      Analytics.logEvent(`modal_${props.variant}_closed`)
    }
  }, [])

  return (
    <View style={flatten([CONTAINER, props.style])}>
      {props.variant === "navigation" && <ModalContentNavigation />}
      {props.variant === "location" && <ModalContentLocation />}
      {props.variant === "register" && <ModalContentRegister />}
      {props.variant === "consent" && <ModalContentConsent />}
      {props.variant === "welcome" && <ModalContentWelcome />}
    </View>
  )
})
