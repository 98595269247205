import { gql } from "@apollo/client"

export const UPDATE_USER_ME = gql`
  mutation updateUserMe($data: UsersPermissionsUserInput!) {
    updateMe(data: $data) {
      data {
        id
        attributes {
          username
          email
          confirmed
          blocked
          name
          phoneNumber
        }
      }
    }
  }
`
