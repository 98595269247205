import React, { useEffect, useState } from "react"
import {
  Pressable,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  ImageStyle,
  Dimensions
} from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons"

import { color, spacing, typography } from "@app/theme"
import { Button, Svg, Text, useModal, Checkbox, Account } from "@app/components"
import { textPresets } from "@app/components/button/button.presets"
import { TextField } from "@app/components/text-field/text-field"
import { useNavigation } from "@react-navigation/native"
import { TabsScreenProps } from "@app/navigators"
import { useRegisterMutation } from "@app/services/graphql/generated"
import { UserSnapshot, useStores } from "@app/models"

export interface ModalContentConsentProps {
  style?: StyleProp<ViewStyle>
}

export const ModalContentConsent = observer((props: ModalContentConsentProps) => {
  const { closeModal, openModal, setModalVariant } = useModal()
  const { setUserConsent } = useStores()
  const navigation = useNavigation<TabsScreenProps["navigation"]>()

  const consentAndCloseModal = () => {
    setUserConsent(true)
    closeModal()
  }

  const consentAndNavigateToTerms = () => {
    consentAndCloseModal()
    navigation.navigate("Terms")
  }

  return (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={BUTTON_CONTAINER}>
        <Button preset="icon" onPress={consentAndCloseModal} style={CLOSE_BUTTON}>
          <Feather style={textPresets.icon} name="x" />
        </Button>
      </View>
      <View style={ROW}>
        <View>
          <MaterialCommunityIcons style={ICON} name="cookie" />
        </View>
        <View style={TEXT_CONTAINER}>
          <Text style={TEXT_TITLE} preset="bold" text="Vi använder kakor, mums!" />
          <Text>
            <Text text="Genom att använda VeckansE godkänner du vår " />
            <Pressable onPress={consentAndNavigateToTerms}>
              <Text style={TERMS_TEXT} text="cookie policy" />
            </Pressable>
          </Text>
        </View>
      </View>
      <Button
        preset="wide"
        style={CONSENT_BUTTON}
        onPress={consentAndCloseModal}
        text="Jag godkänner"
      />
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  padding: spacing[4],
  flex: 1
}
const ROW: ViewStyle = {
  flexDirection: "row",
  maxWidth: 400
}
const ICON: TextStyle = {
  overflow:"hidden",
  fontSize: 40,
  padding: 13,
  borderRadius: (40 + 2 * 13) / 2,
  color: color.textWhite,
  backgroundColor: color.backgroundDark
}

const TEXT_TITLE: TextStyle = {
  fontSize: 16,
  paddingRight: spacing[5],
  marginBottom: spacing[1]
}

const TEXT_CONTAINER: ViewStyle = {
  flex: 1,
  marginHorizontal: spacing[3],
  marginBottom: spacing[4]
}

const BUTTON_CONTAINER: ViewStyle = {
  width: "100%",
  zIndex: 1
}
const CLOSE_BUTTON: ViewStyle = {
  alignSelf: "flex-end",
  marginBottom: -20,
}
const CONSENT_BUTTON: ViewStyle = {
  marginTop: spacing[2]
}

const TERMS_TEXT: TextStyle = {
  color: color.primary
}
