import { Catalog, CatalogPage } from "@app/models"
import { Api } from "@app/services/api"
import { DEFAULT_API_CONFIG } from "@app/services/api/api-config"
import { keysToSnakeCase } from "@app/utils/misc"

export interface GetCatalogs {
  byIdsType: "store_ids" | "catalog_ids" | "dealer_ids"
  ids: string[]
}

export class CatalogApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getCatalogs(args: GetCatalogs) {
    return await this.api.get<Omit<Catalog, "_pages">[]>(
      `${DEFAULT_API_CONFIG.urlExternalV2}/catalogs`,
      { [args.byIdsType]: args.ids.join(",") }
    )
  }

  async getCatalogPages(catalogId: string) {
    return await this.api.get<CatalogPage[]>(
      `${DEFAULT_API_CONFIG.urlExternalV2}/catalogs/${catalogId}/pages`
    )
  }
}
