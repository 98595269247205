import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { LayoutChangeEvent, LayoutRectangle, Platform } from "react-native"
import * as Analytics from "expo-firebase-analytics"
import { UserSnapshot, useStores } from "@app/models"
import { useGetMeLazyQuery } from "@app/services/graphql/generated"
import { useModal } from "@app/components"

type Size = Pick<LayoutRectangle, "width" | "height"> | null
export const useComponentSize = (): [Size, (event: LayoutChangeEvent) => void] => {
  const [size, setSize] = useState<Size>(null)

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout
    setSize({ width, height })
  }, [])

  return [size, onLayout]
}

export const useCheckUserLoggedIn = () => {
  const { user, setUser, logout } = useStores()
  const [getUser] = useGetMeLazyQuery()

  useEffect(() => {
    if (user) {
      getUser()
        .then(async ({ data, error }) => {
          if (data?.me) {
            setUser(data.me as UserSnapshot)
          }
          if (error) {
            logout()
          }
        })
        .catch((err) => {
          console.warn(err)
          logout()
        })
    } else {
      Analytics.setUserProperties({ user_logged_in: "false" })
    }
  }, [])
}

export const useCheckUserConsent = () => {
  const { openModal, setModalVariant } = useModal()
  const { userConsent } = useStores()
  useEffect(() => {
    if (!userConsent) {
      setModalVariant("consent")
      openModal()
    }
  }, [])
}

export const useCheckUserWelcome = () => {
  const { openModal, setModalVariant } = useModal()
  const { userConsent, sessionCount } = useStores()

  useEffect(() => {
    if (userConsent && sessionCount === 1) {
      setTimeout(() => {
        setModalVariant("welcome")
        openModal()
      }, 300)
    }
  }, [userConsent, sessionCount])
}

export const useAddEventListener = <K extends keyof HTMLElementEventMap, T>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  ref?: MutableRefObject<T>
) => {
  const isRegistered = useRef(false)
  if (!ref) {
    ref = useRef<T>(null)
  }
  useEffect(() => {
    const isSupported = Platform.OS === "web" && (ref.current as Element)?.addEventListener
    if (isSupported && !isRegistered.current) {
      ;(ref.current as Element).addEventListener(eventName, handler)

      isRegistered.current = true
    }
    return () => {
      if (isSupported) removeEventListener(eventName, handler)
    }
  }, [ref.current])

  return ref
}
