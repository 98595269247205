module.exports = {
  GOOGLE_CLOUD_API_KEY: "AIzaSyDiWUQ3Hi6mS7lMqUkEGATu8sF1G12x4f8",
  GOOGLE_WEB_CLIENT_ID: "276898136072-f6j269b0q8492oche61hvrnp1bigtfln.apps.googleusercontent.com",
  FACEBOOK_APP_ID: "1806376326232458",
  API_BASE_URL: "https://api.veckanse.se",
  REPLACE_S3_URL_FROM: /^https:\/\/veckanse.s3.eu-west-2.amazonaws.com\/(.+)/g,
  REPLACE_S3_URL_TO: "https://s3.veckanse.se/$1",
  SENTRY_TRACES_SAMPLE_RATE: 0.005,
  ENVIRONMENT: "production"
}
