import { LinkingOptions, NavigatorScreenParams } from "@react-navigation/native"
import * as Linking from "expo-linking"

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends RootNavigatorParamList {}
  }
}
export type RootNavigatorParamList = {
  Tabs: NavigatorScreenParams<TabNavigatorParamList> | undefined
  About: undefined
  Contact: undefined
  Advertise: undefined
  Policy: undefined
  Faq: undefined
  Promotion: undefined
  Terms: undefined
  RegisterNewsletter: undefined
  Login: undefined
  Register: undefined
  Sitemap: undefined
  Catalog: { catalogId: string }
}
export type TabNavigatorParamList = {
  Home: NavigatorScreenParams<HomeNavigatorParamList>
}
export type HomeNavigatorParamList = {
  Offers: { category: string }
  Dealer: { dealerId: string }
  ExternalA: { dealerId: string; slug?: string }
  ExternalB: { dealerId: string; slug: string }
}

const prefix = Linking.createURL("/")

export const linking: LinkingOptions<RootNavigatorParamList> = {
  prefixes: [prefix],
  config: {
    initialRouteName: "Tabs",
    screens: {
      Catalog: {
        path: "erbjudande/:catalogId"
      },
      Register: "register",
      Login: "login",
      Contact: "kontakt",
      About: "om-oss",
      Advertise: "annonsera",
      Policy: "sekretesspolicy",
      Terms: "användarvillkor",
      Faq: "faq",
      Promotion: "kampanj",
      RegisterNewsletter: "registrera-nyhetsbrev",
      Sitemap: "sitemap",
      Tabs: {
        path: "",
        screens: {
          Home: {
            path: "",
            screens: {
              Offers: {
                path: "erbjudanden"
              },
              Dealer: {
                path: "butik/:dealerId",
                parse: {
                  dealerId: (name: string) => name // .toLowerCase()
                }
              },
              ExternalA: {
                path: "butik/:dealerId/om",
                parse: {
                  dealerId: (name: string) => name // .toLowerCase()
                }
              },
              ExternalB: {
                path: "butik/:dealerId/:slug",
                parse: {
                  dealerId: (name: string) => name // .toLowerCase()
                }
              }
            }
          }
        }
      }
    }
  }
}