import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={20.982} height={20} {...props}>
    <G
      data-name="Group 943"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={3}
    >
      <Path data-name="Line 83" d="M5.502 18.5v-7" />
      <Path data-name="Line 82" d="M12.03 8.5v-7" />
      <Path data-name="Path 183" d="M1.502 15h4" />
      <Path data-name="Path 182" d="M1.502 5h10.529" />
      <Path data-name="Path 184" d="M11.483 15h8" />
      <Path data-name="Path 181" d="M17.483 5h2" />
    </G>
  </Svg>
)

export default SvgComponent
