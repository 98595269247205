import React, { useEffect, useState } from "react"
import {
  Pressable,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  ImageStyle,
  Dimensions
} from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { color, spacing, typography } from "@app/theme"
import { Button, Svg, Text, useModal } from "@app/components"
import { TextField } from "@app/components/text-field/text-field"
import { useNavigation } from "@react-navigation/native"
import { useLoginMutation } from "@app/services/graphql/generated"
import { UserSnapshot, useStores } from "@app/models"

import { GoogleAuthButton } from "./google-auth-button"
import { TabsScreenProps } from "@app/navigators"
import { FacebookAuthButton } from "./facebook-auth-button"
import { Divider } from "react-native-elements/dist/divider/Divider"

export interface LoginProps {
  style?: StyleProp<ViewStyle>
}

export const Login = observer((props: LoginProps) => {
  const [state, setState] = useState({
    email: "",
    password: ""
  })
  const navigation = useNavigation<TabsScreenProps["navigation"]>()

  const { login } = useStores()
  const { closeModal } = useModal()
  const [loginMutation, { loading: isLoginLoading, data: loginData, error: loginError }] =
    useLoginMutation()

  const onLoginPress = async () => {
    try {
      const { data } = await loginMutation({
        variables: { identifier: state.email, password: state.password }
      })
      if (data.login) {
        login(data.login.user as UserSnapshot, data.login.jwt)
        onAuthenticated()
      }
    } catch {}
  }

  const onAuthenticated = () => {
    setTimeout(() => {
      navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })
      closeModal()
    }, 1000)
  }
  const translateError = (message: string) => {
    const translation = {
      "Response not successful: Received status code 401": "Ogitlig e-postaddress eller lösenord",
      "Invalid identifier or password": "Ogitlig e-postaddress eller lösenord",
      "identifier is a required field": "Ingen e-postaddress angiven",
      "password is a required field": "Inget lösenord angivet",
      "2 errors occurred": "Ingen e-postaddress eller lösenord angivet"
    }
    return translation[message] ?? message
  }

  return loginData ? (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={FLEX_ROW}>
        <Svg style={ILLUSTRATION} preset="IllustrationMail" />
        <View style={FLEX_COL}>
          <Text style={HEADER} preset="header" text="Missa aldrig ett erbjudande!" />
          <Text
            style={SUB_HEADER}
            preset="default"
            text="Få nyhetsbrevet och exklusiva erbjudanden varje vecka!"
          />
          <Text>Du är nu inloggad.</Text>
        </View>
      </View>
    </View>
  ) : (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={FLEX_ROW}>
        <Svg style={ILLUSTRATION} preset="IllustrationMail" />
        <View style={FLEX_COL} accessibilityRole="form">
          <Text style={HEADER} preset="header" text="Missa aldrig ett erbjudande!" />
          <Text
            style={SUB_HEADER}
            preset="default"
            text="Få nyhetsbrevet och exklusiva erbjudanden varje vecka!"
          />
          {isLoginLoading ? (
            <Text>Laddar...</Text>
          ) : (
            <View style={SECTION}>
              <TextField
                onChangeText={(email) => setState((state) => ({ ...state, email }))}
                value={state.email}
                style={TEXT_FIELD}
                placeholder="E-postadress"
                inputStyle={INPUT_STYLE}
                autoComplete="email"
                textContentType="emailAddress"
              />
              <TextField
                onChangeText={(password) => setState((state) => ({ ...state, password }))}
                value={state.password}
                style={TEXT_FIELD}
                placeholder="Lösenord"
                inputStyle={INPUT_STYLE}
                secureTextEntry
                autoComplete="password"
                textContentType="password"
              />
              <Button preset="wide" onPress={onLoginPress} text="Logga in" />
              <Divider style={DIVIDER}></Divider>
              <GoogleAuthButton
                style={BUTTON_MARGIN}
                text="Logga in med Google"
                onAuthenticated={onAuthenticated}
              />
              <FacebookAuthButton
                style={BUTTON_MARGIN}
                text="Logga in med Facebook"
                onAuthenticated={onAuthenticated}
              />
            </View>
          )}
          {loginError && <Text style={ERROR}>{translateError(loginError.message)}</Text>}
          <Pressable onPress={() => navigation.navigate("Register")}>
            <Text style={TEXT} text="inte medlem? Registrera dig >" />
          </Pressable>
        </View>
      </View>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  width: "100%"
}

const FULL_WIDTH: ViewStyle = {
  width: "100%"
}

const ILLUSTRATION: ImageStyle = {
  width: 232.997,
  height: 201.203,

  paddingLeft: Dimensions.get("window").width > 768 ? "8%" : 0,
  marginTop: Dimensions.get("window").width > 768 ? 0 : -24,
  transform: Dimensions.get("window").width > 768 ? [{ scale: 1.6 }] : [{ scale: 1 }]
}

const HEADER: TextStyle = {
  fontSize: Dimensions.get("window").width > 768 ? 24 : 18
}
const SUB_HEADER: TextStyle = {
  textAlign: "center",
  paddingHorizontal: spacing[1],
  paddingBottom: spacing[4]
}

const BUTTON_MARGIN: ViewStyle = {
  marginBottom: spacing[2]
}

const DIVIDER: ViewStyle = {
  marginVertical: spacing[4]
}

const SECTION: ViewStyle = {
  width: "100%",
  justifyContent: "center",
  marginTop: spacing[3],
  maxWidth: 500
}

const TEXT_FIELD: ViewStyle = {
  ...FULL_WIDTH,
  maxWidth: 500
}
const INPUT_STYLE: TextStyle = {
  ...FULL_WIDTH
}

const ERROR: TextStyle = {
  color: "#E03232",
  marginTop: 4
}

const TEXT: TextStyle = {
  padding: spacing[3],
  fontFamily: typography.primary,
  fontSize: 14,
  color: color.palette.gray3
}

const FLEX_ROW: ViewStyle = {
  alignItems: "center",
  flexDirection: Dimensions.get("window").width > 768 ? "row" : "column",
  justifyContent: Dimensions.get("window").width > 768 ? "space-between" : "center",
  width: "100%"
}

const FLEX_COL: ViewStyle = {
  alignItems: "center",
  flexDirection: "column",
  maxWidth: Dimensions.get("window").width > 768 ? "49%" : "auto"
}
