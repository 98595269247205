import React from "react"
import { Dimensions, StyleProp, View, ViewStyle, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "@app/theme"
import { Button, LocationPicker, useModal } from "@app/components"
import { flatten } from "ramda"

interface ModalContentLocationProps {
  style?: StyleProp<ViewStyle>
}

export const ModalContentLocation = observer((props: ModalContentLocationProps) => {
  const { closeModal, setPanGestureEnabled } = useModal()

  const onTouchStart = () => {
    if (Platform.OS === "web") {
      setPanGestureEnabled(false)
    }
  }
  const onTouchEnd = () => {
    if (Platform.OS === "web") {
      setPanGestureEnabled(true)
    }
  }
  return (
    <View style={flatten([CONTAINER, props.style])}>
      <LocationPicker onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} />
      <Button style={DONE_BUTTON} onPress={closeModal} preset="tertiary" text="Klar" />
    </View>
  )
})

const CONTAINER: ViewStyle = {
  flex: 1,
  marginTop: 30,
  minHeight: Dimensions.get("window").height * 0.6
}

const DONE_BUTTON: ViewStyle = {
  marginBottom: spacing[1]
}
