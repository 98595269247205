/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/sort-styles */
import { Platform, StyleSheet } from "react-native"
import { presets } from "@app/components/text/text.presets"
import { color, spacing, typography } from "@app/theme"

// this is converted to a stylesheet internally at run time with StyleSheet.create(
export const styles = StyleSheet.create({
  // The main container
  body: {
    ...presets.default
  },

  // Headings
  heading1: {
    flexDirection: "row",
    // fontSize: 32,
    ...presets.headerLarge,
    marginTop: spacing[5],
    marginBottom: spacing[5]
  },
  heading2: {
    flexDirection: "row",
    ...presets.header,
    marginTop: spacing[8],
    marginBottom: spacing[3],
    fontSize: 24
  },
  heading3: {
    flexDirection: "row",
    ...presets.header,
    marginTop: spacing[8],
    marginBottom: spacing[3],
    fontSize: 18
  },
  heading4: {
    flexDirection: "row",
    ...presets.header,
    marginTop: spacing[3],
    marginBottom: spacing[3],
    fontSize: 16
  },
  heading5: {
    flexDirection: "row",
    ...presets.header,
    marginTop: spacing[3],
    marginBottom: spacing[3],
    fontSize: 13
  },
  heading6: {
    flexDirection: "row",
    ...presets.header,
    marginTop: spacing[3],
    marginBottom: spacing[3],
    fontSize: 11
  },

  // Horizontal Rule
  hr: {
    backgroundColor: color.text,
    height: 1
  },

  // Emphasis
  strong: {
    // fontWeight: "bold"
    ...presets.bold
  },
  em: {
    fontStyle: "italic"
  },
  s: {
    textDecorationLine: "line-through"
  },

  // Blockquotes
  blockquote: {
    backgroundColor: color.palette.offWhite,
    borderColor: color.palette.gray5,
    borderLeftWidth: 4,
    marginLeft: 5,
    paddingHorizontal: 5
  },

  // Lists
  bullet_list: {},
  ordered_list: {},
  list_item: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_icon: {
    marginLeft: 10,
    marginRight: 10
  },
  // @pseudo class, does not have a unique render rule
  bullet_list_content: {
    flex: 1
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_icon: {
    marginLeft: 10,
    marginRight: 10
  },
  // @pseudo class, does not have a unique render rule
  ordered_list_content: {
    flex: 1
  },

  // Code
  code_inline: {
    borderWidth: 1,
    backgroundColor: color.palette.offWhite,
    borderColor: color.palette.gray5,
    padding: 10,
    borderRadius: 4,
    ...Platform.select({
      ios: {
        fontFamily: "Courier"
      },
      android: {
        fontFamily: "monospace"
      }
    })
  },
  code_block: {
    borderWidth: 1,
    backgroundColor: color.palette.offWhite,
    borderColor: color.palette.gray5,
    padding: 10,
    borderRadius: 4,
    ...Platform.select({
      ios: {
        fontFamily: "Courier"
      },
      android: {
        fontFamily: "monospace"
      }
    })
  },
  fence: {
    borderWidth: 1,
    backgroundColor: color.palette.offWhite,
    borderColor: color.palette.gray5,
    padding: 10,
    borderRadius: 4,
    ...Platform.select({
      ios: {
        fontFamily: "Courier"
      },
      android: {
        fontFamily: "monospace"
      }
    })
  },

  // Tables
  table: {
    borderWidth: 1,
    borderColor: color.palette.gray5,
    borderRadius: 3
  },
  thead: {},
  tbody: {},
  th: {
    flex: 1,
    padding: 5
  },
  tr: {
    borderBottomWidth: 1,
    borderColor: color.palette.gray5,
    flexDirection: "row"
  },
  td: {
    flex: 1,
    padding: 5
  },

  // Links
  link: {
    ...presets.bold,
    textDecorationLine: "none",
    color: color.palette.pink3
  },
  blocklink: {
    flex: 1,
    borderColor: color.palette.gray5,
    borderBottomWidth: 1
  },

  // Images
  image: {
    flex: 1
  },

  // Text Output
  text: {},
  textgroup: {},
  paragraph: {
    marginTop: spacing[1],
    marginBottom: spacing[1],
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%"
  },
  hardbreak: {
    width: "100%",
    height: 1
  },
  softbreak: {},

  // Believe these are never used but retained for completeness
  pre: {},
  inline: {},
  span: {}
})

export default styles
