import React, { useRef, FC, useContext, RefObject } from "react"
import SwiperFlatList from "react-native-swiper-flatlist"

export interface IImageZoomRef {
  zoomIn: () => void
  zoomOut: () => void
  resetTransform: () => void
  state: {
    previousScale: number
    scale: number
    positionX: number
    positionY: number
  }
}

export interface IViewerContext {
  images: IImageZoomRef[]
  swiperRef: RefObject<SwiperFlatList>
  children?: React.ReactNode
}

const defaultState = {
  images: [],
  swiperRef: null
}

export const ViewerContext = React.createContext<IViewerContext>(defaultState)

const ViewerProvider: FC<Pick<IViewerContext, "swiperRef" | "children">> = ({
  children,
  swiperRef
}) => {
  const images = useRef([])

  const value = { images: images.current, swiperRef }
  return <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
}

const useViewer = () => {
  const context = useContext(ViewerContext)
  if (context === undefined) {
    throw new Error("useViewer must be used within a ViewerProvider")
  }
  return context
}

export { ViewerProvider, useViewer }
