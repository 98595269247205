import React, { FC, useState } from "react"
import { View, ViewStyle, ImageStyle, Dimensions } from "react-native"
import { ImageZoomProps } from "./image-zoom.props"
import { AutoImage } from "@app/components/auto-image/auto-image"
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch"
import { useViewer } from "../offer-viewer/viewer-context"

export const ImageZoom: FC<ImageZoomProps> = (props) => {
  const [disablePanning, setDisablePanning] = useState(true)
  const viewerContext = useViewer()
  const onPanningStart = () => undefined
  const onPanningStop = () => undefined
  const onPinchingStart = () => undefined
  const onPinchingStop = () => undefined
  const onZoomStart = () => {
    props.onZoomStart()
    setDisablePanning(false)
  }
  const onZoomStop = (ref: ReactZoomPanPinchRef) => {
    setTimeout(() => {
      if (ref && ref.state.scale === 1) {
        props.onZoomReset()
        setDisablePanning(true)
      }
    }, ANIMATION_TIME + 50)
  }

  const setRef = (ref: ReactZoomPanPinchRef) => {
    viewerContext.images[props.index] = {
      ...ref,
      zoomIn: () => {
        onZoomStart()
        ref && ref.zoomIn(0.5, ANIMATION_TIME)
        onZoomStop(ref)
      },
      zoomOut: () => {
        onZoomStart()
        ref && ref.zoomOut(0.5, ANIMATION_TIME)
        onZoomStop(ref)
      },
      resetTransform: () => {
        onZoomStart()
        ref && ref.resetTransform(ANIMATION_TIME)
        onZoomStop(ref)
      }
    }
  }

  return (
    <View style={CONTAINER}>
      <TransformWrapper
        ref={setRef}
        onPanningStart={onPanningStart}
        onPanningStop={onPanningStop}
        onPinchingStart={onPinchingStart}
        onPinchingStop={onPinchingStop}
        onZoomStart={onZoomStart}
        onZoomStop={onZoomStop}
        panning={{ disabled: disablePanning }}
        pinch={{ disabled: false, step: 1 }}
        wheel={{ wheelDisabled: true }}
        doubleClick={{ disabled: true }}
        initialScale={1}
        minScale={1}
        maxScale={2.5}
        alignmentAnimation={{ animationTime: ANIMATION_TIME }}
        limitToBounds
      >
        <TransformComponent wrapperStyle={TRANSFORM_WRAPPER}>
          {Array.isArray(props.source) ? (
            props.source.map((s, idx) => (
              <AutoImage
                style={IMAGE}
                fadeIn
                key={idx}
                maxWidth={Array.isArray(props.source) ? ww / props.source.length : ww}
                maxHeight={ah}
                source={s}
              />
            ))
          ) : (
            <AutoImage style={IMAGE} fadeIn maxWidth={ww} maxHeight={ah} source={props.source} />
          )}
        </TransformComponent>
      </TransformWrapper>
    </View>
  )
}
const ANIMATION_TIME = 200
const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "flex-start",
  alignItems: "center",
  overflow: "visible"
}

const TRANSFORM_WRAPPER: React.CSSProperties = {
  overflow: "visible"
}

const IMAGE: ImageStyle = {
  margin: "auto"
}

// const IMAGE_RATIO = 333 / 200
const ww = Dimensions.get("window").width
const wh = Dimensions.get("window").height
// const ah = ww >= 368 ? wh - 125 : wh - 71 // available height careful changing this hack
// const IMAGE_SCALE = 1

// const IMAGE_WIDTH = (ww > ah ? ah / IMAGE_RATIO : ww) * IMAGE_SCALE
// const IMAGE_HEIGHT = (ww > ah ? ah : ww * IMAGE_RATIO) * IMAGE_SCALE

const ah = // available height careful changing this hack
      ww >= 1400
    ? wh - 82 // larger than 1300
    : ww >= 768
    ? wh - 72 // larger than 768
    : ww >= 368
    ? wh - 142 // larger than 368
    : wh - 71 // smaller than 368
