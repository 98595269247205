import { Text } from "@app/components"
import { textPresets, viewPresets } from "@app/components/button/button.presets"
import { keysToCamelCase } from "@app/utils/misc"
import { Link } from "@react-navigation/native"
import React, { useEffect, useState } from "react"
import { View } from "react-native"

export const Sitemap = () => {
  const [menu, setMenu] = useState<Post[]>([])
  const fetchMenu = () => {
    fetch("https://veckanse.velumi.site/wp-json/tailpress/v1/menus/primary")
      .then((response) => response.json())
      .then((json) => {
        json as Record<string, any>[]
        const posts = json.map(keysToCamelCase) as Post[]
        setMenu(posts)
      })
  }
  const getParams = (url: string) => {
    const regex = /^https:\/\/veckanse\.velumi\.site\/(.*?)(\/)?$/
    const match = url.match(regex)
    const [dealerId, slug] = (match ? match[1] : "/").split("/").filter(Boolean)
    return { dealerId, slug }
  }
  useEffect(() => {
    fetchMenu()
  }, [])
  return (
    <View style={viewPresets.link}>
      <Text preset="headerLarge" text="Butikssidor" />
      {menu?.map((item) => {
        const { dealerId, slug } = getParams(item.url)
        if (!dealerId) return null
        return (
          <Link
            style={[textPresets.link, textPresets.secondary]}
            key={item.id}
            to={{
              screen: "Tabs",
              params: {
                screen: "Home",
                params: {
                  screen: slug ? "ExternalB" : "ExternalA",
                  params: { dealerId, slug }
                }
              }
            }}
          >
            {item.title}
          </Link>
        )
      })}
    </View>
  )
}

export interface Post {
  id?: number
  postAuthor?: string
  postDate?: Date
  postDateGmt?: Date
  postContent?: string
  postTitle?: string
  postExcerpt?: string
  postStatus?: string
  commentStatus?: string
  pingStatus?: string
  postPassword?: string
  postName?: string
  toPing?: string
  pinged?: string
  postModified?: Date
  postModifiedGmt?: Date
  postContentFiltered?: string
  postParent?: number
  guid?: string
  menuOrder?: number
  postType?: string
  postMIMEType?: string
  commentCount?: string
  filter?: string
  dbID?: number
  menuItemParent?: string
  objectID?: string
  object?: string
  type?: string
  typeLabel?: string
  title?: string
  url?: string
  target?: string
  attrTitle?: string
  description?: string
  classes?: string[]
  xfn?: string
}
export default Sitemap
