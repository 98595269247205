import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const UserModel = types
  .model("User")
  .props({
    id: types.identifier,
    username: types.string,
    email: types.maybe(types.string)
    // confirmed: types.maybe(types.boolean),
    // blocked: types.maybe(types.boolean),
    // role: types.maybe(
    //   types.model({
    //     id: types.identifier,
    //     name: types.string,
    //     description: types.maybe(types.string),
    //     type: types.maybe(types.string)
    //   })
    // )
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface User extends Instance<typeof UserModel> {}
export interface UserSnapshot extends SnapshotOut<typeof UserModel> {}
export const createUserDefaultModel = () => types.maybe(UserModel)
