import { UserSnapshot } from "@app/models"
import { Api } from "@app/services/api"
import { DEFAULT_API_CONFIG } from "@app/services/api/api-config"
import { keysToSnakeCase } from "@app/utils/misc"

export interface GetOauthConnect {
  provider: "google" | "facebook"
  query: {
    accessToken?: string
    code?: string
  }
}

export class UserApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getOauthConnect(args: GetOauthConnect) {
    return await this.api.get<{ user: UserSnapshot; jwt: string }>(
      `${DEFAULT_API_CONFIG.url}/api/auth/${args.provider}/callback`,
      keysToSnakeCase(args.query)
    )
  }
}
