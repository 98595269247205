import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { startBatch } from "mobx/dist/internal"

/**
 * Model description here for TypeScript hints.
 */
export const OfferModel = types
  .model("Offer")
  .props({
    id: types.identifierNumber,
    checksum: types.string,
    thumbnail: types.string,
    start_at: types.maybe(types.Date),
    end_at: types.maybe(types.Date),
    created_at: types.Date,
    updated_at: types.maybe(types.Date),
    is_active: types.boolean,
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

type OfferType = Instance<typeof OfferModel>
export interface Offer extends OfferType {}
type OfferSnapshotType = SnapshotOut<typeof OfferModel>
export interface OfferSnapshot extends OfferSnapshotType {}
export const createOfferDefaultModel = () => types.optional(OfferModel, {})
