/* eslint-disable camelcase */
import React from "react"
import { Helmet } from "react-helmet"

interface Image {
  width: number
  height: number
  url: string
  type: string
}

interface GraphElement {
  "@type": string
  "@id": string
  url?: string
  name?: string
  isPartOf?: { "@id": string }
  datePublished?: string
  dateModified?: string
  description?: string
  breadcrumb?: { "@id": string }
  inLanguage?: string
  potentialAction?: Array<{ "@type": string; target: Array<string> }>
  itemListElement?: Array<{ "@type": string; position: number; name: string; item?: string }>
}

interface Schema {
  "@context": string
  "@graph": Array<GraphElement>
}

interface YoastHeadJson {
  title: string
  description: string
  robots: {
    index: string
    follow: string
    "max-snippet": string
    "max-image-preview": string
    "max-video-preview": string
  }
  canonical: string
  og_locale: string
  og_type: string
  og_title: string
  og_description: string
  og_url: string
  og_site_name: string
  article_modified_time: string
  og_image: Array<Image>
  twitter_card: string
  twitter_misc: {
    [key: string]: string
  }
  schema: Schema
}

type YoastHeadProps = {
  yoastHeadJson: Partial<YoastHeadJson>
}

export const YoastHead: React.FC<YoastHeadProps> = ({ yoastHeadJson }) => {
  const {
    title,
    description,
    robots,
    canonical,
    og_locale,
    og_type,
    og_title,
    og_description,
    og_url,
    og_site_name,
    article_modified_time,
    og_image,
    twitter_card,
    twitter_misc,
    schema
  } = yoastHeadJson

  const robotsContent = Object.values(robots).join(", ")

  // const twitterMisc = Object.entries(twitter_misc).map(([key, value], index) => (
  //   <>
  //     <meta name={`twitter:label${index + 1}`} content={key} />
  //     <meta name={`twitter:data${index + 1}`} content={value} />
  //   </>
  // ))

  const {
    url: imageUrl,
    width: imageWidth,
    height: imageHeight,
    type: imageType
  } = (og_image && og_image[0]) || {}

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robotsContent} />
      <link rel="canonical" href={canonical} />
      <meta property="og:locale" content={og_locale} />
      <meta property="og:type" content={og_type} />
      <meta property="og:title" content={og_title} />
      <meta property="og:description" content={og_description} />
      <meta property="og:url" content={og_url} />
      <meta property="og:site_name" content={og_site_name} />
      <meta property="article:modified_time" content={article_modified_time} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageWidth?.toString()} />
      <meta property="og:image:height" content={imageHeight?.toString()} />
      <meta property="og:image:type" content={imageType} />
      <meta name="twitter:card" content={twitter_card} />
      {/* {twitterMisc} */}
      <script type="application/ld+json" className="yoast-schema-graph">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  )
}

export default YoastHead
