import { Sentry } from "./sentry"
import { Operation } from "@apollo/client"
import { GraphQLError, OperationDefinitionNode, OperationTypeNode } from "graphql"
export interface BreadcrumbData {
  variables?: Record<string, unknown>
  operationType?: OperationTypeNode
  operationName?: string
  errorCode?: string
  errorName?: string
  errorMessages?: string[]
}

export interface ApiGraphQLError extends GraphQLError {
  message: string // A human reable error message
  extensions: {
    error?: {
      name: string // Strapi error name ('ApplicationError' or 'ValidationError'),
      message: string // A human reable error message (same one as above);
      details: Record<string, unknown> // Error info specific to the error type
    }
    code?: string // GraphQL error code (ex: BAD_USER_INPUT)
  }
}

export const captureGraphqlErrors = (
  errors: ReadonlyArray<ApiGraphQLError>,
  operation: Operation
) => {
  const definition = operation.query.definitions.find(
    (q) => q.kind === "OperationDefinition"
  ) as OperationDefinitionNode

  Sentry.withScope((scope) => {
    const data: BreadcrumbData = {}

    const operationName = definition.name?.value
    const operationType = definition.operation

    scope.setTag("operationType", operationType)

    data.operationType = operationType
    if (operationName) {
      data.operationName = operationName
    }
    // Log query and variables as extras (make sure to strip out sensitive data!) data scrubbing is enabled also in sentry
    if (!["login", "register"].includes(operationName)) {
      data.variables = operation.variables
    }

    errors.forEach((error) => {
      const errorCode = error.extensions.code
      const errorName = error.extensions.error?.name || ""
      scope.setTag("errorCode", errorCode)
      scope.setTransactionName(`${errorCode}: ${error.message}`)
      const messages = [
        ...JSON.stringify(error.extensions.error.details).matchAll(
          /"message":"(?<message>[^"]+)"/gm
        )
      ].map((match) => match.groups.message)

      data.errorMessages = messages
      data.errorCode = errorCode
      data.errorName = errorName
      Sentry.addBreadcrumb({
        type: "http",
        category: "graphql",
        data
      })
      const msg = `[GraphQLError]: ${operationType} ${operationName}`
      Sentry.captureMessage(msg)
    })
  })
}
