/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React, { FC, useEffect } from "react"
import { Platform, useColorScheme, Dimensions } from "react-native"
import {
  NavigationContainer,
  CompositeScreenProps,
  DefaultTheme,
  DarkTheme,
  EventArg
} from "@react-navigation/native"
import { createStackNavigator, StackScreenProps, TransitionPresets } from "@react-navigation/stack"
import {
  createBottomTabNavigator,
  BottomTabBarProps,
  BottomTabScreenProps
} from "@react-navigation/bottom-tabs"
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
  MaterialTopTabScreenProps
} from "@react-navigation/material-top-tabs"

import { navigationRef } from "./navigation-utilities"
import { Menu, Modal, useModal } from "@app/components"
import {
  OffersScreen,
  CatalogScreen,
  LoginScreen,
  RegisterScreen,
  DealerScreen,
  AboutScreen,
  ContactScreen,
  AdvertiseScreen,
  RegisterNewsletterScreen,
  PolicyScreen,
  FaqScreen,
  SitemapScreen,
  ExternalScreen,
  PromotionScreen,
  TermsScreen
} from "@app/screens"
import { useCheckUserLoggedIn, useCheckUserConsent, useCheckUserWelcome } from "@app/utils/hooks"
import {
  HomeNavigatorParamList,
  RootNavigatorParamList,
  TabNavigatorParamList,
  linking
} from "@app/navigators/navigator-params"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */

export type TabsScreenProps = StackScreenProps<RootNavigatorParamList, "Tabs">
export type HomeScreenProps = CompositeScreenProps<
  | BottomTabScreenProps<TabNavigatorParamList, "Home">
  | MaterialTopTabScreenProps<TabNavigatorParamList, "Home">,
  TabsScreenProps
>

interface TabBarProps extends Pick<BottomTabBarProps, "state" | "navigation"> {
  variant: "top" | "bottom"
}

const TabBar = (props: TabBarProps) => {
  const { routes } = props.state
  const { navigate } = props.navigation
  const { toggleModal, setModalVariant } = useModal()

  const emit = (routeKey: string) => {
    props.navigation.emit({
      type: "tabPress",
      target: routeKey,
      canPreventDefault: true
    })
  }
  const routesMap = routes.reduce<Record<string, (typeof routes)[number]>>( // TODO use emit on the other navigations https://reactnavigation.org/docs/bottom-tab-navigator/
    (acc, cur) => ({ ...acc, [cur.name]: cur }),
    {}
  )
  const onMenuPress = () => {
    toggleModal()
    setModalVariant("navigation")
  }
  const onCtaPress = () => {
    toggleModal()
    setModalVariant("register")
  }

  const onOffersPress = () => {
    navigate("Offers")
    emit(routesMap.Home.key)
  }

  const onFaqPress = () => {
    navigate("Faq")
    emit(routesMap.Home.key)
  }

  const onUserPress = () => {
    navigate("Login")
  }
  return (
    <Menu
      variant={props.variant}
      onMenuPress={onMenuPress}
      onOffersPress={onOffersPress}
      onFaqPress={onFaqPress}
      // onUserPress={() => navigate("Register")}
      onUserPress={onUserPress}
      onCtaPress={onCtaPress}
    />
  )
}

const TopTabBar = (props: MaterialTopTabBarProps) => <TabBar variant="top" {...props} />
const BottomTabBar = (props: BottomTabBarProps) => <TabBar variant="bottom" {...props} />

const HomeStack = createStackNavigator<HomeNavigatorParamList>()
const Home: FC<HomeScreenProps> = (props) => {
  const tabPressListener = (callback: (e: EventArg<"tabPress", true, undefined>) => void) => {
    return props.navigation.addListener("tabPress", (e) => callback(e))
  }
  useEffect(() => {
    const unsubscribe = props.navigation.addListener("tabPress", (e) => {
      e.preventDefault()
    })
    return unsubscribe
  }, [props.navigation])

  return (
    <HomeStack.Navigator
      initialRouteName="Offers"
      screenOptions={{
        headerShown: false
      }}
    >
      <HomeStack.Screen name="Offers" options={{ title: "VeckansE - Erbjudanden" }}>
        {(props) => <OffersScreen tabPressListener={tabPressListener} {...props} />}
      </HomeStack.Screen>
      <HomeStack.Screen name="Dealer" options={{ title: "VeckansE - Butik" }}>
        {(props) => <DealerScreen {...props} />}
      </HomeStack.Screen>

      <HomeStack.Screen
        name="ExternalA"
        component={ExternalScreen}
        options={{ title: "VeckansE - External" }}
      />
      <HomeStack.Screen
        name="ExternalB"
        component={ExternalScreen}
        options={{ title: "VeckansE - External" }}
      />
    </HomeStack.Navigator>
  )
}

const BottomTab = createBottomTabNavigator<TabNavigatorParamList>()
const BottomTabs: FC<TabsScreenProps> = () => {
  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false }}
      tabBar={BottomTabBar}
    >
      <BottomTab.Screen name="Home" component={Home} />
    </BottomTab.Navigator>
  )
}
const TopTab = createMaterialTopTabNavigator<TabNavigatorParamList>()
const TopTabs: FC<TabsScreenProps> = () => {
  return (
    <TopTab.Navigator initialRouteName="Home" tabBar={TopTabBar}>
      <TopTab.Screen name="Home" component={Home} />
    </TopTab.Navigator>
  )
}

const window = Dimensions.get("window")
const RootStack = createStackNavigator<RootNavigatorParamList>()
const App = () => {
  useCheckUserLoggedIn()
  useCheckUserConsent()
  useCheckUserWelcome()

  const isWeb = Platform.OS === "web"
  return (
    <RootStack.Navigator
      initialRouteName="Tabs"
      screenOptions={{
        headerShown: false
      }}
    >
      <RootStack.Group>
        <RootStack.Screen name="Tabs" component={window.width >= 768 ? TopTabs : BottomTabs} />
        <RootStack.Screen
          name="About"
          component={AboutScreen}
          options={{ title: "VeckansE - Om oss" }}
        />
        <RootStack.Screen
          name="Contact"
          component={ContactScreen}
          options={{ title: "VeckansE - Kontakta oss" }}
        />
        <RootStack.Screen
          name="Advertise"
          component={AdvertiseScreen}
          options={{ title: "VeckansE - Annonsera" }}
        />
        <RootStack.Screen
          name="Policy"
          component={PolicyScreen}
          options={{ title: "VeckansE - Sekretesspolicy" }}
        />
        <RootStack.Screen
          name="Terms"
          component={TermsScreen}
          options={{ title: "VeckansE - Användarvillkor" }}
        />
        <RootStack.Screen
          name="Faq"
          component={FaqScreen}
          options={{ title: "VeckansE - Frågor och svar" }}
        />
        <RootStack.Screen
          name="Sitemap"
          component={SitemapScreen}
          options={{ title: "VeckansE - Sitemap" }}
        />
        <RootStack.Screen
          name="Promotion"
          component={PromotionScreen}
          options={{ title: "VeckansE - Kampanjer" }}
        />
        <RootStack.Screen
          name="RegisterNewsletter"
          component={RegisterNewsletterScreen}
          options={{ title: "VeckansE - Registrera-nyhetsbrev" }}
        />
      </RootStack.Group>
      <RootStack.Group
        screenOptions={{
          cardStyle: { backgroundColor: "transparent" },
          ...(isWeb && { presentation: "transparentModal" }),
          ...(isWeb && TransitionPresets.ModalFadeTransition),
          ...(!isWeb && TransitionPresets.ModalTransition)
        }}
      >
        <RootStack.Screen
          name="Catalog"
          component={CatalogScreen}
          options={{ title: "VeckansE - Katalog" }}
        />
        <RootStack.Screen
          name="Login"
          component={LoginScreen}
          options={{ title: "VeckansE - Login" }}
        />
        <RootStack.Screen
          name="Register"
          component={RegisterScreen}
          options={{ title: "VeckansE - Signup" }}
        />
      </RootStack.Group>
    </RootStack.Navigator>
  )
}

export interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = (props: NavigationProps) => {
  const colorScheme = useColorScheme()
  return (
    <NavigationContainer
      {...props}
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      linking={linking}
    >
      <Modal.Provider>
        <App />
      </Modal.Provider>
    </NavigationContainer>
  )
}

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["Offers"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
