import React, { useLayoutEffect, useState } from "react"
import {
  Image as RNImage,
  ImageProps as DefaultImageProps,
  ImageURISource,
  Platform
} from "react-native"
import FadeIn from "react-native-fade-in-image"

interface ImageProps extends DefaultImageProps {
  source: ImageURISource
}

/**
 * An Image wrapper component that autosizes itself to the size of the actual image.
 * You can always override by passing a width and height in the style.
 * If passing only one of width/height this image component will use the actual
 * size of the other dimension.
 *
 * This component isn't required, but is provided as a convenience so that
 * we don't have to remember to explicitly set image sizes on every image instance.
 *
 * To use as a stand-in replacement import { AutoImage as Image } and remove the
 * Image import from react-native. Now all images in that file are handled by this
 * component and are web-ready if not explicitly sized in the style property.
 */
interface AutoImageProps extends ImageProps {
  fadeIn?: boolean
  maxWidth?: number
  maxHeight?: number
}

export function AutoImage(props: AutoImageProps) {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  useLayoutEffect(() => {
    let abort = false

    const checkAutoWidthOrHeight = (width: number, height: number) => {
      let w: number, h: number
      const aspectRatio = width / height

      const maxWidth = Math.min(width, props.maxWidth)
      const maxHeight = Math.min(height, props.maxHeight)

      if ((props.maxWidth ? 1 : 0) ^ (props.maxHeight ? 1 : 0)) {
        if (props.maxWidth) {
          w = maxWidth
          h = maxWidth / aspectRatio
        } else {
          w = maxHeight * aspectRatio
          h = maxHeight
        }
      } else if (props.maxWidth && props.maxHeight) {
        if (props.maxWidth / props.maxHeight > aspectRatio) {
          w = maxHeight * aspectRatio
          h = maxHeight
        } else {
          w = maxWidth
          h = maxWidth / aspectRatio
        }
      } else {
        w = width
        h = height
      }
      return { width: w, height: h }
    }
    if (props.source?.uri) {
      RNImage.getSize(props.source.uri as any, (width, height) => {
        if (!abort) setImageSize(checkAutoWidthOrHeight(width, height))
      })
    } else if (Platform.OS === "web") {
      // web requires a different method to get it's size
      RNImage.getSize(props.source as any, (width, height) => {
        if (!abort) setImageSize(checkAutoWidthOrHeight(width, height))
      })
    } else {
      const { width, height } = RNImage.resolveAssetSource(props.source)
      if (!abort) setImageSize(checkAutoWidthOrHeight(width, height))
    }
    return () => {
      abort = true
    }
  }, [])

  return props.fadeIn ? (
    <FadeIn>
      <RNImage {...props} style={[props.style, imageSize]} />
    </FadeIn>
  ) : (
    <RNImage {...props} style={[props.style, imageSize]} />
  )
}
