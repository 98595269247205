/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "@app/i18n"
import "@app/utils/ignore-warnings"
import React, { useState, useEffect } from "react"
import { SafeAreaProvider, initialWindowMetrics } from "react-native-safe-area-context"
import AppLoading from "expo-app-loading"
import { ApolloProvider } from "@apollo/client"

import {
  useBackButtonHandler,
  AppNavigator,
  canExit,
  useNavigationPersistence
} from "@app/navigators"
import { RootStore, RootStoreProvider, setupRootStore } from "@app/models"
import { ErrorBoundary } from "@app/screens/error/error-boundary"
import { initFonts } from "@app/theme/fonts" // expo
import * as storage from "@app/utils/storage"
import { apolloClient } from "@app/services/apollo/client"
import { WithSentry } from "@app/services/sentry/sentry"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import { ViewStyle } from "react-native"
// import { ToggleStorybook } from "../storybook/toggle-storybook"

// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"

/**
 * This is the root component of our app.
 */
function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  useBackButtonHandler(canExit)
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    let abort = false
    ;(async () => {
      await initFonts() // expo
      const store = await setupRootStore()
      if (!abort) setRootStore(store)
    })()
    return () => {
      abort = true
    }
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rootStore || !isNavigationStateRestored) return <AppLoading />

  // otherwise, we're ready to render the app
  return (
    //  <ToggleStorybook>
    <GestureHandlerRootView style={FLEX_1}>
      <RootStoreProvider value={rootStore}>
        <ApolloProvider client={apolloClient}>
          <SafeAreaProvider initialMetrics={initialWindowMetrics}>
            <ErrorBoundary catchErrors={"always"}>
              <AppNavigator
                initialState={initialNavigationState}
                onStateChange={onNavigationStateChange}
              />
            </ErrorBoundary>
          </SafeAreaProvider>
        </ApolloProvider>
      </RootStoreProvider>
    </GestureHandlerRootView>
    //  </ToggleStorybook>
  )
}

const FLEX_1: ViewStyle = { flex: 1 }

export default WithSentry(App)
