import { InMemoryCache, Reference, makeVar } from "@apollo/client"

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Catalog: {
      keyFields: ["checksum"]
    },
    Query: {
      fields: {
        // isLoggedIn: {
        //   read() {
        //     return isLoggedInVar();
        //   }
        // },
        // cartItems: {
        //   read() {
        //     return cartItemsVar();
        //   }
        // },
        // launches: {
        //   keyArgs: false,
        //   merge(existing, incoming) {
        //     let launches: Reference[] = [];
        //     if (existing && existing.launches) {
        //       launches = launches.concat(existing.launches);
        //     }
        //     if (incoming && incoming.launches) {
        //       launches = launches.concat(incoming.launches);
        //     }
        //     return {
        //       ...incoming,
        //       launches,
        //     };
        //   }
        // }
      }
    }
  }
})
