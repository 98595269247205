import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  Dimensions,
  ListRenderItem,
  Platform
} from "react-native"
import { SwiperFlatList, PaginationProps } from "react-native-swiper-flatlist"
import { Feather } from "@expo/vector-icons"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { Button, Text, ImageZoom, ImageZoomProps } from "@app/components"
import { color, typography, spacing } from "@app/theme"
import { textPresets } from "@app/components/button/button.presets"
import { useViewer, ViewerContext, ViewerProvider } from "./viewer-context"
import { throttle, addAlphaToHex } from "@app/utils/misc"
import { useAddEventListener } from "@app/utils/hooks"
import { useSafeAreaInsets } from "react-native-safe-area-context"

const TWO_PAGE_BREAKPOINT = 800
const VIEW_TIME_THRESHOLD_MS = 10000

export interface OfferViewerProps {
  style?: StyleProp<ViewStyle>
  images: ImageZoomProps["source"][]
  onPageView: (idxs: number[]) => void
  onZoomStart: (idxs: number[]) => void
  onZoomReset: (idxs: number[]) => void
}

export const OfferViewer = observer(function OfferViewer(props: OfferViewerProps) {
  const styles = flatten([CONTAINER, props.style])
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState(0)

  const swiperRef = useRef<SwiperFlatList>()
  const onZoomStart = () => {
    // props.onZoomStart(swiperIndexToPagesIndex(currentSwiperIndex))
  }

  const onZoomReset = () => {
    // props.onZoomReset(swiperIndexToPagesIndex(currentSwiperIndex))
  }
  const viewTimer = useRef<ReturnType<typeof setTimeout>>(null)

  const renderItem: ListRenderItem<ImageZoomProps["source"]> = ({ item, index }) => {
    return (
      <View style={SLIDE}>
        <ImageZoom
          onZoomStart={onZoomStart}
          onZoomReset={onZoomReset}
          source={item}
          index={index}
        />
      </View>
    )
  }
  const data = useMemo<ImageZoomProps["source"][]>(() => {
    if (!props.images) return []
    const data = []
    for (let idx = 0; idx < props.images.length; idx += 2) {
      const curImg = props.images[idx]
      const nextImg = props.images[idx + 1]
      if (ww >= TWO_PAGE_BREAKPOINT) {
        data.push([curImg, nextImg].filter(Boolean))
      } else {
        data.push([curImg])
        if (nextImg) data.push([nextImg])
      }
    }
    return data
  }, [])

  const swiperIndexToPagesIndex: (index: number) => number[] = (index) => {
    if (!(data.length >= 0)) return []

    if (ww >= TWO_PAGE_BREAKPOINT) {
      const rest = props.images.length % 2
      if (rest === 1 && index === data.length) {
        return [2 * index - 1]
      } else {
        return [2 * index, 2 * index + 1]
      }
    } else {
      return [index]
    }
  }

  const callIfViewed = async (cb: () => void) => {
    clearTimeout(viewTimer.current)
    viewTimer.current = setTimeout(() => {
      cb()
    }, VIEW_TIME_THRESHOLD_MS)
  }

  const ref = useAddEventListener<"wheel", View>(
    "wheel",
    throttle((e) => {
      // const isTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0
      const isTouchPad = Number.isInteger(e.deltaY)

      if (swiperRef.current && !isTouchPad) {
        const currentIndex = swiperRef.current.getCurrentIndex()
        const index =
          e.deltaY > 0
            ? data.length - 1 === currentIndex
              ? data.length - 1
              : currentIndex + 1
            : currentIndex > 0
            ? currentIndex - 1
            : 0

        swiperRef.current.scrollToIndex({ index })
      }
    }, 500)
  )

  useEffect(() => {
    callIfViewed(() => {
      props.onPageView(swiperIndexToPagesIndex(0))
    })
  }, [])

  const onChangeIndex = ({ index }) => {
    setCurrentSwiperIndex(index)
    callIfViewed(() => {
      props.onPageView(swiperIndexToPagesIndex(index))
    })
  }

  useEffect(() => {
    return () => {
      clearTimeout(viewTimer.current)
    }
  }, [])

  return (
    <ViewerProvider swiperRef={swiperRef}>
      <View style={styles} ref={ref}>
        <ViewerContext.Consumer>
          {({ images }) => (
            <SwiperFlatList
              ref={swiperRef}
              scrollEnabled={true}
              horizontal
              style={SWIPER}
              data={data}
              renderItem={renderItem}
              onChangeIndex={onChangeIndex}
              showPagination
              PaginationComponent={Pagination}
              onScroll={throttle(() => {
                images.forEach((image) => image.state?.scale !== 1 && image.resetTransform())
              }, 500)}
            />
          )}
        </ViewerContext.Consumer>
      </View>
    </ViewerProvider>
  )
})

const Pagination: FC<PaginationProps> = (props) => {
  const insets = useSafeAreaInsets()
  const viewerContext = useViewer()
  const styles: ViewStyle = { ...PAGINATION_CONTAINER, paddingBottom: insets.bottom * 0.6 }
  const { paginationIndex: idx, size } = props
  const prev = () => {
    const index = idx - 1 >= 0 ? idx - 1 : idx
    viewerContext.images[idx]?.resetTransform()
    props.scrollToIndex({ index })
  }
  const next = () => {
    const index = idx + 1 <= size - 1 ? idx + 1 : idx
    viewerContext.images[idx]?.resetTransform()
    props.scrollToIndex({ index })
  }
  const start = () => {
    viewerContext.images[idx]?.resetTransform()
    props.scrollToIndex({ index: 0 })
  }
  const end = () => {
    const index = props.size - 1
    viewerContext.images[idx]?.resetTransform()
    props.scrollToIndex({ index })
  }

  const zoomIn = () => viewerContext.images[idx]?.zoomIn()
  const zoomOut = () => viewerContext.images[idx]?.zoomOut()

  const isWeb = Platform.OS === "web"
  return (
    <View style={styles}>
      <View style={PAGINATION_BUTTONS}>
        {isWeb && (
          <Button preset="icon" onPress={zoomOut} style={CIRCLE_BUTTON}>
            <Feather style={textPresets.icon} preset="icon" name="zoom-out" />
          </Button>
        )}
        <Button preset="icon" onPress={start} style={CIRCLE_BUTTON}>
          <Feather style={textPresets.icon} preset="icon" name="chevrons-left" />
        </Button>
        <Button preset="iconPink" onPress={prev} style={CIRCLE_BUTTON}>
          <Feather style={textPresets.iconPink} name="chevron-left" />
        </Button>
        <View style={PAGINATION_INDICATOR}>
          <Text style={TEXT} text={`${idx + 1}/${size}`} />
        </View>
        <Button preset="iconPink" onPress={next} style={CIRCLE_BUTTON}>
          <Feather style={textPresets.iconPink} name="chevron-right" />
        </Button>
        <Button preset="icon" onPress={end} style={CIRCLE_BUTTON}>
          <Feather style={textPresets.icon} preset="icon" name="chevrons-right" />
        </Button>
        {isWeb && (
          <Button preset="icon" onPress={zoomIn} style={CIRCLE_BUTTON}>
            <Feather style={textPresets.icon} preset="icon" name="zoom-in" />
          </Button>
        )}
      </View>
    </View>
  )
}

const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "flex-start"
}
const SWIPER: ViewStyle = {
  flexBasis: 200,
  marginVertical: -100, // used for zoomed image
  ...Platform.select({
    web: {
      paddingTop: Dimensions.get("window").width > 768 ? 36 : 100 // used for zoomed image
    }
  })
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 14,
  color: color.palette.white
}

const ww = Dimensions.get("window").width
const SLIDE: ViewStyle = {
  width: ww
}

const CENTER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center"
}
const CIRCLE_WIDTH = 40
const CIRCLE: ViewStyle = {
  width: CIRCLE_WIDTH,
  height: CIRCLE_WIDTH,
  borderRadius: CIRCLE_WIDTH / 2
}
const CIRCLE_BUTTON: ViewStyle = {
  ...CIRCLE,
  ...CENTER,
  margin: spacing[2]
}

const PAGINATION_CONTAINER: ViewStyle = {
  ...CENTER,
  padding: spacing[1],
  paddingTop: spacing[1],
  ...(Dimensions.get("window").width <= 368 && {
    height: 40,
    padding: spacing[0],
    marginVertical: -3,
    transform: [{ scale: 0.7 }]
  })
}

// const PAGINATION_CONTAINER: ViewStyle = {
//   ...CENTER,
//   padding: spacing[1],
//   paddingTop: spacing[1],
//   ...(Dimensions.get("window").width <= 1400 && {
//     marginVertical: -5,
//     transform: [{ scale: 0.85 }],
//   }),
//   ...(Dimensions.get("window").width <= 768 && {
//     marginVertical: 0,
//     transform: [{ scale: 1 }],
//   }),
//   ...(Dimensions.get("window").width <= 368 && {
//     height: 40,
//     padding: spacing[0],
//     marginVertical: -3,
//     transform: [{ scale: 0.7 }]
//   })
// }

const PAGINATION_INDICATOR: ViewStyle = {
  ...CENTER,
  minWidth: 42,
  display: Dimensions.get("window").width > 320 ? "flex" : "none",
  padding: spacing[1]
}
const PAGINATION_BUTTONS: ViewStyle = {
  ...CENTER,
  backgroundColor: addAlphaToHex(color.palette.black, 0.5),
  borderRadius: 50,
  flexDirection: "row"
}
