import React, { useEffect, useRef, useState } from "react"
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  ActivityIndicator,
  LayoutRectangle
} from "react-native"
import { MapPressEvent } from "react-native-maps"
import { observer } from "mobx-react-lite"
import * as Location from "expo-location"
import { flatten } from "ramda"

import {
  MapView,
  MapViewMarker,
  MapViewCircle,
  Slider,
  Text,
  IconButton,
  GooglePlacesAutocomplete
} from "@app/components"

import { GooglePlacesAutocompleteProps } from "@app/components/google-places-autocomplete/google-places-autocomplete.props"
import { color, spacing, typography } from "@app/theme"
import { addAlphaToHex, debounce } from "@app/utils/misc"
import { LocationSnapshot, useStores } from "@app/models"
import { LocationService } from "@app/services/location/location"

const SLIDER_MIN = 100
const SLIDER_MAX = 700000

export interface LocationPickerProps {
  style?: StyleProp<ViewStyle>
  onTouchStart?: () => void
  onTouchEnd?: () => void
}

// TODO CHANGE GOOGLE API KEY !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const metersToLatLngDelta = (meters: number) => {
  const EARTH_RADIUS = 6.3781 * 10 ** 6
  const EARTH_CIRCUMFERENCE = 2 * Math.PI * EARTH_RADIUS

  const latitudeDelta = (meters / EARTH_CIRCUMFERENCE) * 360
  const longitudeDelta = (meters / EARTH_CIRCUMFERENCE) * 360
  return { latitudeDelta, longitudeDelta }
}

export const LocationPicker = observer((props: LocationPickerProps) => {
  const {
    location,
    setLocation,
    updateSelectedLocation,
    radius: storeRadius,
    setRadius: setStoreRadius,
    findLocationOnMount
  } = useStores()
  const [radius, setRadius] = useState(storeRadius)
  const [isLoadingLocation, setIsLoadingLocation] = useState(false)
  const spinAnim = useRef(new Animated.Value(0)).current
  const [availableLayout, setAvailableLayout] = useState<LayoutRectangle>(null)
  const { latitude, longitude } = location
  const ref = useRef<MapView>(null)

  const isMounted = () => ref.current !== null

  const sliderToRadius = (value: number) => {
    return Math.round(SLIDER_MIN * Math.pow(SLIDER_MAX / SLIDER_MIN, value))
  }

  const radiusToSlider = (radiusValue: number) => {
    return Math.log(radiusValue / SLIDER_MIN) / Math.log(SLIDER_MAX / SLIDER_MIN)
  }

  const onSlidingCompleate = (value: number) => {
    if (Platform.OS === "web") {
      const zoom = Math.round(
        Math.log(360 / metersToLatLngDelta(3 * sliderToRadius(value)).latitudeDelta) / Math.LN2
      )
      ref.current.animateCamera({ zoom, center: { latitude, longitude } })
    } else {
      ref.current.animateToRegion({
        latitude,
        longitude,
        ...metersToLatLngDelta(3 * sliderToRadius(value))
      })
    }
    setStoreRadius(sliderToRadius(value))
  }

  const onValueChange = (value: number) => setRadius(sliderToRadius(value))

  const onPress = async (e: MapPressEvent) => {
    const { coordinate } = e.nativeEvent
    const addresses = await new LocationService().reverseGeocode(coordinate)
    if (!isMounted()) return
    const newLocation: LocationSnapshot = { ...coordinate, ...addresses[0] }
    setLocation(newLocation)
    debounce(updateSelectedLocation, 5000)(newLocation)
    if (Platform.OS !== "web") {
      ref.current.animateToRegion({
        latitude: coordinate.latitude,
        longitude: coordinate.longitude,
        ...metersToLatLngDelta(3 * radius)
      })
    }
  }

  const findAndSetLocation = async () => {
    setIsLoadingLocation(true)

    let ac: Animated.CompositeAnimation
    if (Platform.OS !== "web") {
      ac = Animated.loop(
        Animated.timing(spinAnim, {
          toValue: 1,
          duration: 2000,
          easing: Easing.linear,
          useNativeDriver: true
        })
      )
      ac.start()
    }

    const locationPermission = await Location.requestForegroundPermissionsAsync()

    if (locationPermission.status !== "granted") {
      const msg = "Permission to access location was denied"
      __DEV__ && console.log(msg, locationPermission.status)
      if (isMounted()) setIsLoadingLocation(false)
      // setErrorMsg("Permission to access location was denied")
      return
    }
    const position = await Location.getCurrentPositionAsync({})
    const addresses = await new LocationService().reverseGeocode(position.coords)
    const newLocation: LocationSnapshot = { ...position.coords, ...addresses[0] }
    setLocation(newLocation)
    debounce(updateSelectedLocation, 5000)(newLocation)
    if (!isMounted()) return
    ref.current.animateToRegion({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      ...metersToLatLngDelta(3 * radius)
    })

    setIsLoadingLocation(false)
    if (ac) ac.stop()
  }
  const onSearchSelect: GooglePlacesAutocompleteProps["onSelect"] = async (result) => {
    ref.current.animateToRegion({
      latitude: result.latLng.lat,
      longitude: result.latLng.lng,
      ...metersToLatLngDelta(3 * radius)
    })

    const addresses = await new LocationService().reverseGeocode({
      latitude: result.latLng.lat,
      longitude: result.latLng.lng
    })
    if (!isMounted()) return
    const newLocation: LocationSnapshot = {
      latitude: result.latLng.lat,
      longitude: result.latLng.lng,
      postalCode: result.postalCode,
      ...addresses[0],
      name: result.formattedAddress.split(",")[0]
    }
    debounce(updateSelectedLocation, 5000)(newLocation)
    setLocation(newLocation)
  }

  useEffect(() => {
    if (findLocationOnMount) {
      findAndSetLocation()
    }
  }, [])

  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"]
  })

  return (
    <View
      style={flatten([CONTAINER, props.style])}
      onLayout={(event) => !availableLayout && setAvailableLayout(event.nativeEvent.layout)}
    >
      <View style={HEADER}>
        <View style={FIND_LOCATION_CONTAINER}>
          {isLoadingLocation ? (
            <ActivityIndicator color={color.primary} />
          ) : (
            <Animated.View style={{ transform: [{ rotateZ: spin }] }}>
              <IconButton
                onPress={findAndSetLocation}
                style={FIND_LOCATION}
                // containerStyle={FIND_LOCATION_CONTAINER}
                lib="MaterialIcons"
                name="my-location"
                {...(isLoadingLocation && { text: "Loading" })}
              />
            </Animated.View>
          )}
        </View>
        <View style={AUTOCOMPLETE_CONTAINER}>
          <GooglePlacesAutocomplete onSelect={onSearchSelect} />
        </View>
      </View>
      <MapView
        ref={ref}
        style={flatten([
          MAP,
          availableLayout && { width: availableLayout.width, height: availableLayout.height }
        ])}
        initialRegion={{
          latitude,
          longitude,
          ...metersToLatLngDelta(3 * radius)
        }}
        onPress={onPress}
        onTouchStart={props.onTouchStart}
        onTouchEnd={props.onTouchEnd}
      >
        <MapViewMarker coordinate={{ latitude, longitude }} />
        <MapViewCircle
          {...CIRCLE_STYLES}
          onPress={onPress}
          center={{ latitude, longitude }}
          radius={radius}
        />
      </MapView>
      <View style={RADIUS_INDICATOR_CONTAINER}>
        <View style={RADIUS_INDICATOR_BORDER}>
          <Text style={RADIUS_INDICATOR} text={`${Math.round(radius / 100) / 10} km`} />
        </View>
      </View>
      <Slider
        minimumValue={0}
        maximumValue={1}
        minimumTrackTintColor={color.primary}
        maximumTrackTintColor={addAlphaToHex(color.primary, 0.4)}
        thumbTintColor={Platform.OS === "ios" ? color.background : color.primary}
        value={radiusToSlider(radius)}
        onValueChange={onValueChange}
        onSlidingComplete={onSlidingCompleate}
      />
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  minHeight: Dimensions.get("window").width > 768 ? 300 : Dimensions.get("window").height / 1.3,
  flex: 1
}
const MAP: ViewStyle = {
  justifyContent: "center",
  flex: 1,
  zIndex: -1
}

const CIRCLE_STYLES = {
  strokeWidth: 2,
  strokeColor: addAlphaToHex(color.primary, 0.4),
  fillColor: addAlphaToHex(color.primary, 0.4)
}
const HEADER: ViewStyle = {
  marginHorizontal: 10,
  marginBottom: -55,
  zIndex: 1,
  flexDirection: "row"
}
const FIND_LOCATION_CONTAINER: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: color.background,
  borderRadius: 4,
  borderColor: color.palette.gray2,
  borderWidth: 1,
  marginRight: 10,
  height: 45,
  width: 45
}

const AUTOCOMPLETE_CONTAINER: ViewStyle = {
  flex: 1
}
const FIND_LOCATION: ViewStyle = {
  marginTop: 2
}
const RADIUS_INDICATOR_CONTAINER: ViewStyle = {
  alignItems: "center",
  marginTop: -spacing[5] + 1,
  height: spacing[5]
}
const RADIUS_INDICATOR_BORDER: ViewStyle = {
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  backgroundColor: color.background
}
const RADIUS_INDICATOR: TextStyle = {
  fontFamily: typography.primaryMedium,
  textAlign: "center",
  color: color.text,
  zIndex: 1,
  paddingHorizontal: spacing[3],
  paddingVertical: spacing[1]
}
