import { Instance, SnapshotOut, types, cast, getRoot } from "mobx-state-tree"

import { Dealerfront } from "@app/services/api"
import { DealerApi } from "@app/services/api/dealer-api"
import { DealerModel, DealerSnapshot } from "@app/models/dealer/dealer"
import { CatalogModel } from "@app/models/catalog/catalog"
import { withRootStore } from "@app/models/extensions/with-root-store"
import { withEnvironment } from "@app/models/extensions/with-environment"

/**
 * Model description here for TypeScript hints.
 */
export const DealerStoreModel = types
  .model("DealerStore")
  .props({
    dealers: types.map(DealerModel),
    dealerfront: types.optional(
      types.array(
        types.model({
          dealer: types.reference(DealerModel),
          catalogs: types.array(types.reference(CatalogModel))
        })
      ),
      []
    ),
    dealerfrontHasMore: types.maybe(types.boolean)
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    saveDealer: (dealer: DealerSnapshot) => {
      self.dealers.put(dealer)
    },
    saveDealerfront: (dealerfront: Dealerfront) => {
      dealerfront.forEach(({ dealer, catalogs }) => {
        self.dealers.put(dealer)
        catalogs.forEach((catalog) => self.rootStore.catalogStore.saveCatalog(catalog))
      })

      const dealerfrontOnlyIds = dealerfront.map(({ dealer, catalogs }) => ({
        dealer: dealer.id,
        catalogs: catalogs.map((catalog) => catalog.id)
      }))

      self.dealerfront = cast(dealerfrontOnlyIds)
    }
  }))
  .actions((self) => ({
    loadDealerfront: async () => {
      const dealerApi = new DealerApi(self.environment.api)
      const result = await dealerApi.getDealerfront()

      if (result.kind === "ok") {
        self.saveDealerfront(result.data)
      }
    }
  }))

export interface DealerStore extends Instance<typeof DealerStoreModel> {}
export interface DealerStoreSnapshot extends SnapshotOut<typeof DealerStoreModel> {}
export const createDealerStoreDefaultModel = () => types.optional(DealerStoreModel, {})
