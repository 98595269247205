import React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import { Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"

import { color, spacing } from "@app/theme"
import { Button, Text, useModal } from "@app/components"
import { textPresets } from "@app/components/button/button.presets"
import { useStores } from "@app/models"

export interface ModalContentWelcomeProps {
  style?: StyleProp<ViewStyle>
}

export const ModalContentWelcome = observer((props: ModalContentWelcomeProps) => {
  const { closeModal, openModal, setModalVariant } = useModal()
  const { setFindLocationOnMount } = useStores()
  // const navigation = useNavigation<TabsScreenProps["navigation"]>()

  const onLocationPress = () => {
    closeModal()
    setTimeout(() => {
      setModalVariant("location")
      setFindLocationOnMount(true)
      openModal()
      setFindLocationOnMount(false)
    }, 299)
  }

  return (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={BUTTON_CONTAINER}>
        <Button preset="icon" onPress={closeModal} style={CLOSE_BUTTON}>
          <Feather style={textPresets.icon} name="x" />
        </Button>
      </View>
      <View style={ROW}>
        <View style={ICON_WRAPPER}>
          <Feather style={ICON} name="map-pin" />
        </View>
        <View style={TEXT_CONTAINER}>
          <Text style={TEXT_TITLE} preset="bold" text="Se erbjudanden nära dig!" />
          <Text text="Välj din plats för att få se erbjudanden nära dig." />
        </View>
      </View>
      {/* <Button
        preset="wide"
        style={LOCATION_BUTTON}
        onPress={onLocationPress}
        text="Hämta min plats"
      /> */}
      <Button preset="wide" onPress={onLocationPress} style={LOCATION_BUTTON}>
        <MaterialIcons style={BUTTON_ICON} name="my-location" />
        <Text style={BUTTON_TEXT} preset="bold" text="Välj din plats" />
      </Button>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  padding: spacing[4],
  flex: 1
}
const ROW: ViewStyle = {
  flexDirection: "row",
  maxWidth: 400
}

const ICON_WRAPPER: TextStyle = {
  width: 65,
  height: 65,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: (65) / 2,
  color: color.textWhite,
  backgroundColor: color.backgroundDark
}
const ICON: TextStyle = {
  fontSize: 34,
  color: color.textWhite
}

const TEXT_CONTAINER: ViewStyle = {
  flex: 1,
  marginHorizontal: spacing[3],
  marginBottom: spacing[4]
}

const TEXT_TITLE: TextStyle = {
  fontSize: 16,
  paddingRight: spacing[5],
  marginBottom: spacing[2]
}

const BUTTON_CONTAINER: ViewStyle = {
  width: "100%",
  zIndex: 1
}
const CLOSE_BUTTON: ViewStyle = {
  alignSelf: "flex-end",
  marginBottom: -20
}
const LOCATION_BUTTON: ViewStyle = {
  marginTop: spacing[2]
}

const BUTTON_ICON: TextStyle = {
  color: color.textWhite,
  padding: 5,
  paddingBottom: 4,
  fontSize: 14
}
const BUTTON_TEXT: TextStyle = {
  color: color.textWhite
}
