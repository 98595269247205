import { API_BASE_URL } from "@app/config/env"

const API_URL_EXTERNAL_V2 = "https://ereklamblad.se/api/squid/v2"

export interface ApiConfig {
  url: string
  urlExternalV2: string
  timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_BASE_URL,
  urlExternalV2: API_URL_EXTERNAL_V2,
  timeout: 10000
}
