import * as Font from "expo-font"
import { FontAwesome, MaterialIcons } from "@expo/vector-icons"
import Sentry from "@app/services/sentry/sentry"

const MAX_RETRIES = 3
const RETRY_TIMEOUT = 3000 // milliseconds

export const initFonts = async () => {
  let attempts = 0

  async function loadFonts() {
    // Refer to ./assets/fonts/custom-fonts.md for instructions.
    // remmeber to preload the hashed fonts in html file to fix timeouts
    try {
      await Font.loadAsync({
        "Poppins-Regular": require("./Poppins/Poppins-Regular.ttf"),
        "Poppins-Medium": require("./Poppins/Poppins-Medium.ttf"),
        "Poppins-Bold": require("./Poppins/Poppins-Bold.ttf"),
        ...FontAwesome.font,
        ...MaterialIcons.font
      })
      console.log("Fonts loaded successfully")
    } catch (error) {
      if (attempts < MAX_RETRIES) {
        attempts++
        console.log(`Retry ${attempts}: Trying to load fonts again...`)
        setTimeout(loadFonts, RETRY_TIMEOUT)
      } else {
        Sentry.captureException(error)
        console.log("Failed to load fonts after retries")
      }
    }
  }

  loadFonts()
}
