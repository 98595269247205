import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { color, typography, spacing } from "@app/theme"
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native"
import { Text } from "@app/components/text/text"

export interface TagProps {
  text?: string
  selected?: boolean
  onPress?: () => void
  isLoading?: boolean
}

export const Tag = observer(function Tag(props: TagProps) {
  const { selected, text } = props
  const textStyle = flatten([TEXT, selected && ACTIVE_TEXT])
  const tagStyle = flatten([TAG, selected && ACTIVE_TAG])

  return props.isLoading ? (
    <TagLoader style={CONTAINER} />
  ) : (
    <View style={CONTAINER}>
      <TouchableOpacity onPress={props.onPress} style={tagStyle}>
        <Text preset="bold" style={textStyle} text={text} />
      </TouchableOpacity>
    </View>
  )
})

const TagLoader = (props) => (
  <ContentLoader
    speed={2}
    width={110}
    height={41}
    viewBox="0 0 110 41"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <Path d="M 14.815 0.723 c -19.884 5.533 -19.704 33.275 0.25 38.605 c 3.654 0.976 75.345 0.976 79 0 c 20.101 -5.37 20.101 -33.27 0 -38.64 c -3.514 -0.94 -75.866 -0.906 -79.25 0.035 z" />
  </ContentLoader>
)

const CONTAINER: ViewStyle = {
  flexDirection: "row",
  paddingVertical: spacing[1],
  marginHorizontal: spacing[4] / 2
}

const TAG: ViewStyle = {
  justifyContent: "center",
  backgroundColor: color.palette.gray1,
  // paddingVertical: spacing[3],
  height: 40,
  paddingHorizontal: spacing[4],
  borderRadius: 20
}

const ACTIVE_TAG: ViewStyle = {
  backgroundColor: color.palette.offWhite
}

const TEXT: TextStyle = {
  fontSize: 14,
  color: color.palette.gray4
}
const ACTIVE_TEXT: TextStyle = {
  color: color.primary
}
