import React, { useEffect, useState } from "react"
import {
  Pressable,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  ImageStyle,
  Dimensions
} from "react-native"
import { observer } from "mobx-react-lite"
import { T, flatten, update } from "ramda"

import { color, spacing, typography } from "@app/theme"
import { Button, Svg, Text, useModal, Checkbox } from "@app/components"
import { TextField } from "@app/components/text-field/text-field"
import { Feather } from "@expo/vector-icons"
import { useNavigation } from "@react-navigation/native"
import { TabsScreenProps } from "@app/navigators"
import { useRegisterMutation, useUpdateUserMeMutation } from "@app/services/graphql/generated"
import { INITIAL_LOCATION, UserSnapshot, useStores } from "@app/models"

import { GoogleAuthButton } from "./google-auth-button"
import { FacebookAuthButton } from "./facebook-auth-button"

export interface RegisterProps {
  style?: StyleProp<ViewStyle>
}

export const Register = observer((props: RegisterProps) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: null,
    terms: false,
    phoneNotificationsEnabled: false,
    socialSignupView: true
  })

  const navigation = useNavigation<TabsScreenProps["navigation"]>()

  const { location } = useStores()
  const { closeModal } = useModal()
  const [
    registerMutation,
    { loading: isRegisterLoading, data: registerData, error: registerError }
  ] = useRegisterMutation()

  const [updateUserMeMutation, { error: updateMeError }] = useUpdateUserMeMutation()

  const onRegisterPress = async () => {
    try {
      await registerMutation({
        variables: {
          username: state.email,
          email: state.email,
          password: state.password,
          name: state.name,
          phoneNumber: state.phoneNumber,
          phoneNotificationsEnabled: state.phoneNotificationsEnabled,
          emailNotificationsEnabled: true
        }
      })
    } catch {}
  }

  const toggleView = () => {
    setState((state) => ({ ...state, socialSignupView: !state.socialSignupView }))
  }
  const onAuthenticated = async () => {
    navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })
    closeModal()
  }
  const onSocialAuthenticated = async (user: UserSnapshot | null) => {
    if (user) {
      const locaitonIsInitial =
        INITIAL_LOCATION.latitude === location.latitude &&
        INITIAL_LOCATION.longitude === location.longitude

      await updateUserMeMutation({
        variables: {
          data: {
            ...(state.phoneNumber && { phoneNumber: state.phoneNumber }),
            ...(state.phoneNotificationsEnabled && {
              phoneNotificationsEnabled: state.phoneNotificationsEnabled
            }),
            emailNotificationsEnabled: true,
            ...(!locaitonIsInitial && { selectedLocation: location })
          }
        }
      })
    }
    onAuthenticated()
  }

  const translateError = (message: string): string => {
    const regexMap: [RegExp, string][] = [
      [/An error occurred during account creation/, "Något gick fel, vänligen försök igen"],
      [/password must be at least 6 characters/, "Lösenordet måste vara minst 6 bokstäver"],
      [/Email is already taken/, "E-posten är redan registrerad hos oss"],
      [/email must be a valid email/, "Ogiltig E-postaddress"],
      [/Email or Username are already taken/, "E-post eller Användarnamn är redan taget"],
      [/^phoneNumber must match the following:.+$/, "Ogiltigt telefonnummer, exempel: 0701234567"],
      [/.+/g, "Något gick fel, vänligen kontakta kundtjänst om problemet kvarstår"]
    ]

    function lookupMessage(message: string): string {
      for (const [regex, translation] of regexMap) {
        if (regex.test(message)) {
          return translation
        }
      }
      return undefined
    }

    return lookupMessage(message)
  }

  const termsCheckbox = (
    <Checkbox
      style={CHECKBOX}
      value={state.terms}
      onToggle={(terms) => setState((state) => ({ ...state, terms }))}
    >
      <Text style={CHECKBOX_TEXT} text="Jag godkänner " />
      <Pressable onPress={() => navigation.navigate("Terms")}>
        <Text style={TERMS_TEXT} text="användarvillkoren" />
      </Pressable>
      <Text style={CHECKBOX_TEXT} text=" & " />
      <Pressable onPress={() => navigation.navigate("Policy")}>
        <Text style={TERMS_TEXT} text="sekretesspolicyn" />
      </Pressable>
    </Checkbox>
  )

  const phoneNotificationCheckbox = (
    <Checkbox
      style={CHECKBOX}
      value={state.phoneNotificationsEnabled}
      onToggle={(phoneNotificationsEnabled) =>
        setState((state) => ({ ...state, phoneNotificationsEnabled }))
      }
    >
      <Text style={CHECKBOX_TEXT} text="Jag vill bli uppdaterad på SMS när nya blad släpps" />
    </Checkbox>
  )

  const phoneNotificationField = state.phoneNotificationsEnabled && (
    <View style={PHONE}>
      <TextField
        onChangeText={(phoneNumber) => setState((state) => ({ ...state, phoneNumber }))}
        value={state.phoneNumber}
        style={TEXT_FIELD}
        placeholder="Telefonnummer"
        inputStyle={INPUT_STYLE}
        autoComplete="tel"
        textContentType="telephoneNumber"
        keyboardType="phone-pad"
      />
    </View>
  )

  return registerData ? (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={FLEX_THANK_ROW}>
        <View style={FLEX_THANK}>
          <Svg preset="IllustrationSignup" />
          <Text style={HEADER} preset="header" text="Välkommen till VeckansE!" />
          <Text
            style={SUB_HEADER}
            preset="default"
            text="Vi har nu skickat ett mail till dig där du bara behöver bekräfta din e-postadress."
          />
          <Text
            style={SUB_HEADER}
            preset="default"
            text="Om du inte hittar mailet kolla gärna i din skräppost och flytta mailet till din inkorg samt lägg till nyhetsbrev@veckanse.se till dina kontakter, så kommer våra utskick rätt framöver."
          />
        </View>
      </View>
    </View>
  ) : (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={FLEX_ROW}>
        <Svg style={ILLUSTRATION} preset="IllustrationMail" />
        <View style={FLEX_COL} accessibilityRole="form">
          <Text style={HEADER} preset="header" text="Missa aldrig ett erbjudande!" />
          <Text
            style={SUB_HEADER}
            preset="default"
            text="Få nyhetsbrevet & exklusiva erbjudanden varje vecka!"
          />
          {isRegisterLoading ? (
            <Text>Laddar...</Text>
          ) : !state.socialSignupView ? (
            <View style={SECTION}>
              <TextField
                onChangeText={(name) => setState((state) => ({ ...state, name }))}
                value={state.name}
                style={TEXT_FIELD}
                placeholder="Fullständigt namn"
                inputStyle={INPUT_STYLE}
                autoComplete="name"
                textContentType="name"
              />
              <TextField
                onChangeText={(email) => setState((state) => ({ ...state, email }))}
                value={state.email}
                style={TEXT_FIELD}
                placeholder="E-postadress"
                inputStyle={INPUT_STYLE}
                autoComplete="email"
                textContentType="emailAddress"
              />
              <TextField
                onChangeText={(password) => setState((state) => ({ ...state, password }))}
                value={state.password}
                style={TEXT_FIELD}
                placeholder="Lösenord"
                inputStyle={INPUT_STYLE}
                secureTextEntry
                autoComplete="password-new"
                textContentType="newPassword"
              />
              {phoneNotificationCheckbox}
              {phoneNotificationField}
              {termsCheckbox}
              <Button
                disabled={!state.terms}
                style={BUTTON_MARGIN}
                preset="wide"
                onPress={onRegisterPress}
                text="Registrera"
              />
              <Button
                onPress={toggleView}
                style={TOGGLE_VIEW_BUTTON}
                preset="secondary"
                text="Registrera med sociala konton"
              />
            </View>
          ) : (
            <View style={SECTION}>
              {phoneNotificationCheckbox}
              {phoneNotificationField}
              {/* {termsCheckbox} */}
              <GoogleAuthButton
                // disabled={!state.terms}
                style={BUTTON_MARGIN}
                text="Registrera med Google"
                onAuthenticated={onSocialAuthenticated}
              />
              <FacebookAuthButton
                // disabled={!state.terms}
                style={BUTTON_MARGIN}
                text="Registrera med Facebook"
                onAuthenticated={onSocialAuthenticated}
              />
              <Button
                onPress={toggleView}
                style={TOGGLE_VIEW_BUTTON}
                preset="secondary"
                text="Registrera med e-postadress"
              />
            </View>
          )}
          {registerError && <Text style={ERROR}>{translateError(registerError.message)}</Text>}
          {updateMeError && <Text style={ERROR}>{translateError(updateMeError.message)}</Text>}
          {/* <Pressable onPress={() => navigation.navigate("Login")}>
            <Text style={TEXT} text="redan medlem? logga in >" />
          </Pressable> */}
        </View>
      </View>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  paddingBottom: 48,
  width: "100%"
}
const FULL_WIDTH: ViewStyle = {
  width: "100%"
}

const BUTTON_MARGIN: ViewStyle = {
  marginBottom: spacing[2]
}
const TOGGLE_VIEW_BUTTON: ViewStyle = {
  ...BUTTON_MARGIN,
  ...FULL_WIDTH,
  alignItems: "center"
}

const ILLUSTRATION: ImageStyle = {
  width: 232.997,
  height: 201.203,

  paddingLeft: Dimensions.get("window").width > 768 ? "8%" : 0,
  paddingTop: Dimensions.get("window").width > 768 ? "8%" : 0,
  paddingBottom: Dimensions.get("window").width > 768 ? "8%" : 0,
  marginTop: Dimensions.get("window").width > 768 ? -24 : -24,
  transform: Dimensions.get("window").width > 768 ? [{ scale: 1.5 }] : [{ scale: 1 }]
}

const HEADER: TextStyle = {
  fontSize: Dimensions.get("window").width > 768 ? 24 : 18
}
const SUB_HEADER: TextStyle = {
  textAlign: "center",
  paddingHorizontal: spacing[1],
  paddingBottom: spacing[4]
}

const PHONE: ViewStyle = {
  paddingBottom: 16
}

const TEXT_FIELD: ViewStyle = {
  ...FULL_WIDTH,
  maxWidth: 500
}
const INPUT_STYLE: TextStyle = {
  ...FULL_WIDTH
}

const ERROR: TextStyle = {
  color: "#E03232",
  marginTop: 4
}

const CHECKBOX: ViewStyle = {
  marginTop: spacing[3],
  marginBottom: spacing[4],
  alignSelf: "flex-start"
}
const TERMS_TEXT: TextStyle = {
  color: color.primary,
  fontSize: 15
}
const CHECKBOX_TEXT: TextStyle = {
  fontSize: 15
}
const SECTION: ViewStyle = {
  width: "100%",
  justifyContent: "center",
  marginTop: spacing[3],
  maxWidth: 500
}

const FLEX_ROW: ViewStyle = {
  alignItems: "center",
  flexDirection: Dimensions.get("window").width > 768 ? "row" : "column",
  justifyContent: Dimensions.get("window").width > 768 ? "space-between" : "center",
  width: "100%"
}

const FLEX_COL: ViewStyle = {
  alignItems: "center",
  flexDirection: "column",
  maxWidth: Dimensions.get("window").width > 768 ? "49%" : "auto"
}

const FLEX_THANK_ROW: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
}

const FLEX_THANK: ViewStyle = {
  alignItems: "center",
  flexDirection: "column",
  maxWidth: Dimensions.get("window").width > 768 ? "59%" : "auto"
}
