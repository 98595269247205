import React, { FC, ReactElement } from "react"

import BaseMarkdown from "react-native-markdown-display"
import rules from "./rules"
import styles from "./styles"

interface IMarkdown {
  (props: { children: string }): ReactElement
}

export const Markdown: IMarkdown = ({ children: copy }) => (
  <BaseMarkdown rules={rules} style={styles}>
    {copy}
  </BaseMarkdown>
)

export default Markdown
