import * as Localization from "expo-localization"
import i18n from "i18n-js"
import sv from "./sv.json"
// import en from "./en.json"

i18n.fallbacks = true
i18n.translations = { sv }

i18n.defaultLocale = "sv"
i18n.locale = Localization.locale || "sv"

/**
 * Builds up valid keypaths for translations.
 */
type DefaultLocale = typeof sv
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>

type RecursiveKeyOf<TObj extends Record<string, any>> = {
  [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any>
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`
}[keyof TObj & string]
