import { ImageStyle, TextStyle, ViewStyle } from "react-native"

const htmlTags = [
  "a",
  "abbr",
  "address",
  "area",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "bdi",
  "bdo",
  "blockquote",
  "body",
  "br",
  "button",
  "canvas",
  "caption",
  "cite",
  "code",
  "col",
  "colgroup",
  "data",
  "datalist",
  "dd",
  "del",
  "details",
  "dfn",
  "dialog",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "footer",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "head",
  "header",
  "hr",
  "html",
  "i",
  "iframe",
  "img",
  "input",
  "ins",
  "kbd",
  "label",
  "legend",
  "li",
  "link",
  "main",
  "map",
  "mark",
  "meta",
  "meter",
  "nav",
  "noscript",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "picture",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "ruby",
  "s",
  "samp",
  "script",
  "section",
  "select",
  "small",
  "source",
  "span",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "svg",
  "table",
  "tbody",
  "td",
  "template",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "tr",
  "track",
  "u",
  "ul",
  "var",
  "video",
  "wbr"
]

type NamedStyles<T> = { [P in keyof T]: ViewStyle | TextStyle | ImageStyle }
export const convertStyleToCSS = <T extends NamedStyles<T> | NamedStyles<any>>(
  styles: T | NamedStyles<T>
): string => {
  let css = ""

  for (const className in styles) {
    const selector = htmlTags.includes(className) ? className : `.${className}`
    css += `${selector} {\n`

    for (const property in styles[className]) {
      const kebabCaseProperty = property
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2")
        .toLowerCase()
      const value = styles[className][property]
      css += `  ${kebabCaseProperty}: ${value};\n`
    }

    css += "}\n"
  }

  return css
}

export const markStyleValuesAsImportant = <T extends NamedStyles<T> | NamedStyles<any>>(
  style: NamedStyles<T>[]
) =>
  Object.entries(style).reduce((acc, [key, value]) => {
    acc[key] = `${value} !important`
    return acc
  }, {})
