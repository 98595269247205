import React, { FC } from "react"
import {
  StyleProp,
  ViewStyle,
  View,
  Dimensions,
  TouchableWithoutFeedback,
  Platform
} from "react-native"
import { Feather } from "@expo/vector-icons"
import { flatten } from "ramda"

import { textPresets } from "@app/components/button/button.presets"
import { Button } from "@app/components"
import { color, spacing } from "@app/theme"
import { addAlphaToHex } from "@app/utils/misc"

type ModalScreenLayoutProps = {
  style?: StyleProp<ViewStyle>
  onClosePress: () => void
  closeIconPreset: "iconBlack" | "icon"
  children: React.ReactNode
}

export const ModalScreenLayout = (props: ModalScreenLayoutProps) => {
  const isNative = Platform.OS !== "web"
  return (
    <TouchableWithoutFeedback disabled={isNative} onPress={props.onClosePress}>
      <View style={flatten([CONTAINER, props.style])}>
        <Button
          preset={props.closeIconPreset}
          onPress={props.onClosePress}
          containerStyle={BUTTON_CONTAINER}
          style={CLOSE_BUTTON}
        >
          <Feather style={textPresets[props.closeIconPreset]} name="x" />
        </Button>
        <TouchableWithoutFeedback disabled={isNative}>
          <View style={CHILDREN_CONTAINER}>{props.children}</View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  )
}

const CONTAINER: ViewStyle = {
  backgroundColor: addAlphaToHex(color.palette.black, 0.75),
  flex: 1,
  overflow: "hidden" // used for zoomed catalog
}
const CHILDREN_CONTAINER: ViewStyle = {
  flex: 1
}

const CENTER: ViewStyle = {
  justifyContent: "center",
  alignItems: "center"
}

const CIRCLE_WIDTH = 40
const CIRCLE: ViewStyle = {
  width: CIRCLE_WIDTH,
  height: CIRCLE_WIDTH,
  borderRadius: CIRCLE_WIDTH / 2
}

const CIRCLE_BUTTON: ViewStyle = {
  ...CIRCLE,
  ...CENTER,
  margin: Dimensions.get("window").width > 768 ? spacing[3] : spacing[3]
}
const CLOSE_BUTTON: ViewStyle = {
  ...CIRCLE_BUTTON,
  ...(Dimensions.get("window").width <= 368 && {
    marginTop: -spacing[1],
    marginRight: -spacing[1],
    marginBottom: spacing[1],
    transform: [{ scale: 0.7 }]
  })
}
const BUTTON_CONTAINER: ViewStyle = {
  zIndex: 1,
  alignSelf: "flex-end"
}
