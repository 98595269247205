import * as React from "react"
import { TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { Text } from "../text/text"
import { color, spacing } from "../../theme"
import { CheckboxProps } from "./checkbox.props"

export function Checkbox(props: CheckboxProps) {
  // const numberOfLines = props.multiline ? 0 : 0

  const rootStyle = [ROOT, props.style]
  const outlineStyle = [OUTLINE, props.outlineStyle]
  const fillStyle = [FILL, props.fillStyle]

  const onPress = props.onToggle ? () => props.onToggle && props.onToggle(!props.value) : null
  const content = props.text || props.children

  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={!props.onToggle}
      onPress={onPress}
      style={rootStyle}
    >
      <View style={outlineStyle}>{props.value && <View style={fillStyle} />}</View>
      <Text tx={props.tx} numberOfLines={3} style={LABEL}>
        {content}
      </Text>
    </TouchableOpacity>
  )
}

const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingVertical: spacing[1],
  alignSelf: "flex-start",
  justifyContent: "center",
  alignItems: "flex-start"
}

const DIMENSIONS = { width: 20, height: 20 }

const OUTLINE: ViewStyle = {
  ...DIMENSIONS,
  marginTop: 2, // will depend on font/line-height/baseline
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 1,
  borderColor: color.palette.gray3,
  borderRadius: 4
}

const FILL: ViewStyle = {
  width: DIMENSIONS.width,
  height: DIMENSIONS.height,
  backgroundColor: color.primary,
  borderWidth: 1,
  borderColor: color.palette.pink3,
  borderRadius: 4
}

const LABEL: TextStyle = { paddingLeft: spacing[2], justifyContent: "center", alignItems: "center", fontSize: 14 }
