import { cast, Instance, SnapshotOut, types } from "mobx-state-tree"

import { ButikModel, ButikSnapshot } from "@app/models/butik/butik"
import { withEnvironment } from "@app/models/extensions/with-environment"
import { ButikApi, GetButiker } from "@app/services/api/butik-api"

/**
 * Model description here for TypeScript hints.
 */
export const ButikStoreModel = types
  .model("ButikStore")
  .props({
    butiker: types.map(ButikModel)
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    saveButik: (butik: ButikSnapshot) => {
      self.butiker.put(butik)
    }
  }))
  .actions((self) => ({
    getButiker: async (args: GetButiker) => {
      const butikApi = new ButikApi(self.environment.api)
      const result = await butikApi.getButiker(args)
      let savedButikerIds: string[] = []
      if (result.kind === "ok") {
        savedButikerIds = result.data.map((butik) => {
          self.saveButik(butik)
          return butik.id
        })
      }
      return savedButikerIds
    }
  }))

export interface ButikStore extends Instance<typeof ButikStoreModel> {}
export interface ButikStoreSnapshot extends SnapshotOut<typeof ButikStoreModel> {}
export const createButikStoreDefaultModel = () => types.optional(ButikStoreModel, {})
