import React from "react"
import { Dimensions, StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { color, spacing, typography } from "@app/theme"
import { Button, Text, useModal } from "@app/components"
import { Feather, MaterialIcons } from "@expo/vector-icons"

export interface ChangeLocationBigBannerProps {
  style?: StyleProp<ViewStyle>
}

export const ChangeLocationBigBanner = observer((props: ChangeLocationBigBannerProps) => {
  const { openModal, setModalVariant } = useModal()
  const onPress = () => {
    setModalVariant("location")
    openModal()
  }
  return (
    <View style={flatten([CONTAINER, props.style])}>
      <View style={EMPTYSTATE}>
        <Feather style={EMPTYSTATE_ICON} name="book" />
        <Text
          style={EMPTYSTATE_TITLE}
          preset="header"
          text="Vi hittade inga erbjudanden nära dig!"
        />
        <Text
          style={EMPTYSTATE_TEXT}
          preset="default"
          text="Testa att öka avståndet runt din plats för att se fler butiker."
        />
        <Button onPress={onPress} style={EMPTYSTATE_BUTTON}>
          <MaterialIcons style={BUTTON_ICON} name="my-location" />
          <Text style={BUTTON_TEXT} preset="bold" text="Ändra din plats" />
        </Button>
      </View>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  alignSelf: "center",
  width: Dimensions.get("window").width > 768 ? "96%" : "92%",
  marginBottom: 80
}

const EMPTYSTATE: ViewStyle = {
  backgroundColor: color.palette.gray1,
  borderRadius: 10,
  // width: "100%",
  // width: Dimensions.get("window").width > 768 ? "100%" : "92%",
  height: Dimensions.get("window").width > 768 ? 480 : 380,
  justifyContent: "center",
  alignItems: "center",
  padding: spacing[5]
}

const EMPTYSTATE_ICON: TextStyle = {
  color: color.backgroundDark,
  marginBottom: spacing[3],
  fontSize: 56
}

const EMPTYSTATE_TITLE: TextStyle = {
  color: color.palette.pink5,
  fontWeight: "900",
  marginBottom: spacing[2],
  fontSize: Dimensions.get("window").width > 768 ? 24 : 18,
  textAlign: "center"
}

const EMPTYSTATE_TEXT: TextStyle = {
  textAlign: "center"
}

const EMPTYSTATE_BUTTON: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing[6]
}

const BUTTON_ICON: TextStyle = {
  color: color.textWhite,
  paddingRight: 5,
  fontSize: 16
}
const BUTTON_TEXT: TextStyle = {
  color: color.textWhite
}
