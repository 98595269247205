import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, TextStyle, Dimensions, Linking, TouchableOpacity } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { Button, Screen, Text, Svg } from "@app/components"
import { color, spacing } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type AdvertiseScreenProps = StackScreenProps<RootNavigatorParamList, "Advertise">
export const AdvertiseScreen = observer((props: AdvertiseScreenProps) => {
  const goHome = () =>
    props.navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })
  return (
    <Screen style={ROOT} preset="fixed">
      <View style={PAGE_WRAPPER}>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <Text style={HEADER_LARGE} preset="headerLarge" text="Annonsera" />
        <Text
          preset="default"
          text="Kontakta oss på veckansE gällande annonsering. Lämna era kontaktuppgifter så hör vi av oss snarast!"
        />
        <View style={DIVIDER}></View>
        <TouchableOpacity
          onPress={() =>
            Linking.openURL("mailto:annons@veckanse.com?subject=Annonsering på VeckansE")
          }
        >
          <Text style={LINK} text="annons@veckanse.com" />
        </TouchableOpacity>
        <View style={SECTION}>
          <Text preset="bold" text="Bästa hälsningar," />
          <Text preset="bold" text="Team VeckansE" />
        </View>
      </View>
      <Footer />
    </Screen>
  )
})

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  minHeight: Dimensions.get("window").height,
  alignSelf: "center"
}

const SECTION: ViewStyle = {
  paddingTop: 40
}

const DIVIDER: ViewStyle = {
  marginBottom: 16
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform:
    Dimensions.get("window").width > 768
      ? [{ scale: 2 }, { rotate: "135deg" }]
      : [{ scale: 1 }, { rotate: "135deg" }],
  zIndex: -1
}

const HEADER_LARGE: TextStyle = {
  marginTop: spacing[5],
  marginBottom: spacing[5]
}

const LINK: TextStyle = {
  color: color.palette.pink3
}
