import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { CatalogModel, CatalogSnapshot } from "@app/models/catalog/catalog"
import { withEnvironment } from "@app/models/extensions/with-environment"
import { CatalogApi, GetCatalogs } from "@app/services/api/catalog-api"

/**
 * Model description here for TypeScript hints.
 */
export const CatalogStoreModel = types
  .model("CatalogStore")
  .props({
    catalogs: types.map(CatalogModel)
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    saveCatalog: (catalog: CatalogSnapshot) => {
      self.catalogs.put(catalog)
    }
  }))
  .actions((self) => ({
    getCatalogs: async (args: GetCatalogs) => {
      const catalogApi = new CatalogApi(self.environment.api)
      const result = await catalogApi.getCatalogs(args)
      let savedCatalogIds: string[] = []
      if (result.kind === "ok") {
        savedCatalogIds = result.data.map((catalog) => {
          self.saveCatalog({ ...catalog, _pages: undefined })
          return catalog.id
        })
      }
      return savedCatalogIds
    }
  }))

export interface CatalogStore extends Instance<typeof CatalogStoreModel> {}
export interface CatalogStoreSnapshot extends SnapshotOut<typeof CatalogStoreModel> {}
export const createCatalogStoreDefaultModel = () => types.optional(CatalogStoreModel, {})
