import * as React from "react"
import { View, TouchableOpacity } from "react-native"
import { Text } from "../text/text"
import { viewPresets, textPresets, containerPresets } from "./button.presets"
import { ButtonProps } from "./button.props"

export function Button(props: ButtonProps) {
  const {
    preset = "primary",
    tx,
    text,
    style: styleOverride,
    textStyle: textStyleOverride,
    containerStyle: containerStyleOverride,
    disabled,
    children,
    ...rest
  } = props

  const viewStyle = viewPresets[preset] || viewPresets.primary
  const viewStyles = [viewStyle, styleOverride]
  const textStyle = textPresets[preset] || textPresets.primary
  const textStyles = [textStyle, textStyleOverride]
  const containerStyle = containerPresets[preset] || containerPresets.primary
  const containerStyles = [containerStyle, containerStyleOverride]

  const content = children || <Text tx={tx} text={text} style={textStyles} />

  return (
    <View style={containerStyles}>
      <TouchableOpacity style={viewStyles} disabled={disabled} {...rest}>
        {content}
      </TouchableOpacity>
    </View>
  )
}
