import * as React from "react"
import {
  Platform,
  StyleProp,
  View,
  ViewStyle,
  TouchableOpacityProps,
  Pressable,
  Linking
} from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import {
  Button,
  IconButton,
  IconButtonHorizontal,
  LocationButton,
  Svg,
  useModal
} from "@app/components"
import { color, spacing } from "@app/theme"
import { useStores } from "@app/models"
import { useSafeAreaInsets } from "react-native-safe-area-context"

export interface MenuProps
  extends Record<
    "onMenuPress" | "onOffersPress" | "onFaqPress" | "onUserPress" | "onCtaPress",
    TouchableOpacityProps["onPress"]
  > {
  variant: "top" | "bottom"
  style?: StyleProp<ViewStyle>
}

export const Menu = observer(function Menu(props: MenuProps) {
  const { style } = props
  const insets = useSafeAreaInsets()
  const styles: StyleProp<ViewStyle> = flatten([
    CONTAINER,
    props.variant === "top" ? CONTAINER_TOP : [],
    props.variant === "bottom" ? { ...CONTAINER_BOTTOM, paddingBottom: insets.bottom * 0.6 } : [],
    style
  ])

  const { user, logout } = useStores()
  const { closeModal, openModal, setModalVariant, openState } = useModal()
  const openLocation = () => {
    const delay = ["open", "opening"].includes(openState) ? 300 : null
    if (delay) {
      closeModal()
      setTimeout(() => {
        setModalVariant("location")
        openModal()
      }, delay)
    } else {
      setModalVariant("location")
      openModal()
    }
  }
  return (
    <View style={styles}>
      {props.variant === "top" ? (
        <React.Fragment>
          <View style={flatten([ICON_WRAPPER, ICON_WRAPPER_TOP])}>
            <View style={COLUMN}>
              <Pressable style={LOGO} onPress={props.onOffersPress}>
                <Svg preset="VeckanseLogoPink" />
              </Pressable>
              <LocationButton style={ROW_ITEM} />
            </View>
            <View style={COLUMN}>
              <IconButtonHorizontal
                style={ROW_ITEM}
                onPress={props.onOffersPress}
                name="home"
                text="Hem"
                lib="Feather"
              />
              {/* <IconButtonHorizontal
                style={ROW_ITEM}
                onPress={() =>
                  Linking.openURL(
                    "https://veckansr.se/"
                    // "https://docs.google.com/forms/d/e/1FAIpQLSfv-BkN1mTPibfCCsFUBBaWeeDH87ntdbGFLPEZnVjnrqOdfg/viewform?usp=sf_link"
                  )
                }
                name="tag"
                text="Rabatter"
                lib="Feather"
              /> */}
              <IconButtonHorizontal
                style={ROW_ITEM}
                onPress={props.onFaqPress}
                name="help-circle"
                text="Frågor & svar"
                lib="Feather"
              />
              {!user ? (
                <>
                  <IconButtonHorizontal
                    style={ROW_ITEM}
                    onPress={props.onUserPress}
                    name="user"
                    text="Logga in"
                    lib="Feather"
                  />
                  <Button
                    style={flatten([BUTTON, ROW_ITEM])}
                    onPress={props.onCtaPress}
                    text="Få nyhetsbrevet"
                  />
                </>
              ) : (
                <IconButtonHorizontal
                  style={ROW_ITEM}
                  onPress={logout}
                  name="user"
                  text="Logga ut"
                  lib="Feather"
                />
              )}
            </View>
          </View>
        </React.Fragment>
      ) : props.variant === "bottom" ? (
        <React.Fragment>
          <View style={ICON_WRAPPER}>
            <IconButton
              containerStyle={ITEM}
              onPress={props.onMenuPress}
              name="menu"
              text="Meny"
              lib="Feather"
            />
            <IconButton
              containerStyle={ITEM}
              onPress={props.onOffersPress}
              name="home"
              text="Hem"
              lib="Feather"
            />
            {/* <IconButton containerStyle={ITEM} onPress={props.onUserPress} name="user" text="Logga in" lib="Feather" /> */}
            <IconButton
              containerStyle={ITEM}
              onPress={openLocation}
              name="map-pin"
              text="Din plats"
              lib="Feather"
            />
            {user && (
              <IconButton
                containerStyle={ITEM}
                onPress={logout}
                name="user"
                text="Logga ut"
                lib="Feather"
              />
            )}
          </View>
          {/* {!user && <Button style={ITEM} onPress={props.onCtaPress} text="Få nyhetsbrevet" />} */}
          {!user && (
            <View style={BUTTON_WRAPPER}>
              <Button
              style={flatten([BUTTON, ITEM])}
                onPress={props.onCtaPress}
                text="Få nyhetsbrevet"
                preset="wide"
              />
            </View>
          )}
        </React.Fragment>
      ) : null}
    </View>
  )
})

const SHADOW: ViewStyle = {
  borderTopStartRadius: 10,
  borderTopEndRadius: 10,
  // backgroundColor: "transparent",
  shadowColor: color.palette.black,
  shadowOffset: {
    width: 0,
    height: 0
  },
  shadowOpacity: 0.1,
  shadowRadius: 20,
  elevation: 3
}

const CONTAINER: ViewStyle = {
  ...SHADOW,
  justifyContent: "space-around",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: color.palette.white,
  zIndex: 1,
  marginTop: -10
}
const CONTAINER_TOP: ViewStyle = {
  shadowOpacity: 0,
  borderTopStartRadius: 0,
  borderTopEndRadius: 0,
  marginTop: 0
}
const CONTAINER_BOTTOM: ViewStyle = {}
const BUTTON: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  paddingLeft: 20,
  paddingRight: 20
}

const BUTTON_WRAPPER: ViewStyle = {
  flexGrow: 1,
  paddingRight: 8,
  paddingLeft: 8,
}

const ICON_WRAPPER: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexDirection: "row",
  justifyContent: "space-evenly",
  paddingRight: 8,
  paddingLeft: 8,
  ...Platform.select({
    native: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center"
    },
    web: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center"
    }
  })
}

const ITEM: ViewStyle = {
  flexDirection: "row"
}
const ROW_ITEM: ViewStyle = {
  marginHorizontal: spacing[3],
  flexDirection: "row"
}

const ICON_WRAPPER_TOP: ViewStyle = {
  justifyContent: "space-between",
  flex: 0.44,
  flexBasis: 800
}
const COLUMN: ViewStyle = {
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "row"
}

const LOGO: ViewStyle = {
  marginHorizontal: spacing[5]
}
