import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, Dimensions } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"

import { Button, Screen, Text, Svg, Markdown } from "@app/components"
import { color } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { useGetFaqPageQuery } from "@app/services/graphql/generated"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type FaqScreenProps = StackScreenProps<RootNavigatorParamList, "Faq">
export const FaqScreen = observer((props: FaqScreenProps) => {
  const goHome = () =>props.navigation.navigate("Tabs", { screen: "Home", params: { screen: 'Offers'} })

  const { loading, data } = useGetFaqPageQuery()

  return (
    <Screen style={ROOT} preset="scroll">
      <View style={PAGE_WRAPPER}>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        {!loading && <Markdown>{data.faqPage.data?.attributes?.markdown}</Markdown>}
      </View>
      <Footer />
    </Screen>
  )
})

const ww = Dimensions.get("window").width
const wh = Dimensions.get("window").height

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  width: "100%",
  minHeight: wh,
  alignSelf: "center"
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform: ww > 768 ? [{ scale: 2 }, { rotate: "45deg" }] : [{ scale: 1 }, { rotate: "45deg" }],
  zIndex: -1
}
