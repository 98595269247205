import * as Sentry from "@sentry/browser"
import { BrowserTracing } from "@sentry/tracing"
import { Platform } from "react-native"
import { SENTRY_TRACES_SAMPLE_RATE, ENVIRONMENT } from "@app/config/env"

Sentry.init({
  dsn: "https://d2741714906146f6a2d1be709b99ab34@o1081641.ingest.sentry.io/6596269",
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  environment:
    ENVIRONMENT +
    Platform.select({
      ios: "-ios",
      android: "-android",
      web: "-web"
    })
})

export const WithSentry = (App: React.ComponentType) => App
export { Sentry }
export default Sentry
