import { Api } from "@app/services/api"
import { DEFAULT_API_CONFIG } from "@app/services/api/api-config"
import { Dealerfront } from "@app/services/api/api.types"
import { keysToSnakeCase } from "@app/utils/misc"

const API_PAGE_SIZE = 50

export class DealerApi {
  private api: Api

  constructor(api: Api) {
    this.api = api
  }

  async getDealerfront() {
    const args = {
      rLat: 59.3274,
      rLng: 18.0653,
      rRadius: 700000,
      limit: 12,
      orderBy: "name",
      types: "paged&incito",
      offset: 12
    }
    return await this.api.get<Dealerfront>(
      `${DEFAULT_API_CONFIG.urlExternalV2}/dealerfront`,
      keysToSnakeCase(args)
    )
  }
}
