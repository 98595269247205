import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, TextStyle, Dimensions, ListRenderItem } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
// import { useNavigation } from "@react-navigation/native"

// import { useStores } from "@app/models"
import { Button, Screen, Text, Svg } from "@app/components"
import { TextProps } from "@app/components/text/text.props"
import { color, spacing } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type ListItem = {
  text: string
  preset: TextProps["preset"]
}

type AboutScreenProps = StackScreenProps<RootNavigatorParamList, "About">
export const AboutScreen = observer((props: AboutScreenProps) => {
  const goHome = () =>
    props.navigation.navigate("Tabs", { screen: "Home", params: { screen: "Offers" } })

  const renderItem: ListRenderItem<ListItem> = ({ item }) => {
    switch (item.preset) {
      case "bold":
        return (
          <Text preset={item.preset} style={LIST_ITEM_BOLD}>
            {item.text}
          </Text>
        )
      default:
        return (
          <Text preset={item.preset} style={LIST_ITEM_DEFAULT}>
            {item.text}
          </Text>
        )
    }
  }
  return (
    <Screen style={ROOT} preset="scroll">
      <View style={PAGE_WRAPPER}>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Button preset="back" onPress={goHome}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <Text style={HEADER_LARGE} preset="headerLarge" text="Om oss" />
        <Text
          preset="default"
          text="VeckansE skapades för att kunna erbjuda konsumenten ett digitalt alternativ till de reklamblad som du idag får i brevlådan. De erbjudanden som finns är en viktig del i många konsumenters vardag för att hitta bra köp. För att underlätta för konsumenten såg vi en potential i att samla dessa erbjudanden digitalt. Detta ger också en positiv inverkan på miljön då behovet av erbjudanden i brevlådan försvinner."
        />
        <View style={SECTION}>
          <Text preset="bold" text="Cartero Digital Marketing AB" />
          <Text preset="bold" text="Box 5077" />
          <Text preset="bold" text="102 42 Stockholm" />
        </View>
      </View>
      <Footer />
    </Screen>
  )
})

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height
}

const LIST_ITEM_BOLD: ViewStyle = {
  marginTop: spacing[4],
  marginBottom: spacing[2]
}
const LIST_ITEM_DEFAULT: ViewStyle = {
  marginLeft: spacing[3],
  marginBottom: spacing[2]
}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  minHeight: Dimensions.get("window").height,
  alignSelf: "center"
}

const SECTION: ViewStyle = {
  paddingTop: 40
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform:
    Dimensions.get("window").width > 768
      ? [{ scale: 2 }, { rotate: "45deg" }]
      : [{ scale: 1 }, { rotate: "45deg" }],
  zIndex: -1
}

const HEADER_LARGE: TextStyle = {
  marginTop: spacing[5],
  marginBottom: spacing[5]
}

const LINK: TextStyle = {
  color: color.palette.pink3
}
