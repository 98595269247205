import React from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, Dimensions } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"

import { Button, Screen, Text, Svg } from "@app/components"
import { color } from "@app/theme"
import { Footer } from "@app/components/footer/footer"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import Sitemap from "@app/components/third-party/newcustomer/sitemap"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"

type SitemapScreenProps = StackScreenProps<RootNavigatorParamList, "Sitemap">
export const SitemapScreen = observer((props: SitemapScreenProps) => {
  const goBack = () => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack()
    } else {
     props.navigation.navigate("Tabs", { screen: "Home", params: { screen: 'Offers'} })
    }
  }

  return (
    <Screen style={ROOT} preset="scroll">
      <View style={PAGE_WRAPPER}>
        <View style={BACKGROUND_IMAGE}>
          <Svg preset="PinkBlob" />
        </View>
        <Button preset="back" onPress={goBack}>
          <Feather style={textPresets.back} name="chevron-left" />
          <Text style={textPresets.back} text="Tillbaka" />
        </Button>
        <Sitemap />
      </View>
      <Footer />
    </Screen>
  )
})

const ww = Dimensions.get("window").width
const wh = Dimensions.get("window").height

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  minHeight: Dimensions.get("window").height}

const PAGE_WRAPPER: ViewStyle = {
  padding: 24,
  paddingBottom: 80,
  maxWidth: 840,
  width: "100%",
  minHeight: wh,
  alignSelf: "center"
}

const BACKGROUND_IMAGE: ViewStyle = {
  height: 500,
  width: 580,
  position: "absolute",
  top: -100,
  left: -200,
  transform: ww > 768 ? [{ scale: 2 }, { rotate: "45deg" }] : [{ scale: 1 }, { rotate: "45deg" }],
  zIndex: -1
}
