import React, { useState } from "react"
import WebGooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId
} from "react-google-places-autocomplete"

import { GOOGLE_CLOUD_API_KEY } from "@app/config/env"
import { GooglePlacesAutocompleteProps } from "./google-places-autocomplete.props"
import { color, typography } from "@app/theme"

export const GooglePlacesAutocomplete = (props: GooglePlacesAutocompleteProps) => {
  const [selectedItem, setSelectedItem] = useState(null)

  const onChange = async (item) => {
    setSelectedItem(item)
    const results = await geocodeByPlaceId(item.value.place_id)
    const result = results[0]

    const addressCompMap = result.address_components.reduce<Record<string, string>>((acc, cur) => {
      acc[cur.types[0]] = cur.long_name
      return acc
    }, {})
    const formattedAddress = result.formatted_address
    const postalCode = addressCompMap.postal_code
    const latLng = await getLatLng(result)
    props.onSelect({ latLng, postalCode, formattedAddress })
  }

  return (
    <WebGooglePlacesAutocomplete
      selectProps={{
        // https://react-select.com/props
        value: selectedItem,
        onChange,
        placeholder: "Sök postnr,  ort eller adress",
        loadingMessage: () => "Söker...",
        noOptionsMessage: () => "Inga träffar",
        styles: {
          input: (provided) => ({
            ...provided,
            height: "35px",
            lineHeight: "35px",
            fontFamily: typography.primary
          }),
          placeholder: (provided) => ({
            ...provided,
            fontFamily: typography.primary
          }),
          loadingMessage: (provided) => ({
            ...provided,
            fontFamily: typography.primary
          }),
          noOptionsMessage: (provided) => ({
            ...provided,
            fontFamily: typography.primary
          }),
          option: (provided) => ({
            ...provided,
            fontFamily: typography.primary
          }),
          singleValue: (provided) => ({
            ...provided,
            fontFamily: typography.primary
          })
        }
      }}
      apiOptions={{ language: "sv", region: "SE" }}
      apiKey={GOOGLE_CLOUD_API_KEY}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["se"]
        }
      }}
    />
  )
}
